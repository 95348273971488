import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sleep } from "../../../common/utils";
import { RootState } from "../../index";
import { postAddRemoteDevice } from "../../../api/devices/device";

type RemoteRegisterState = {
  loading: boolean;
  error?: string | Error;
  success: boolean;
  categoryId: number | undefined;
  brandId: number | undefined;
};

const initialState: RemoteRegisterState = {
  loading: false,
  error: undefined,
  success: false,
  categoryId: undefined,
  brandId: undefined,
};

export const fetchRegisterRemoteDevice = createAsyncThunk(
  "panel/register-remote-device",
  async ({
    id,
    categoryId,
    brandId,
    token,
  }: {
    id: number;
    categoryId: number;
    brandId: number;
    token?: string;
  }) => {
    await sleep(100);
    const { result } = await postAddRemoteDevice(
      id,
      categoryId,
      brandId,
      token
    );
    return result;
  }
);

const slice = createSlice({
  name: "panel/register-remote-device",
  initialState,
  reducers: {
    resetRegisterRemoteDevice: (state: RemoteRegisterState) => ({
      ...state,
      loading: initialState.loading,
      success: initialState.success,
      categoryId: initialState.categoryId,
      brandId: initialState.brandId,
    }),
    updateCategoryId: (state: RemoteRegisterState, { payload }) => ({
      ...state,
      categoryId: payload,
    }),
    updateBrandId: (state: RemoteRegisterState, { payload }) => ({
      ...state,
      brandId: payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchRegisterRemoteDevice.pending,
      (state: RemoteRegisterState) => {
        state.loading = true;
        state.error = undefined;
      }
    );
    builder.addCase(
      fetchRegisterRemoteDevice.fulfilled,
      (state: RemoteRegisterState, { payload }) => {
        state.loading = false;
        state.error = undefined;
        state.success = payload;
      }
    );
    builder.addCase(
      fetchRegisterRemoteDevice.rejected,
      (state: RemoteRegisterState, { error }) => {
        state.loading = false;
        state.error = error.message;
      }
    );
  },
});

export const registerRemoteDeviceSelector = (state: RootState) =>
  state.panel.registerRemote;
export const { resetRegisterRemoteDevice, updateBrandId, updateCategoryId } =
  slice.actions;

export default slice;
