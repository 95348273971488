import React, { useEffect, useState } from "react";
import { Select } from "antd";
import "./MultiView.scss";
import { useDispatch, useSelector } from "react-redux";
import { fetchGetSpaceList, spaceListSelector } from "../../store/space/list";
import { cctvListSelector, fetchGetCctvList } from "../../store/cctv";
import { AppDispatch } from "../../store";
import { useTitle } from "ahooks";
import CctvMultiViewSizeIcon from "../../common/svgs/cctv/CctvMultiViewSizeIcon";
import CctvMultiviewArrowIcon from "../../common/svgs/cctv/CctvMultiviewArrowIcon";
// import CctvMuteIcon from "../../common/svgs/cctv/CctvMuteIcon";
// import CctvCaptureIcon from "../../common/svgs/cctv/CctvCaptureIcon";
// import CctvMicIcon from "../../common/svgs/cctv/CctvMicIcon";
// import CctvRecordIcon from "../../common/svgs/cctv/CctvRecordIcon";
// import CctvSettingIcon from "../../common/svgs/cctv/CctvSettingIcon";

const { Option } = Select;
import CameraView from "./CameraView";

function MultiView() {
  useTitle("카메라 보아보기 | 헤이홈 스퀘어");
  const dispatch = useDispatch<AppDispatch>();

  const { spaceList } = useSelector(spaceListSelector);
  const { cctvList } = useSelector(cctvListSelector);

  const [selectedSpaceName, setSelectedSpaceName] = useState("미지정");
  const [defaultSpaceId, setDefaultSpaceId] = useState(0);
  const [viewSize, setViewSize] = useState(4);
  const [viewPage, setViewPage] = useState(1);
  const vieSizeList = [4, 9, 16];

  useEffect(() => {
    dispatch(fetchGetSpaceList());
    dispatch(fetchGetCctvList());
  }, []);

  useEffect(() => {
    if (spaceList.length <= 0) return;

    const spaceId = spaceList[0].id;
    const founded = spaceList.find((space) => space.id === spaceId);

    setDefaultSpaceId(spaceId);
    setSelectedSpaceName(founded ? founded.name : "none");
    dispatch(fetchGetCctvList(spaceId));
  }, [spaceList]);

  const onViewClick = (view: number) => {
    setViewSize(view);
  };

  const onchange = (value: number) => {
    setDefaultSpaceId(value);
    if (value === 0) {
      setSelectedSpaceName("미지정");
      dispatch(fetchGetCctvList());
    } else {
      const founded = spaceList.find((space) => space.id === value);
      setSelectedSpaceName(founded ? founded.name : "none");
      dispatch(fetchGetCctvList(value));
    }
  };

  const onClickPrevPage = () => {
    if (viewPage === 1) return;

    setViewPage(viewPage - 1);
  };

  const getTotalPageSize = () => {
    if (cctvList.length % viewSize === 0) return cctvList.length / viewSize;
    if (cctvList.length / viewSize < 1) return 1;

    return Math.round(cctvList.length / viewSize) + 1;
  };

  const onClickNextPage = () => {
    if (getTotalPageSize() <= viewPage) return;
    setViewPage(viewPage + 1);
  };

  const getNoCamCount = () => {
    const totalPages = getTotalPageSize();
    if (totalPages === 1) return totalPages * viewSize - cctvList.length;

    if (viewPage * viewSize > cctvList.length)
      return totalPages * viewSize - cctvList.length;

    return 0;
  };

  return (
    <div className="view-list-container">
      <div className={`view-list-${viewSize}`}>
        {cctvList.map(({ id }, index) => {
          return (
            <CameraView
              id={id}
              cameraIndex={index}
              viewSize={viewSize}
              viewPage={viewPage}
              selectedSpaceName={selectedSpaceName}
            />
          );
        })}
        {[...Array(getNoCamCount())].map((value, index, array) => {
          return (
            <div className="player-wrapper" key={index}>
              <div className="player-content">
                <span className="no-camera"> NO CAM</span>
              </div>
            </div>
          );
        })}
      </div>
      <div className="view-buttons">
        <Select
          className="view-list-space-select"
          defaultValue={defaultSpaceId}
          value={defaultSpaceId}
          onChange={onchange}
        >
          <Option value={0}>장소 미지정</Option>;
          {spaceList &&
            spaceList.map(({ id, name }: { id: number; name: string }) => {
              return (
                <Option key={id} value={id}>
                  {name}
                </Option>
              );
            })}
        </Select>
        <div className="divide-buttons">
          {vieSizeList.map((size) => {
            return (
              <button
                key={size}
                onClick={() => onViewClick(size)}
                className={viewSize === size ? "active" : ""}
              >
                <CctvMultiViewSizeIcon />
                {size}뷰
              </button>
            );
          })}
        </div>
        <div className="extra-buttons">
          {/*<button>*/}
          {/*  <CctvMuteIcon />*/}
          {/*</button>*/}
          {/*<button>*/}
          {/*  <CctvCaptureIcon />*/}
          {/*</button>*/}
          {/*<button>*/}
          {/*  <CctvMicIcon />*/}
          {/*</button>*/}
          {/*<button>*/}
          {/*  <CctvRecordIcon />*/}
          {/*</button>*/}
          {/*<button>*/}
          {/*  <CctvSettingIcon />*/}
          {/*</button>*/}
          {/*<button onClick={reloadCctv}>Refresh</button>*/}
        </div>
        <div className="control-buttons">
          <button onClick={onClickPrevPage}>
            <CctvMultiviewArrowIcon direction={"left"} />
          </button>
          <span>
            {viewPage}/{getTotalPageSize()}
          </span>
          <button onClick={onClickNextPage}>
            <CctvMultiviewArrowIcon direction={"right"} />
          </button>
        </div>
      </div>
    </div>
  );
}

export default MultiView;
