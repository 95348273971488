import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../index";

type SpacePanelState = {
  visible: boolean;
};

const initialState: SpacePanelState = {
  visible: false,
};

const slice = createSlice({
  name: "space/panel",
  initialState,
  reducers: {
    resetSpacePanel: (state: SpacePanelState) => ({
      ...state,
      visible: initialState.visible,
    }),
    updateSpacePanelVisible: (state: SpacePanelState, { payload }) => ({
      ...state,
      visible: payload,
    }),
  },
});

export const spacePanelSelector = (state: RootState) => state.space.panel;
export const { resetSpacePanel, updateSpacePanelVisible } = slice.actions;

export default slice;
