import { Button, Modal } from "antd";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  resetSetting,
  settingSelector,
  updateLogoutModal,
} from "../../store/setting";
import { AppDispatch } from "../../store";
import { useNavigate } from "react-router-dom";

function LogoutModal() {
  const localStorage = window.localStorage;
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { logoutModal } = useSelector(settingSelector);

  const onClose = useCallback(async () => {
    dispatch(updateLogoutModal(false));
  }, [dispatch]);

  const onAgreeClick = () => {
    localStorage.setItem("accessToken", "");
    localStorage.setItem("username", "");
    localStorage.setItem("email", "");
    localStorage.setItem("id", "");
    dispatch(resetSetting());
    navigate("/login");
  };

  return (
    <Modal
      className="setting-logout-modal"
      visible={logoutModal}
      footer={[
        <Button onClick={onClose} key="close">
          취소
        </Button>,
        <Button
          key="submit"
          type="primary"
          danger
          onClick={onAgreeClick}
          htmlType="submit"
        >
          로그아웃
        </Button>,
      ]}
      onCancel={onClose}
    >
      <h1>로그아웃</h1>
      <p>정말로 로그아웃 하시겠어요?</p>
    </Modal>
  );
}

export default LogoutModal;
