import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { joinSelector, updateJoinForm } from "../../../store/join";
import {
  resetPasswordSelector,
  updateResetPasswordForm,
} from "../../../store/reset-password";
import { passwordRegex } from "../../../utils/regax";
import { Button, Input } from "antd";
import "./PasswordForm.scss";

function PasswordForm() {
  const [type1, setType1] = useState(true);
  const [type2, setType2] = useState(true);

  const [valid, setValid] = useState(true);
  const [compare, setCompare] = useState(true);

  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const route = pathname.split("/")[2];

  const { form } = useSelector(joinSelector);
  const { form: resetPasswordForm } = useSelector(resetPasswordSelector);

  useEffect(() => {
    if (route === "join" && form.password1 === form.password2) setCompare(true);
    if (
      route === "reset-password" &&
      resetPasswordForm.password1 === resetPasswordForm.password2
    )
      setCompare(true);
  }, [
    form.password1,
    form.password2,
    resetPasswordForm.password1,
    resetPasswordForm.password2,
    route,
  ]);

  const onPasswordVisible = useCallback(
    (type: string) => () => {
      type === "type1" ? setType1(!type1) : setType2(!type2);
    },
    [type1, type2]
  );

  const onPasswordChange = useCallback(
    (e: any) => {
      const { value } = e.target;
      const isValid = passwordRegex(value);
      if (isValid) {
        route === "join"
          ? dispatch(updateJoinForm({ password1: value }))
          : dispatch(updateResetPasswordForm({ password1: value }));
      } else {
        route === "join"
          ? dispatch(updateJoinForm({ password1: undefined }))
          : dispatch(updateResetPasswordForm({ password1: undefined }));
      }
      setValid(isValid);
    },
    [dispatch]
  );

  const onPasswordRepeatChange = useCallback(
    (e: any) => {
      const { value } = e.target;

      const isCompare =
        resetPasswordForm.password1 === value || form.password1 === value;
      setCompare(isCompare);
      route === "join"
        ? dispatch(updateJoinForm({ password2: value }))
        : dispatch(updateResetPasswordForm({ password2: value }));
    },
    [form.password1, resetPasswordForm.password1, dispatch]
  );

  return (
    <div className="password-form">
      <div className={`password-input-form ${!valid ? "invalid" : ""}`}>
        <label>비밀번호</label>
        <Input
          type={type1 ? "password" : "text"}
          autoComplete="off"
          placeholder="8-12자 사이의 숫자, 영문 소문자, 특수문자"
          onChange={onPasswordChange}
          addonAfter={
            <Button
              onClick={onPasswordVisible("type1")}
              className={type1 ? "invisible" : "visible"}
            >
              &nbsp;
            </Button>
          }
        />
      </div>
      <div className="password-message">
        {!valid && "규칙에 알맞은 비밀번호를 입력해주세요."}
      </div>

      <div className={`password-input-form ${!compare ? "invalid" : ""}`}>
        <label>비밀번호 재입력</label>
        <Input
          type={type2 ? "password" : "text"}
          autoComplete="off"
          placeholder="비밀번호를 다시 한 번 입력해주세요."
          onChange={onPasswordRepeatChange}
          addonAfter={
            <Button
              onClick={onPasswordVisible("type2")}
              className={type2 ? "invisible" : "visible"}
            >
              &nbsp;
            </Button>
          }
        />
      </div>
      <div className="password-message">
        {!compare && "입력한 비밀번호가 다릅니다."}
      </div>
    </div>
  );
}

export default PasswordForm;
