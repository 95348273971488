import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../index";
import { deleteWidgetListInDashboard } from "../../../../api/dashboard/dashboard";
import { sleep } from "../../../../common/utils";

type DashboardWidgetDeleteState = {
  loading: boolean;
  error?: string | Error;
  selectedWidgetList: string[];
  success: boolean;
  init: boolean;
};

const initialState: DashboardWidgetDeleteState = {
  loading: false,
  selectedWidgetList: [],
  success: false,
  init: false,
};

export const fetchDeleteWidgetListInDashboard = createAsyncThunk(
  "dashboard/widget/delete",
  async ({ id, widgetIdList }: { id: number; widgetIdList: number[] }) => {
    await sleep(100);
    const { result } = await deleteWidgetListInDashboard(id, widgetIdList);
    return result;
  }
);

const slice = createSlice({
  name: "dashboard/widget/delete",
  initialState,
  reducers: {
    addSelectedWidget: (state: DashboardWidgetDeleteState, { payload }) => ({
      ...state,
      selectedWidgetList: [...state.selectedWidgetList, payload],
    }),
    removeSelectedWidget: (state: DashboardWidgetDeleteState, { payload }) => ({
      ...state,
      selectedWidgetList: state.selectedWidgetList.filter(
        (selectedWidget) => selectedWidget !== payload
      ),
    }),
    resetDashboardWidgetDeleteState: (state: DashboardWidgetDeleteState) => ({
      ...state,
      loading: initialState.loading,
      selectedWidgetList: initialState.selectedWidgetList,
      success: initialState.success,
    }),
    updateInit: (state: DashboardWidgetDeleteState, { payload }) => ({
      ...state,
      init: payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDeleteWidgetListInDashboard.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchDeleteWidgetListInDashboard.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = undefined;
        state.success = payload;
      }
    );
    builder.addCase(
      fetchDeleteWidgetListInDashboard.rejected,
      (state, { error }) => {
        state.loading = false;
        state.error = error.message as string;
        state.success = false;
      }
    );
  },
});

export const dashboardWidgetDeleteSelector = (state: RootState) =>
  state.dashboard.widget.delete;
export const {
  addSelectedWidget,
  resetDashboardWidgetDeleteState,
  removeSelectedWidget,
  updateInit,
} = slice.actions;

export default slice;
