import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../index";
import { sleep } from "../../../common/utils";
import {
  ConditionSelectType,
  getConditionListByDeviceType,
} from "../../../api/recipe/recipe";

type ConditionSelectListState = {
  loading: boolean;
  error?: string | Error;
  conditionList: ConditionSelectType[];
};

const initialState: ConditionSelectListState = {
  loading: false,
  error: "",
  conditionList: [],
};

export const fetchGetConditionList = createAsyncThunk(
  "recipe/conditionList",
  async (deviceType: string) => {
    await sleep(100);
    const { result } = await getConditionListByDeviceType(deviceType);
    return result;
  }
);
const slice = createSlice({
  name: "recipe/conditionList",
  initialState,
  reducers: {
    resetRecipeConditionList: (state: ConditionSelectListState) => ({
      ...state,
      loading: initialState.loading,
      error: initialState.error,
      conditionList: initialState.conditionList,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchGetConditionList.pending,
      (state: ConditionSelectListState) => {
        state.loading = true;
      }
    );
    builder.addCase(
      fetchGetConditionList.fulfilled,
      (state: ConditionSelectListState, { payload }) => {
        state.loading = false;
        state.error = undefined;
        state.conditionList = payload;
      }
    );
    builder.addCase(
      fetchGetConditionList.rejected,
      (state: ConditionSelectListState, { error }) => {
        state.loading = false;
        state.error = error.message as string;
        state.conditionList = [];
      }
    );
  },
});

export const recipeConditionListSelector = (state: RootState) =>
  state.recipe.conditionList;
export const { resetRecipeConditionList } = slice.actions;
export default slice;
