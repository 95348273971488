import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../index";
import { sleep } from "../../../common/utils";
import { getCctvList, getCctvPlayInformation } from "../../../api/cctv/cctv";

type CctvState = {
  loading: boolean;
  error?: string | Error;
  cctvPlayInformation: {
    message: string;
    result: {
      id: number;
      token: string;
      cameraId: string;
      serialNo: string;
      scvHostPort: string;
      hlsStreamUrl: string;
    };
  };
};

const initialState: CctvState = {
  loading: false,
  error: undefined,
  cctvPlayInformation: {
    message: "",
    result: {
      id: 0,
      token: "",
      cameraId: "",
      serialNo: "",
      scvHostPort: "",
      hlsStreamUrl: "",
    },
  },
};

export const fetchGetCctvPlayInformation = createAsyncThunk(
  "cctv/play/information",
  async (cameraId: number) => {
    await sleep(100);
    const { data } = await getCctvPlayInformation(cameraId);
    data.result.id = cameraId;
    return data;
  }
);

const slice = createSlice({
  name: "cctv/play/information",
  initialState,
  reducers: {
    resetCctvPlayInformation: (state) => ({
      ...state,
      cctvPlayInformation: initialState.cctvPlayInformation,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetCctvPlayInformation.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(
      fetchGetCctvPlayInformation.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = undefined;
        state.cctvPlayInformation = payload;
      }
    );
    builder.addCase(
      fetchGetCctvPlayInformation.rejected,
      (state, { error }) => {
        state.loading = false;
        state.error = error.message;
      }
    );
  },
});

export const cctvPlatInformationSelector = (state: RootState) =>
  state.cctv.play;
export const { resetCctvPlayInformation } = slice.actions;

export default slice;
