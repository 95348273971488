import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../index";
import { sleep } from "../../../common/utils";
import {
  getCctvEvents,
  getCctvList,
  getCctvPlayInformation,
  getCctvReceiptVideo,
} from "../../../api/cctv/cctv";

type CctvReceiptVideoResponseType = {
  cameraId: string;
  cvrHostPort: string;
  hlsStreamUrl: string;
  serialNo: string;
  token: string;
};

type CctvEventState = {
  loading: boolean;
  error?: string | Error;
  CctvReceiptVideo: {
    message: string;
    result: CctvReceiptVideoResponseType;
  };
};

const initialState: CctvEventState = {
  loading: false,
  error: undefined,
  CctvReceiptVideo: {
    message: "",
    result: {
      cameraId: "",
      cvrHostPort: "",
      hlsStreamUrl: "",
      serialNo: "",
      token: "",
    },
  },
};

export const fetchGetCctvReceiptVideo = createAsyncThunk(
  "cctv/receipt/video",
  async ({ id, start }: { id: number; start: string }) => {
    await sleep(100);
    const { data } = await getCctvReceiptVideo(id, start);
    return data;
  }
);

const slice = createSlice({
  name: "cctv/receipt/video",
  initialState,
  reducers: {
    resetCctvReceiptVideo: (state) => ({
      ...state,
      CctvReceiptVideo: initialState.CctvReceiptVideo,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetCctvReceiptVideo.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(
      fetchGetCctvReceiptVideo.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = undefined;
        state.CctvReceiptVideo = payload;
      }
    );
    builder.addCase(fetchGetCctvReceiptVideo.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    });
  },
});

export const cctvReceiptVideoSelector = (state: RootState) =>
  state.cctv.cctvReceiptVideo;
export const { resetCctvReceiptVideo } = slice.actions;

export default slice;
