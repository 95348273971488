import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sleep } from "../../common/utils";
import { updateName } from "../../api/devices/device";
import { RootState } from "../index";

type UpdateDeviceNameState = {
  loading: boolean;
  error?: string | Error;
  success: boolean;
};

const initialState: UpdateDeviceNameState = {
  loading: false,
  error: undefined,
  success: false,
};

export const fetchUpdateDeviceName = createAsyncThunk(
  "update-device-name",
  async ({ id, name }: { id: number; name: string }) => {
    await sleep(100);
    const { result } = await updateName(id.toString(), name);
    return result;
  }
);

const slice = createSlice({
  name: "update-device-name",
  initialState,
  reducers: {
    resetUpdateDeviceName: (state) => ({
      ...state,
      success: initialState.success,
      loading: initialState.loading,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUpdateDeviceName.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(fetchUpdateDeviceName.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = undefined;
      state.success = payload;
    });
    builder.addCase(fetchUpdateDeviceName.rejected, (state, { error }) => {
      state.loading = false;
      state.success = false;
      state.error = error.message;
    });
  },
});

export const deviceUpdateNameSelector = (state: RootState) =>
  state.updateDeviceName;
export const { resetUpdateDeviceName } = slice.actions;

export default slice;
