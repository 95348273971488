import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sleep } from "../../../common/utils";
import { RootState } from "../../index";
import { getSpaceDetail } from "../../../api/space/space";

type SpaceType = {
  id: number;
  name: string;
  address: string;
  imageUrl: string;
  lan: number;
  lat: number;
};

type SpaceDetailState = {
  loading: boolean;
  error?: string | Error;
  space?: SpaceType;
  currentSpaceId?: number;
  spaceTabList: SpaceType[];
};

const initialState: SpaceDetailState = {
  loading: false,
  error: undefined,
  space: undefined,
  // currentSpaceId: undefined,
  currentSpaceId: 0,
  spaceTabList: [],
};

export const fetchGetSpaceDetail = createAsyncThunk(
  "space/detail",
  async (spaceId: number) => {
    await sleep(100);
    const { result } = await getSpaceDetail(spaceId);
    return result;
  }
);

const slice = createSlice({
  name: "space/detail",
  initialState,
  reducers: {
    resetSpaceDetail: (state: SpaceDetailState) => ({
      ...state,
      space: initialState.space,
      spaceTabList: initialState.spaceTabList,
      loading: initialState.loading,
      currentSpaceId: initialState.currentSpaceId,
    }),

    updateCurrentSpaceId: (state: SpaceDetailState, { payload }) => ({
      ...state,
      currentSpaceId: payload,
    }),
    updateSpaceTabList: (state: SpaceDetailState, { payload }) => ({
      ...state,
      spaceTabList: payload,
    }),
    renameSpaceTab: (
      state: SpaceDetailState,
      { payload: { id, name, address } }
    ) => ({
      ...state,
      spaceTabList: state.spaceTabList.map((spaceTab) => {
        if (spaceTab.id === id) {
          return {
            ...spaceTab,
            name,
            address,
          };
        }
        return spaceTab;
      }),
    }),
    removeSpaceTab: (state: SpaceDetailState, { payload }) => ({
      ...state,
      spaceTabList: state.spaceTabList.filter(
        (spaceTab) => spaceTab.id !== payload
      ),
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetSpaceDetail.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(fetchGetSpaceDetail.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = undefined;
      state.space = {
        ...state.space,
        ...payload,
      };
      state.currentSpaceId = payload ? payload.id : undefined;
    });
    builder.addCase(fetchGetSpaceDetail.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    });
  },
});

export const spaceDetailSelector = (state: RootState) => state.space.detail;
export const {
  resetSpaceDetail,
  updateCurrentSpaceId,
  updateSpaceTabList,
  removeSpaceTab,
  renameSpaceTab,
} = slice.actions;

export default slice;
