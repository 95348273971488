import { Button } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../store";
import {
  updateToastcamModalVisible,
  updateToastcamStep,
} from "../../../../../store/setting/toascam";
import { settingToastcamListSelector } from "../../../../../store/setting/toascam/list";

function ToastcamComplete() {
  const { selectedCam, selectedSpace } = useSelector(
    settingToastcamListSelector
  );
  const dispatch = useDispatch<AppDispatch>();
  return (
    <>
      <h1>카메라 연동 완료</h1>
      <p>토스트캠 연동이 완료되었습니다.</p>
      <div className="cam-main">
        <div className="cam-detail">
          <p>카메라: {selectedCam ? selectedCam.name : "-"}</p>
          <p>이름: {selectedCam ? selectedCam.newName : "-"}</p>
          <p>장소: {selectedSpace ? selectedSpace.name : "-"}</p>
        </div>
      </div>
      <div className="cam-footer">
        <Button
          disabled={!selectedCam}
          onClick={() => dispatch(updateToastcamStep())}
          type="primary"
        >
          다음
        </Button>
        {selectedCam && (
          <Button onClick={() => dispatch(updateToastcamModalVisible(false))}>
            닫기
          </Button>
        )}
      </div>
    </>
  );
}

export default ToastcamComplete;
