export const resetPasswordMessages = [
  {
    step: 1,
    title: "새로운 비밀번호를 설정합니다",
    subtitle: "계정에 사용중인 이메일을 입력해주세요.",
  },
  {
    step: 2,
    title: "인증번호가 담긴 이메일을 보냈어요",
    subtitle: "이메일에 담긴 6자리 인증번호를 입력해주세요.",
  },
  {
    step: 3,
    title: "새로운 비밀번호를 입력해주세요",
    subtitle: "8-20자 사이의 영문, 숫자를 조합하여 비밀번호를 만들어주세요.",
  },
  {
    step: 4,
    title: "비밀번호를 성공적으로 변경했어요",
    subtitle: "새로운 비밀번호를 사용해 다시 로그인해주세요.",
  },
];
