import React, { useEffect } from "react";
import { Route, Routes } from "react-router";
import { useLocation } from "react-router-dom";
import useIsMobile from "../../utils/useIsMobile";
import JoinProcess from "../join/JoinProcess";
import ResetPasswordProcess from "../reset-password/ResetPasswordProcess";
import LoginForm from "./LoginForm";
import "./Login.scss";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { resetSend } from "../../store/send-code";

function LoginContainer() {
  const isMobile = useIsMobile();
  const { pathname } = useLocation();
  const route = pathname.split("/")[2];
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(resetSend());
  }, []);

  return (
    <div className="login-container">
      <div className="hejhome-login">
        <div className="hejhome-login-content">
          <img
            className="hejhome-square-logo"
            src={`${process.env.REACT_APP_S3_URL}/1.0/logos/HejhomeLoginLogo.png`}
            alt="square-logo"
          />
          {!route && isMobile && (
            <div className="hejhome-login-logo">
              <img
                src={`${process.env.REACT_APP_S3_URL}/1.0/logos/HejhomeLoginLogo.png`}
                alt="logo"
              />
            </div>
          )}
          <Routes>
            <Route path="/" element={<LoginForm />} />
            <Route path="/join" element={<JoinProcess />} />
            <Route path="/reset-password" element={<ResetPasswordProcess />} />
          </Routes>
        </div>
      </div>
      <div className="background" />
      <div className="background crop" />
    </div>
  );
}

export default LoginContainer;
