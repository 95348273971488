import React, { useEffect, useState } from "react";
import { CctvDetailType } from "../../api/cctv/cctv";
import { WarningOutlined, LoadingOutlined } from "@ant-design/icons";

function CameraView({
  id,
  cameraIndex,
  viewSize,
  viewPage,
  selectedSpaceName,
}: {
  id: number;
  cameraIndex: number;
  viewSize: number;
  viewPage: number;
  selectedSpaceName: string;
}) {
  const hls = new window.Hls({ liveBackBufferLength: 10 });
  const [cctvDetail, setCctvDetail] = useState<CctvDetailType>({
    id: 0,
    deviceName: "",
    spaceName: "",
    thumbnailUrl: "",
  });
  const [isError, setIsError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  let cameraReloadTimeOut: any;
  let cameraErrorTimeOut: any;

  useEffect(() => {
    if (!id) return;

    getCctvDetail(id);
    getCctvPlayInformation(id);
  }, [id]);

  useEffect(() => {
    console.log("isError", isError);
    console.log("isLoaded", isLoaded);
  }, [isError, isLoaded]);

  const getCctvDetail = async (deviceId: number) => {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/v1.0/openapi/cctv/${deviceId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    const { result } = await response.json();
    setCctvDetail({ ...result });
  };

  const getCctvHlsInfo = async (deviceId: number) => {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/v1.0/openapi/cctv/play/${deviceId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return await response.json();
  };

  const hlsManifestParsedEvent = async () => {
    const videoTag = document.getElementById(
      `cctv-play-${id}`
    ) as HTMLMediaElement;

    console.log(`play(${id})...`);
    if (!cameraReloadTimeOut) {
      clearTimeout(cameraReloadTimeOut);
    }
    if (cameraErrorTimeOut) clearTimeout(cameraErrorTimeOut);
    const playPromise = videoTag.play();
    playPromise
      .then((_) => {
        console.log("ok...");
        setIsLoaded(true);
        setIsError(false);
      })
      .catch(() => {
        console.log("catch...");
        hls.detachMedia();
        setIsLoaded(true);
        setIsError(true);
      });
  };

  // const hslLevelLoadedEvent = (event: any, data: any) => {
  //   console.log(data.details.totalduration);
  // }

  // async function hslErrorHandler(event: any, data: any) {
  //   if (["networkError"].includes(data.type)) {
  //     // handle Hls Error
  //   }
  // }

  const getCctvPlayInformation = async (deviceId: number) => {
    const {
      data: { result },
    } = await getCctvHlsInfo(deviceId);

    if (result.hlsStreamUrl) {
      const videoTag = document.getElementById(
        `cctv-play-${id}`
      ) as HTMLMediaElement;
      if (window.Hls.isSupported() && videoTag) {
        hls.loadSource(result.hlsStreamUrl);
        hls.attachMedia(videoTag);
        // hls.on(Hls.Events.LEVEL_LOADED, hslLevelLoadedEvent);
        hls.on(window.Hls.Events.MANIFEST_PARSED, hlsManifestParsedEvent);
        // hls.on(Hls.Events.ERROR, hslErrorHandler);

        videoTag.addEventListener("waiting", (event) => {
          cameraReloadTimeOut = setTimeout(reloadCamera, 10000);
        });

        videoTag.addEventListener("playing", (event) => {
          if (!isLoaded) setIsLoaded(true);
          if (cameraReloadTimeOut) clearTimeout(cameraReloadTimeOut);
          if (cameraErrorTimeOut) clearTimeout(cameraErrorTimeOut);
        });

        videoTag.addEventListener("error", (event) => {
          console.log(`cctv-play-${id} is error....`);
          if (cameraReloadTimeOut) clearTimeout(cameraReloadTimeOut);
          if (cameraErrorTimeOut) clearTimeout(cameraErrorTimeOut);

          cameraReloadTimeOut = setTimeout(reloadCamera, 10000);
          cameraErrorTimeOut = setTimeout(onErrorCamera, 15000);
        });
      }
    }
  };

  const checkIsViewBoolean = () => {
    // return true;
    if (viewPage === 1 && cameraIndex > viewSize - 1) return false;

    return !(
      viewSize * (viewPage - 1) > cameraIndex ||
      viewSize * viewPage - 1 < cameraIndex
    );
  };

  const reloadCamera = async (restart?: boolean) => {
    if (isError) setIsError(false);
    if (isLoaded) setIsLoaded(false);

    hls.detachMedia();

    await getCctvDetail(id);
    const {
      data: { result },
    } = await getCctvHlsInfo(id);

    if (result.hlsStreamUrl) {
      console.log("reloadCamera...", `cctv-play-${id}`);
      const videoTag = document.getElementById(
        `cctv-play-${id}`
      ) as HTMLMediaElement;
      hls.loadSource(result.hlsStreamUrl);
      hls.startLoad();
      hls.attachMedia(videoTag);

      if (cameraReloadTimeOut) clearTimeout(cameraReloadTimeOut);

      if (restart) await startCamera();
    }
  };

  const onErrorCamera = () => {
    setIsError(true);
  };

  const startCamera = async () => {
    const videoTag = document.getElementById(
      `cctv-play-${id}`
    ) as HTMLMediaElement;
    if (videoTag.duration) videoTag.currentTime = videoTag.duration;
    await videoTag.play();
  };

  return (
    <div
      className="player-wrapper"
      style={{ display: checkIsViewBoolean() ? "" : "none" }}
    >
      <div className="player-content">
        {!isLoaded && (
          <div
            style={{
              position: "absolute",
              color: "red",
              left: "35%",
              top: "40%",
              display: "grid",
            }}
          >
            <LoadingOutlined
              style={{
                fontSize: "60px",
              }}
            />
          </div>
        )}
        {isError && (
          <div
            style={{
              position: "absolute",
              color: "red",
              left: "35%",
              top: "40%",
              display: "grid",
            }}
          >
            <WarningOutlined
              style={{
                fontSize: "60px",
              }}
              onClick={() => {
                reloadCamera(true);
              }}
            />
            <span>카메라와의 연결이 끊겼습니다.</span>
          </div>
        )}
        <video
          key={id}
          id={`cctv-play-${id}`}
          autoPlay={false}
          controls={false}
          style={{
            width: "100%",
            height: "100%",
            transform: "scale(1) translate(0px 0px)",
          }}
        />
        <div className="player-text">
          <p className="camera-name">{cctvDetail.deviceName}</p>
          <p className="space-name">{selectedSpaceName}</p>
        </div>
      </div>
    </div>
  );
}

export default CameraView;
