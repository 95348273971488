import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../index";
import { sleep } from "../../../../common/utils";
import { removeDeviceListInSpace } from "../../../../api/space/space";

type removeSpaceDeviceListState = {
  loading: boolean;
  error?: string | Error;
  success: boolean;
  init: boolean;
};

const initialState: removeSpaceDeviceListState = {
  loading: false,
  success: false,
  init: false,
};

export const fetchDeleteDeviceListInSpace = createAsyncThunk(
  "space/deviceSetting/remove",
  async ({ id, deviceList }: { id: number; deviceList: number[] }) => {
    await sleep(100);
    const { result } = await removeDeviceListInSpace(id, { deviceList });
    return result;
  }
);

const slice = createSlice({
  name: "space/deviceSetting/remove",
  initialState,
  reducers: {
    resetRemoveSpaceDeviceList: (state: removeSpaceDeviceListState) => ({
      ...state,
      loading: initialState.loading,
      success: initialState.success,
      init: initialState.init,
    }),
    updateRemoveInit: (state: removeSpaceDeviceListState, { payload }) => ({
      ...state,
      init: payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDeleteDeviceListInSpace.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchDeleteDeviceListInSpace.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = undefined;
        state.success = payload;
      }
    );
    builder.addCase(
      fetchDeleteDeviceListInSpace.rejected,
      (state, { error }) => {
        state.loading = false;
        state.error = error.message as string;
        state.success = false;
      }
    );
  },
});

export const removeSpaceDeviceListSelector = (state: RootState) =>
  state.space.deviceSetting.remove;
export const { resetRemoveSpaceDeviceList, updateRemoveInit } = slice.actions;

export default slice;
