import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../index";
import {
  ActionType,
  ConditionType,
  device,
  timer,
  weatherCondition,
} from "../register";
import { sleep } from "../../../common/utils";
import { postUpdateRecipe } from "../../../api/recipe/recipe";

export type RecipeUpdateState = {
  modal: boolean;
  recipeId: number;
  currentActionId?: number;
  currentConditionId?: number;
  oneClick: boolean;
  timeInfo?: {
    repeat?: boolean;
    during?: boolean;
    dayList?: string[];
    startTime?: string;
    endTime?: string;
    display?: {
      startTime?: string;
      endTime?: string;
    };
  };
  conditionList: ConditionType[];
  actionList: ActionType[];
  loading: boolean;
  updateRecipeId: string;
  error?: Error | string;
  success?: boolean;
  message?: any;
};

const initialState: RecipeUpdateState = {
  modal: false,
  recipeId: 0,
  currentActionId: undefined,
  currentConditionId: undefined,
  oneClick: false,
  timeInfo: undefined,
  conditionList: [],
  actionList: [],
  loading: false,
  updateRecipeId: "0",
  success: undefined,
};

export const fetchUpdateRecipe = createAsyncThunk(
  "recipe/update",
  async ({ id, body }: { id: string; body: any }) => {
    await sleep(100);
    const { result, message } = await postUpdateRecipe(id, body);
    return { result, message };
  }
);

const slice = createSlice({
  name: "recipe/update",
  initialState,
  reducers: {
    resetRecipeUpdateModal: (state: RecipeUpdateState) => ({
      ...state,
      modal: initialState.modal,
      loading: initialState.loading,
      currentActionId: initialState.currentActionId,
      currentConditionId: initialState.currentConditionId,
      oneClick: initialState.oneClick,
      timeInfo: initialState.timeInfo,
      conditionList: initialState.conditionList,
      actionList: initialState.actionList,
      updateRecipeId: initialState.updateRecipeId,
      success: initialState.success,
    }),
    updateCurrentUpdateActionId: (state: RecipeUpdateState, { payload }) => ({
      ...state,
      currentActionId: payload,
    }),
    updateCurrentUpdateConditionId: (
      state: RecipeUpdateState,
      { payload }
    ) => ({
      ...state,
      currentConditionId: payload,
    }),
    updateRecipeId: (state: RecipeUpdateState, { payload }) => ({
      ...state,
      updateRecipeId: payload,
    }),
    updateOpenRecipeUpdateModal: (state: RecipeUpdateState, { payload }) => ({
      ...state,
      modal: payload,
    }),
    updateRecipeInfo: (
      state: RecipeUpdateState,
      { payload: { oneClick, timeInfo, conditionList, actionList } }
    ) => ({
      ...state,
      oneClick,
      timeInfo,
      conditionList,
      actionList,
    }),
    updateRecipeUpdateDayList: (state: RecipeUpdateState, { payload }) => ({
      ...state,
      timeInfo: {
        ...state?.timeInfo,
        dayList: payload,
      },
    }),
    updateRecipeUpdateRepeat: (state: RecipeUpdateState, { payload }) => ({
      ...state,
      timeInfo: {
        ...state.timeInfo,
        repeat: payload,
      },
    }),
    updateRecipeUpdateDuring: (state: RecipeUpdateState, { payload }) => ({
      ...state,
      timeInfo: {
        ...state.timeInfo,
        during: payload,
      },
    }),
    updateRecipeUpdateDisplayStartTime: (
      state: RecipeUpdateState,
      { payload }
    ) => ({
      ...state,
      timeInfo: {
        ...state.timeInfo,
        display: {
          ...state.timeInfo?.display,
          startTime: payload,
        },
      },
    }),
    updateRecipeUpdateDisplayEndTime: (
      state: RecipeUpdateState,
      { payload }
    ) => ({
      ...state,
      timeInfo: {
        ...state.timeInfo,
        display: {
          ...state.timeInfo?.display,
          endTime: payload,
        },
      },
    }),
    updateRecipeUpdateStartTime: (state: RecipeUpdateState, { payload }) => ({
      ...state,
      timeInfo: {
        ...state.timeInfo,
        startTime: payload,
      },
    }),
    updateRecipeUpdateEndTime: (state: RecipeUpdateState, { payload }) => ({
      ...state,
      timeInfo: {
        ...state.timeInfo,
        endTime: payload,
      },
    }),
    deleteRecipeUpdateEndTime: (state: RecipeUpdateState) => ({
      ...state,
      timeInfo: {
        ...state.timeInfo,
        endTime: undefined,
        display: {
          ...state.timeInfo?.display,
          endTime: undefined,
        },
      },
    }),
    updateRecipeUpdateConditionWeather: (
      state: RecipeUpdateState,
      { payload: { id } }
    ) => ({
      ...state,
      conditionList: [
        ...state.conditionList,
        { id, type: "weather", condition: false },
      ],
    }),
    updateRecipeUpdateConditionType: (
      state: RecipeUpdateState,
      { payload: { id, type } }
    ) => ({
      ...state,
      conditionList: state.conditionList.map((conditionInfo, index) =>
        index === id
          ? {
              ...conditionInfo,
              type,
              condition: false,
            }
          : conditionInfo
      ),
    }),
    updateRecipeUpdateConditionWeatherLocation: (
      state: RecipeUpdateState,
      { payload: { id, location } }
    ) => ({
      ...state,
      conditionList: state.conditionList.map((conditionInfo, index) =>
        index === id
          ? {
              ...conditionInfo,
              condition: {
                ...(conditionInfo.condition as weatherCondition),
                location,
              },
            }
          : conditionInfo
      ),
    }),
    updateRecipeUpdateConditionWeatherKey: (
      state: RecipeUpdateState,
      { payload: { id, key } }
    ) => ({
      ...state,
      conditionList: state.conditionList.map((conditionInfo, index) =>
        index === id
          ? {
              ...conditionInfo,
              condition: {
                ...(conditionInfo.condition as weatherCondition),
                key,
              },
            }
          : conditionInfo
      ),
    }),
    updateRecipeUpdateConditionWeatherValue: (
      state: RecipeUpdateState,
      { payload: { id, value } }
    ) => ({
      ...state,
      conditionList: state.conditionList.map((conditionInfo, index) =>
        index === id
          ? {
              ...conditionInfo,
              condition: {
                ...(conditionInfo.condition as weatherCondition),
                value,
              },
            }
          : conditionInfo
      ),
    }),
    updateRecipeUpdateConditionWeatherInputValue: (
      state: RecipeUpdateState,
      { payload: { id, input } }
    ) => ({
      ...state,
      conditionList: state.conditionList.map((conditionInfo, index) =>
        index === id
          ? {
              ...conditionInfo,
              condition: {
                ...(conditionInfo.condition as weatherCondition),
                input,
              },
            }
          : conditionInfo
      ),
    }),
    updateRecipeUpdateConditionDevice: (
      state: RecipeUpdateState,
      { payload: { id, pid, deviceId, deviceType, name, placeName } }
    ) => ({
      ...state,
      conditionList: state.conditionList.map((conditionInfo, index) =>
        index === id
          ? {
              ...conditionInfo,
              condition: {
                ...(conditionInfo.condition as device),
                pid,
                deviceId,
                deviceType,
                name,
                input: undefined,
                placeName,
              },
            }
          : conditionInfo
      ),
    }),
    updateRecipeUpdateConditionDeviceKey: (
      state: RecipeUpdateState,
      { payload: { id, key } }
    ) => ({
      ...state,
      conditionList: state.conditionList.map((conditionInfo, index) =>
        index === id
          ? {
              ...conditionInfo,
              condition: {
                ...(conditionInfo.condition as device),
                key,
              },
            }
          : conditionInfo
      ),
    }),
    updateRecipeUpdateConditionDeviceValue: (
      state: RecipeUpdateState,
      { payload: { id, value } }
    ) => ({
      ...state,
      conditionList: state.conditionList.map((conditionInfo, index) =>
        index === id
          ? {
              ...conditionInfo,
              condition: {
                ...(conditionInfo.condition as device),
                value,
              },
            }
          : conditionInfo
      ),
    }),
    updateRecipeUpdateConditionDeviceDisplayKey: (
      state: RecipeUpdateState,
      { payload: { id, key } }
    ) => ({
      ...state,
      conditionList: state.conditionList.map((conditionInfo, index) =>
        index === id
          ? {
              ...conditionInfo,
              condition: {
                ...(conditionInfo.condition as device),
                display: {
                  ...((conditionInfo.condition as device).display as {
                    key: string;
                    value: string;
                  }),
                  key,
                },
                input: undefined,
              },
            }
          : conditionInfo
      ),
    }),
    updateRecipeUpdateConditionDeviceDisplayValue: (
      state: RecipeUpdateState,
      { payload: { id, value } }
    ) => ({
      ...state,
      conditionList: state.conditionList.map((conditionInfo, index) =>
        index === id
          ? {
              ...conditionInfo,
              condition: {
                ...(conditionInfo.condition as device),
                display: {
                  ...((conditionInfo.condition as device).display as {
                    key: string;
                    value: string;
                  }),
                  value,
                },
              },
            }
          : conditionInfo
      ),
    }),
    updateRecipeUpdateConditionDeviceInputValue: (
      state: RecipeUpdateState,
      { payload: { id, input } }
    ) => ({
      ...state,
      conditionList: state.conditionList.map((conditionInfo, index) =>
        index === id
          ? {
              ...conditionInfo,
              condition: {
                ...(conditionInfo.condition as device),
                input,
              },
            }
          : conditionInfo
      ),
    }),
    deleteRecipeUpdateConditionById: (
      state: RecipeUpdateState,
      { payload }: { payload: number }
    ) => ({
      ...state,
      conditionList: state.conditionList.reduce((list, condition) => {
        if (condition.id === payload) {
          return list;
        } else if (condition.id > payload) {
          condition = {
            ...condition,
            id: condition.id - 1,
          };
        }
        list.push(condition);
        return list;
      }, [] as ConditionType[]),
    }),
    addRecipeUpdateAction: (state: RecipeUpdateState, { payload: { id } }) => ({
      ...state,
      actionList: [...state.actionList, { id, type: "recipe", action: false }],
    }),
    updateRecipeUpdateActionType: (state, { payload: { id, type } }) => ({
      ...state,
      actionList: state.actionList.map((actionInfo, index) =>
        index === id
          ? {
              ...actionInfo,
              type,
              action: undefined,
            }
          : actionInfo
      ),
    }),
    updateRecipeUpdateActionDevice: (
      state: RecipeUpdateState,
      { payload: { id, pid, deviceId, deviceType, name, placeName } }
    ) => ({
      ...state,
      actionList: state.actionList.map((actionInfo, index) =>
        index === id
          ? {
              ...actionInfo,
              action: {
                ...(actionInfo.action as device),
                pid,
                deviceId,
                deviceType,
                name,
                placeName,
              },
            }
          : actionInfo
      ),
    }),
    updateRecipeUpdateActionDeviceKey: (
      state: RecipeUpdateState,
      { payload: { id, key } }
    ) => ({
      ...state,
      actionList: state.actionList.map((actionInfo, index) =>
        index === id
          ? {
              ...actionInfo,
              action: {
                ...(actionInfo.action as device),
                key,
              },
            }
          : actionInfo
      ),
    }),
    updateRecipeUpdateActionDeviceValue: (
      state: RecipeUpdateState,
      { payload: { id, value } }
    ) => ({
      ...state,
      actionList: state.actionList.map((actionInfo, index) =>
        index === id
          ? {
              ...actionInfo,
              action: {
                ...(actionInfo.action as device),
                value,
              },
            }
          : actionInfo
      ),
    }),
    updateRecipeUpdateActionDeviceDisplayKey: (
      state: RecipeUpdateState,
      { payload: { id, key } }
    ) => ({
      ...state,
      actionList: state.actionList.map((actionInfo, index) =>
        index === id
          ? {
              ...actionInfo,
              action: {
                ...(actionInfo.action as device),
                display: {
                  ...((actionInfo.action as device).display as {
                    key: string;
                    value: string;
                  }),
                  key,
                },
              },
            }
          : actionInfo
      ),
    }),
    updateRecipeUpdateActionDeviceDisplayValue: (
      state: RecipeUpdateState,
      { payload: { id, value } }
    ) => ({
      ...state,
      actionList: state.actionList.map((actionInfo, index) =>
        index === id
          ? {
              ...actionInfo,
              action: {
                ...(actionInfo.action as device),
                display: {
                  ...((actionInfo.action as device).display as {
                    key: string;
                    value: string;
                  }),
                  value,
                },
              },
            }
          : actionInfo
      ),
    }),
    updateRecipeUpdateActionDeviceInputValue: (
      state: RecipeUpdateState,
      { payload: { id, input } }
    ) => ({
      ...state,
      actionList: state.actionList.map((actionInfo, index) =>
        index === id
          ? {
              ...actionInfo,
              action: {
                ...(actionInfo.action as device),
                input,
              },
            }
          : actionInfo
      ),
    }),
    updateRecipeUpdateActionTimerValue: (
      state: RecipeUpdateState,
      { payload: { id, time } }
    ) => ({
      ...state,
      actionList: state.actionList.map((actionInfo, index) =>
        index === id
          ? {
              ...actionInfo,
              action: {
                ...(actionInfo.action as timer),
                value: time,
              },
            }
          : actionInfo
      ),
    }),
    updateRecipeUpdateActionTimerDisplay: (
      state: RecipeUpdateState,
      { payload: { id, time } }
    ) => ({
      ...state,
      actionList: state.actionList.map((actionInfo, index) =>
        index === id
          ? {
              ...actionInfo,
              action: {
                ...(actionInfo.action as timer),
                display: time,
              },
            }
          : actionInfo
      ),
    }),
    updateRecipeUpdateActionRecipe: (
      state: RecipeUpdateState,
      { payload: { id, recipeId } }
    ) => ({
      ...state,
      actionList: state.actionList.map((actionInfo, index) =>
        index === id
          ? {
              ...actionInfo,
              action: recipeId,
            }
          : actionInfo
      ),
    }),
    deleteRecipeUpdateActionById: (state: RecipeUpdateState, { payload }) => ({
      ...state,
      actionList: state.actionList.reduce((list, action) => {
        if (action.id === payload) {
          return list;
        } else if (action.id > payload) {
          action = {
            ...action,
            id: action.id - 1,
          };
        }
        list.push(action);
        return list;
      }, [] as ActionType[]),
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUpdateRecipe.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(
      fetchUpdateRecipe.fulfilled,
      (state, { payload: { message, result } }) => {
        state.loading = false;
        state.success = result;
        state.message = message;
        state.error = undefined;
      }
    );
    builder.addCase(fetchUpdateRecipe.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    });
  },
});

export const recipeUpdateSelector = (state: RootState) => state.recipe.update;
export const {
  updateOpenRecipeUpdateModal,
  resetRecipeUpdateModal,
  updateRecipeInfo,
  updateRecipeUpdateDayList,
  updateRecipeUpdateDisplayEndTime,
  updateRecipeUpdateDisplayStartTime,
  updateRecipeUpdateDuring,
  updateRecipeUpdateEndTime,
  deleteRecipeUpdateEndTime,
  updateRecipeUpdateRepeat,
  updateRecipeUpdateStartTime,
  deleteRecipeUpdateActionById,
  deleteRecipeUpdateConditionById,
  updateCurrentUpdateActionId,
  updateCurrentUpdateConditionId,
  updateRecipeUpdateConditionDevice,
  updateRecipeUpdateConditionDeviceDisplayKey,
  updateRecipeUpdateConditionDeviceDisplayValue,
  updateRecipeUpdateConditionDeviceInputValue,
  updateRecipeUpdateConditionDeviceKey,
  updateRecipeUpdateConditionDeviceValue,
  updateRecipeUpdateConditionType,
  updateRecipeUpdateConditionWeather,
  updateRecipeUpdateConditionWeatherInputValue,
  updateRecipeUpdateConditionWeatherKey,
  updateRecipeUpdateConditionWeatherLocation,
  updateRecipeUpdateConditionWeatherValue,
  addRecipeUpdateAction,
  updateRecipeUpdateActionDevice,
  updateRecipeUpdateActionDeviceDisplayKey,
  updateRecipeUpdateActionDeviceDisplayValue,
  updateRecipeUpdateActionDeviceInputValue,
  updateRecipeUpdateActionDeviceKey,
  updateRecipeUpdateActionDeviceValue,
  updateRecipeUpdateActionTimerDisplay,
  updateRecipeUpdateActionRecipe,
  updateRecipeUpdateActionTimerValue,
  updateRecipeUpdateActionType,
  updateRecipeId,
} = slice.actions;

export default slice;
