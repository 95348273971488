import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { joinSelector, updateJoinForm } from "../../../store/join";
import {
  resetPasswordSelector,
  updateResetPasswordForm,
} from "../../../store/reset-password";
import { checkCodeSelector, updateVisible } from "../../../store/check-code";
import { message } from "antd";
import "./CertificationForm.scss";
import { fetchSendCode, sendCodeSelector } from "../../../store/send-code";
import { AppDispatch } from "../../../store";
import CertificationModal from "../certification-modal/CertificationModal";

function CertificationForm() {
  const { form } = useSelector(joinSelector);
  const { form: resetPasswordForm } = useSelector(resetPasswordSelector);
  const { error } = useSelector(checkCodeSelector);
  const { code, success } = useSelector(sendCodeSelector);
  const dispatch = useDispatch<AppDispatch>();

  const { pathname } = useLocation();
  const route = pathname.split("/")[2];

  const [min, setMin] = useState(3);
  const [sec, setSec] = useState(0);
  const [expired, setExpired] = useState(false);
  const [limit, setLimit] = useState(false);
  const time = useRef<any>(180);
  const timerId = useRef<any>(null);

  const countdown = useCallback(() => {
    clearInterval(timerId.current);
    time.current = 180;
    timerId.current = setInterval(() => {
      setMin(Number(time.current) / 60);
      setSec(time.current % 60);
      time.current -= 1;
    }, 1000);
  }, []);

  useEffect(() => {
    countdown();
    return () => {
      // @ts-ignore
      clearInterval(countdown);
    };
  }, [countdown]);

  useEffect(() => {
    if (time.current <= 0) {
      setExpired(true);
      clearInterval(timerId.current);
    }
    return () => {
      // @ts-ignore
      clearInterval(countdown);
    };
  }, [sec, countdown]);

  useEffect(() => {
    if (success === false && code === false) {
      dispatch(updateVisible(true));
      setLimit(true);
      setExpired(true);
    }
  }, [success, code]);

  const pinCodeNumFocus2 = useRef<HTMLInputElement>(null);
  const pinCodeNumFocus3 = useRef<HTMLInputElement>(null);
  const pinCodeNumFocus4 = useRef<HTMLInputElement>(null);
  const pinCodeNumFocus5 = useRef<HTMLInputElement>(null);
  const pinCodeNumFocus6 = useRef<HTMLInputElement>(null);

  const onInputChange = useCallback(
    (e: any) => {
      const { value, name } = e.target;

      let key;

      if (value.length > 1) {
        for (let i = 0; i < value.length; i++) {
          key = `number${i + 1}`;

          route === "join"
            ? dispatch(updateJoinForm({ [key]: value.substring(i, i + 1) }))
            : dispatch(
                updateResetPasswordForm({ [key]: value.substring(i, i + 1) })
              );
        }
      } else {
        key = `number${name}`;
        route === "join"
          ? dispatch(updateJoinForm({ [key]: value }))
          : dispatch(updateResetPasswordForm({ [key]: value }));
        switch (Number(name)) {
          case 1:
            pinCodeNumFocus2.current?.focus();
            break;
          case 2:
            pinCodeNumFocus3.current?.focus();
            break;
          case 3:
            pinCodeNumFocus4.current?.focus();
            break;
          case 4:
            pinCodeNumFocus5.current?.focus();
            break;
          case 5:
            pinCodeNumFocus6.current?.focus();
            break;
          case 6:
            pinCodeNumFocus6.current?.focus();
            break;
          default:
            break;
        }
      }
    },
    [route, dispatch]
  );

  const onFocus = useCallback(async (e: any) => {
    const { name } = e.target;
    const key = `number${name}`;

    route === "join"
      ? await dispatch(updateJoinForm({ [key]: undefined }))
      : await dispatch(updateResetPasswordForm({ [key]: undefined }));
  }, []);

  const onResendClick = useCallback(async () => {
    if (success === false && code === false) {
      await dispatch(updateVisible(true));
      await setLimit(true);
      await setExpired(true);
    } else {
      await setExpired(false);
      await setLimit(false);
      await countdown();

      let auth_type = route === "join" ? 1 : 2;

      await dispatch(
        fetchSendCode({
          email:
            route === "join"
              ? (form.email as string)
              : (resetPasswordForm.email as string),
          auth_type,
        })
      );

      await message.success({
        content: "이메일을 다시 보냈습니다.",
        duration: 3,
        className: "custom-message",
        style: {
          marginTop: "90vh",
        },
      });
    }
  }, [countdown, dispatch, form.email, resetPasswordForm.email, form, expired]);

  return (
    <div className="certification-form">
      <form className={`certification-inputs`}>
        <input
          type="tel"
          inputMode="decimal"
          maxLength={6}
          pattern="d*"
          name="1"
          autoComplete="off"
          onChange={onInputChange}
          onFocus={onFocus}
          value={form.number1 || resetPasswordForm.number1 || ""}
        />
        <input
          type="tel"
          inputMode="decimal"
          maxLength={6}
          pattern="d*"
          name="2"
          autoComplete="off"
          onChange={onInputChange}
          ref={pinCodeNumFocus2}
          onFocus={onFocus}
          value={form.number2 || resetPasswordForm.number2 || ""}
        />
        <input
          type="tel"
          inputMode="decimal"
          maxLength={6}
          pattern="d*"
          name="3"
          autoComplete="off"
          onChange={onInputChange}
          ref={pinCodeNumFocus3}
          onFocus={onFocus}
          value={form.number3 || resetPasswordForm.number3 || ""}
        />
        <input
          type="tel"
          inputMode="decimal"
          maxLength={6}
          pattern="d*"
          name="4"
          autoComplete="off"
          onChange={onInputChange}
          ref={pinCodeNumFocus4}
          onFocus={onFocus}
          value={form.number4 || resetPasswordForm.number4 || ""}
        />
        <input
          type="tel"
          inputMode="decimal"
          maxLength={6}
          pattern="d*"
          name="5"
          autoComplete="off"
          onChange={onInputChange}
          ref={pinCodeNumFocus5}
          onFocus={onFocus}
          value={form.number5 || resetPasswordForm.number5 || ""}
        />
        <input
          type="tel"
          inputMode="decimal"
          maxLength={1}
          pattern="d*"
          name="6"
          autoComplete="off"
          onChange={onInputChange}
          ref={pinCodeNumFocus6}
          onFocus={onFocus}
          value={form.number6 || resetPasswordForm.number6 || ""}
        />
      </form>

      <div className="certification-messages">
        {limit && expired && (
          <div className="expired-message">
            <span>
              요청 제한을 초과하였습니다. <br />
              잠시 후 다시 시도해주세요.
            </span>
          </div>
        )}
        {!limit && expired && (
          <div className="expired-message">
            <span>
              제한 시간이 지났습니다. <br />
              새로운 이메일을 보내 인증번호를 다시 입력할 수 있습니다.
            </span>
          </div>
        )}
        {!expired && !error && (
          <div className="remain-time-message">
            <span>보낸 이메일 - {form.email || resetPasswordForm.email}</span>
            <span>
              <strong>
                {Math.floor(min)}분 {sec}초
              </strong>{" "}
              안에 입력해 주세요.
            </span>
          </div>
        )}
        {!expired && error && (
          <div className="warning-message">
            <span>
              인증번호가 올바르지 않습니다. 다시 확인해주세요. <br />{" "}
              <strong>
                {Math.floor(min)}분{sec}초
              </strong>{" "}
              안에 입력해주세요.
            </span>
          </div>
        )}

        <div className="resend-message">
          <span>이메일이 오지 않는다면 스팸함을 확인해보세요.</span>
          <span>
            또는{" "}
            <strong onClick={onResendClick}>
              이메일을 다시 보낼 수 있습니다.
            </strong>
          </span>
        </div>
      </div>
      <CertificationModal />
    </div>
  );
}

export default CertificationForm;
