import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { Button, Divider, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store";
import {
  fetchUpdateAccountUserName,
  settingAccountUpdateSelector,
} from "../../../store/setting/account/update";
import { ChangePasswordPopup } from "./ChangePasswordPopup";
import { ResignAccountPopup } from "./ResignAccountPopup";

function AccountManage() {
  const localStorage = window.localStorage;

  const currentName = useMemo(() => {
    return localStorage.getItem("username") as string;
  }, []);
  const currentEmail = useMemo(() => {
    return localStorage.getItem("email") as string;
  }, []);

  const [name, setName] = useState(currentName);
  const [email, setEmail] = useState(currentEmail);
  const [emailValid, setEmailValid] = useState(false);
  const [editName, setEditName] = useState(false);
  const [showChangePasswordPopup, setShowChangePasswordPopup] = useState(false);
  const [showResignAccountPopup, setShowResignAccountPopup] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const { loading: updateLoading, success: updateResult } = useSelector(
    settingAccountUpdateSelector
  );

  useEffect(() => {
    setEditName(updateResult);
    if (updateResult) {
      localStorage.setItem("username", name);

      setTimeout(function () {
        setEditName(false);
      }, 1500);
    }
  }, [updateLoading, updateResult]);

  const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const onSubmit = () => {
    dispatch(fetchUpdateAccountUserName(name));
  };

  const onClickShowChangePasswordPopup = () => {
    setShowChangePasswordPopup(true);
  };

  const onClickChangePasswordPopupOk = () => {
    setShowChangePasswordPopup(false);
  };

  const onClickChangePasswordPopupCancel = () => {
    setShowChangePasswordPopup(false);
  };

  const onClickShowResignAccountPopup = () => {
    setShowResignAccountPopup(true);
  };

  const onClickResignAccountPopupOk = () => {
    setShowResignAccountPopup(false);
  };

  const onClickResignAccountPopupCancel = () => {
    setShowResignAccountPopup(false);
  };

  return (
    <div className="setting-container">
      <div className="setting-title">
        <h1>계정 관리</h1>
        <p>사용자 정보</p>
      </div>
      <div className="account-manage-form">
        <Form onFinish={onSubmit}>
          <Form.Item className={`account-input`}>
            <span>이름</span>
            <Input
              onChange={onNameChange}
              placeholder={currentName}
              autoComplete="off"
              name="name"
              value={name}
            />
          </Form.Item>
          <Form.Item className={`account-input ${emailValid ? "invalid" : ""}`}>
            <span>이메일 주소</span>
            <Input
              onChange={onEmailChange}
              placeholder={currentEmail}
              name="email"
              autoComplete="off"
              value={email}
              disabled={true}
            />
          </Form.Item>
          <div className="email-message">
            {emailValid && "올바르지 않은 이메일 형식입니다."}
          </div>
          <Form.Item className="account-manage-button">
            <Button
              htmlType="submit"
              disabled={name === currentName}
              type="primary"
            >
              저장
            </Button>
            {editName && (
              <div className="save-message">{"저장되었습니다."}</div>
            )}
          </Form.Item>
        </Form>
      </div>
      <div className="setting-content">
        <p>보안</p>
        <Divider />
        <div className="setting-item">
          <img
            src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/security-icon.png`}
            alt="security_icon"
          />
          <div className="setting-info">
            <h1>본인 인증</h1>
            <p>
              본인 인증을 완료하고 Hejhome Square의 모든 기능을 이용해보세요.
            </p>
          </div>
          <Button type="primary">본인인증</Button>
        </div>
        <Divider />
        <div className="setting-item">
          <img
            src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/password-change-icon.png`}
            alt="password_change_icon"
          />
          <div className="setting-info">
            <h1>비밀번호 변경</h1>
            <p>
              계정 비밀번호를 변경합니다. 3개월 주기로 변경하는 것을 권장합니다.
            </p>
          </div>
          <Button type="primary" onClick={onClickShowChangePasswordPopup}>
            비밀번호 변경
          </Button>
        </div>
        <Divider />
      </div>
      <div className="setting-content">
        <p>기타</p>
        <Divider />
        <div className="setting-item">
          <img
            src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/logout-icon.png`}
            alt="security_icon"
          />
          <div className="setting-info">
            <h1>회원 탈퇴</h1>
            <p>Hejhome Square에서 탈퇴합니다.</p>
          </div>
          <Button type="primary" danger onClick={onClickShowResignAccountPopup}>
            회원 탈퇴
          </Button>
        </div>
      </div>

      {showChangePasswordPopup && (
        <ChangePasswordPopup
          visible={showChangePasswordPopup}
          onClickOk={onClickChangePasswordPopupOk}
          onClickCancel={onClickChangePasswordPopupCancel}
        />
      )}

      {showResignAccountPopup && (
        <ResignAccountPopup
          visible={showResignAccountPopup}
          onClickOk={onClickResignAccountPopupOk}
          onClickCancel={onClickResignAccountPopupCancel}
        />
      )}
    </div>
  );
}
export default AccountManage;
