import React, { useCallback, useEffect, useMemo, useState } from "react";
import "moment/locale/ko";
import Battery from "../../assets/img/dashboard/battery";
import { useDispatch, useSelector } from "react-redux";
import { panelDetailSelector } from "../../store/panel/detail";
import { DeviceDetailType } from "../../api/devices/device";
import { Switch, Select, Button } from "antd";
import { AppDispatch } from "../../store";
import { fetchDeviceControl } from "../../store/panel/control";
import moment from "moment";
import { datetimeFormat } from "../../common/utils";

const { Option } = Select;
const AlarmPanel = ({ action, token }: { action: any; token?: string }) => {
  const [noDetected, setNoDetected] = useState(false);
  const { detail } = useSelector(panelDetailSelector);
  const { id, status, deviceName, spaceName, time } =
    detail as DeviceDetailType;

  const eventTime = useMemo(() => {
    return moment(time).format(datetimeFormat);
  }, [time]);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    status && status.alarmSwitch && !noDetected && setNoDetected(true);
  }, [status?.alarmSwitch]);

  const changeAlarmSound = useCallback(
    (value: string) => {
      if (id)
        dispatch(
          fetchDeviceControl({
            id,
            token,
            body: { alarmVolume: value },
          })
        );
    },
    [dispatch, status, id]
  );

  const onChangePower = useCallback(() => {
    if (id && status)
      dispatch(
        fetchDeviceControl({
          id,
          token,
          body: { alarmSwitch: !status.alarmSwitch },
        })
      );
  }, [dispatch, status, id]);

  return (
    <section className="device-setting-body alarm">
      <div className="device-setting-top">
        <h1>{deviceName}</h1>
        <div className="device-setting-header">
          <div className="remain-battery">
            <Battery batteryLevel={status?.battery} />
            <p>{spaceName ? spaceName : "미지정"}</p>
          </div>
        </div>
      </div>
      <div
        className={`device-display alarm status ${
          status?.alarmSwitch ? "detected" : ""
        }`}
      >
        <h2>경보 감지 상태</h2>
        <div className="display">
          <h1>{status?.alarmSwitch ? "감지" : "정상"}</h1>
          <p className="detected-time-stamp">{time ? eventTime : "없음"}</p>
        </div>
      </div>
      <div className="alarm-setting">
        <h2>설정</h2>
        <div className="alarm-setting-container">
          <div className="alarm-setting-item">
            <p>푸시 알림</p>
            <Switch size="default" />
          </div>
          <div className="alarm-setting-item">
            <p>배터리 방전 알림</p>
            <Switch size="default" />
          </div>
          <div className="alarm-setting-item">
            <p>경보 전원</p>
            <Switch
              size="default"
              checked={status?.alarmSwitch}
              onChange={onChangePower}
            />
          </div>
          <div className="alarm-setting-item">
            <p>경보 음량</p>
            <Select value={status?.alarmVolume} onChange={changeAlarmSound}>
              <Option value="mute" key="mute">
                무음
              </Option>
              <Option value="low" key="low">
                낮음
              </Option>
              <Option value="middle" key="middle">
                중간
              </Option>
              <Option value="high" key="high">
                높음
              </Option>
            </Select>
          </div>
        </div>
      </div>
      <Button className="alarm-setting-button">
        <img
          src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/check_icon.png`}
          alt="check_icon"
        />
        설정 저장하기
      </Button>
    </section>
  );
};

export default AlarmPanel;
