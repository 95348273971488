import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sleep } from "../../../../common/utils";
import { RootState } from "../../../index";
import {
  DeviceDetailType,
  getDeviceListByUser,
} from "../../../../api/devices/device";

type SpaceDeviceState = {
  loading: boolean;
  error?: string | Error;
  deviceList: DeviceDetailType[];
};

const initialState: SpaceDeviceState = {
  loading: false,
  deviceList: [],
};

export const fetchGetDeviceListInWidget = createAsyncThunk(
  "dashboard/widget/deviceList",
  async () => {
    await sleep(100);
    const { result } = await getDeviceListByUser();
    return result;
  }
);

const slice = createSlice({
  name: "dashboard/widget/deviceList",
  initialState,
  reducers: {
    resetWidgetDeviceList: (state) => ({
      ...state,
      deviceList: initialState.deviceList,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetDeviceListInWidget.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchGetDeviceListInWidget.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = undefined;
        state.deviceList = payload;
      }
    );
    builder.addCase(fetchGetDeviceListInWidget.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message as string;
      state.deviceList = [];
    });
  },
});

export const widgetDeviceListSelector = (state: RootState) =>
  state.dashboard.widget.deviceList;
export const { resetWidgetDeviceList } = slice.actions;

export default slice;
