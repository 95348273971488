import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sleep } from "../../../common/utils";
import { RootState } from "../../index";
import { getSpaceList } from "../../../api/space/space";

type SpaceList = {
  loading: boolean;
  error?: string | Error;
  spaceList: {
    id: number;
    name: string;
    address: string;
    lan: string;
    lat: string;
  }[];
};

const initialState: SpaceList = {
  loading: false,
  error: undefined,
  spaceList: [],
};

export const fetchGetSpaceList = createAsyncThunk("space/main", async () => {
  await sleep(100);
  const { result } = await getSpaceList();
  return result;
});

const slice = createSlice({
  name: "space/list",
  initialState,
  reducers: {
    resetSpaceList: (state) => ({
      ...state,
      spaceList: initialState.spaceList,
      loading: initialState.loading,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetSpaceList.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(fetchGetSpaceList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = undefined;
      state.spaceList = payload;
    });
    builder.addCase(fetchGetSpaceList.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    });
  },
});

export const spaceListSelector = (state: RootState) => state.space.list;
export const { resetSpaceList } = slice.actions;

export default slice;
