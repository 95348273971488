import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { panelDetailSelector } from "../../store/panel/detail";
import { DeviceDetailType } from "../../api/devices/device";
import { Switch } from "antd";
import { AppDispatch } from "../../store";
import { fetchDeviceControl } from "../../store/panel/control";
import moment from "moment";
import { datetimeFormat } from "../../common/utils";

const RelayPanel = ({
  action,
  token,
}: {
  action: any;
  token?: string;
  app?: string;
}) => {
  const { detail } = useSelector(panelDetailSelector);
  const { id, status, deviceName, spaceName, time } =
    detail as DeviceDetailType;
  const dispatch = useDispatch<AppDispatch>();

  const eventTime = useMemo(() => {
    return moment(time).format(datetimeFormat);
  }, [time]);

  const handleRelayPower = useCallback(() => {
    id &&
      status &&
      dispatch(
        fetchDeviceControl({
          id,
          token,
          body: { power1: !status.power1 },
        })
      );
  }, [dispatch, status, id]);

  return (
    <section key={id + "relay"} className="device-setting-body relay-wrap">
      <div className="device-setting-top">
        <h1>{deviceName}</h1>
        <div className="device-setting-header">
          <div className="remain-battery">
            <p>{spaceName ? spaceName : "미지정"}</p>
          </div>
          <Switch
            onChange={handleRelayPower}
            checked={status && status.power1}
          />
        </div>
      </div>
      <div className="device-display relay">
        <h2>현재 상태</h2>
        <h1 className={`${status && status.power1 && "on"}`}>
          {status && status.power1 ? "On" : "Off"}
        </h1>
        {time && <p>{eventTime}</p>}
      </div>
    </section>
  );
};

export default RelayPanel;
