import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useQuery from "../../../utils/useQuery";
import {
  emailVerifySelector,
  fetchEmailVerify,
} from "../../../store/verify-email";
import { fetchSendCode, sendCodeSelector } from "../../../store/send-code";
import { resetPasswordSelector } from "../../../store/reset-password";
import { AppDispatch } from "../../../store";
import EmailForm from "../../common/email-form/EmailForm";
import { Button } from "antd";
import CertificationModal from "../../common/certification-modal/CertificationModal";
import { updateVisible } from "../../../store/check-code";

function ResetPasswordStep1() {
  const { form } = useSelector(resetPasswordSelector);
  const { valid, loading: verifyLoading } = useSelector(emailVerifySelector);
  const { loading, success, code } = useSelector(sendCodeSelector);

  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();
  const query = useQuery();
  const step = Number(query.get("step"));

  useEffect(() => {
    if (valid === false && success && code) {
      navigate(`/login/reset-password?step=${step + 1}`);
    } else if (success === false && code === false) {
      dispatch(updateVisible(true));
    }
  }, [valid, success, code, dispatch, loading]);

  useEffect(() => {
    if (valid === false)
      dispatch(fetchSendCode({ email: form.email as string, auth_type: 2 }));
  }, [valid, verifyLoading]);

  const onNextClick = useCallback(async () => {
    await dispatch(fetchEmailVerify(form.email as string));
  }, [form.email, dispatch, success, code]);

  return (
    <div>
      <EmailForm />
      <div className="reset-password-footer">
        <Button
          type="primary"
          disabled={!form.email || form.email === "" || loading}
          onClick={onNextClick}
        >
          {loading ? "loading..." : "다음"}
        </Button>
      </div>
      <CertificationModal />
    </div>
  );
}

export default ResetPasswordStep1;
