import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sleep } from "../../../../common/utils";
import { RootState } from "../../../index";
import {
  getWidgetListByDeviceType,
  WidgetType,
} from "../../../../api/widget/widget";

type WidgetListState = {
  loading: boolean;
  error?: string | Error;
  widget:
    | {
        deviceType: string;
        essentials: string[];
        widgets: WidgetType[];
      }
    | undefined;
};

const initialState: WidgetListState = {
  loading: false,
  widget: undefined,
};

export const fetchGetWidgetListByDeviceType = createAsyncThunk(
  "dashboard/widget/list",
  async (deviceType: string) => {
    await sleep(100);
    const { result } = await getWidgetListByDeviceType(deviceType);
    return result;
  }
);

const slice = createSlice({
  name: "dashboard/widget/list",
  initialState,
  reducers: {
    resetWidgetList: (state) => ({
      ...state,
      widget: initialState.widget,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetWidgetListByDeviceType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchGetWidgetListByDeviceType.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = undefined;
        state.widget = payload;
      }
    );
    builder.addCase(
      fetchGetWidgetListByDeviceType.rejected,
      (state, { error }) => {
        state.loading = false;
        state.error = error.message as string;
        state.widget = undefined;
      }
    );
  },
});

export const widgetListSelector = (state: RootState) =>
  state.dashboard.widget.list;
export const { resetWidgetList } = slice.actions;

export default slice;
