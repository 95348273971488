import React, { useEffect, useMemo } from "react";
import useQuery from "../../utils/useQuery";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";
import {
  fetchGetDeviceDetail,
  panelDetailSelector,
  updatePanelDeviceStatus,
} from "../../store/panel/detail";
import "react-input-range/lib/css/index.css";
import "../../assets/scss/device-setting.scss";
import { io } from "socket.io-client";
import { getDevicePanel } from "../../utils/getDevicePanel";
import { modifyUserAgent } from "../../common/utils";
import { Skeleton } from "antd";

const broker_url = "https://develop-device-event-broker.goqual.io";
const socket = io(broker_url);
function Panel() {
  const query = useQuery();
  const dispatch = useDispatch<AppDispatch>();
  const { detail } = useSelector(panelDetailSelector);
  const id = Number(query.get("id"));
  const {
    app = "",
    authorization: token = "Bearer 935c173f621cbb9abe1099eba08180b1d27b836c",
  } = useMemo(() => {
    const userAgent = window.navigator.userAgent;
    return modifyUserAgent(userAgent);
  }, [window, id, detail]);

  useEffect(() => {
    id && token && dispatch(fetchGetDeviceDetail({ id, token }));
  }, [id]);

  useEffect(() => {
    const email = "test001@goqual.com"; // dummy
    if (email) {
      socket.emit("join", email);
      socket.on("DeviceStatus", (sockets) => {
        let event = sockets.indexOf("{") !== -1 && JSON.parse(sockets);
        if (
          !!event &&
          event.deviceId &&
          event.status &&
          detail &&
          event.deviceId === detail.deviceId
        ) {
          dispatch(
            updatePanelDeviceStatus({ status: event.status, time: event.time })
          );
        }
      });
    }
  }, [app, token, id, detail]);

  return (
    <div className="device-setting">
      {token && detail ? (
        <div className="device-scroll">
          {getDevicePanel(detail.deviceType as string, token, app)}
        </div>
      ) : (
        <div className="loading">
          <Skeleton />
        </div>
      )}
    </div>
  );
}
export default Panel;
