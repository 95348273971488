import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { joinSelector, openModal, updateJoinForm } from "../../../store/join";
import { sendCodeSelector, fetchSendCode } from "../../../store/send-code";
import { AppDispatch } from "../../../store";
import useQuery from "../../../utils/useQuery";
import JoinTerms from "./JoinTerms";
import { Button } from "antd";
import "./JoinStep2.scss";
import CertificationModal from "../../common/certification-modal/CertificationModal";
import { updateVisible } from "../../../store/check-code";

function JoinStep2() {
  const { form, modalOpen: modal } = useSelector(joinSelector);
  const { success, code, loading } = useSelector(sendCodeSelector);
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();
  const query = useQuery();
  const step = Number(query.get("step"));

  useEffect(() => {
    !form.email && navigate("/login/join?step=1");
  }, []);

  useEffect(() => {
    success && navigate(`/login/join?step=${step + 1}`);
  }, [success]);

  useEffect(() => {
    success === false && code === false && dispatch(updateVisible(true));
  }, [code, success]);

  const onNextClick = useCallback(async () => {
    if (form.terms1 && form.terms2 && form.terms3) {
      await dispatch(
        fetchSendCode({ email: form.email as string, auth_type: 1 })
      );
    }
  }, [form, dispatch]);

  const onModalOpen = useCallback(
    (terms: string) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();
      dispatch(openModal({ open: !modal.open, type: terms }));
    },
    [modal.open, dispatch]
  );

  const onAllTermsClick = useCallback(() => {
    if (!(form.terms1 && form.terms2 && form.terms3)) {
      dispatch(updateJoinForm({ terms1: true, terms2: true, terms3: true }));
    } else {
      dispatch(updateJoinForm({ terms1: false, terms2: false, terms3: false }));
    }
  }, [form.terms1, form.terms2, form.terms3, dispatch]);

  const onTermsClick = useCallback(
    (value: string) => () => {
      dispatch(
        updateJoinForm({
          [value]: !form[value as "terms1" | "terms2" | "terms3"],
        })
      );
    },
    [form.terms1, form.terms2, form.terms3, dispatch]
  );

  return (
    <div className="join-terms-container">
      <ul className="join-terms">
        <li className={`terms 1 ${form.terms1 ? "active" : ""}`}>
          <span onClick={onTermsClick("terms1")}>
            <strong>[필수]</strong> 서비스 약관
          </span>
          <button onClick={onModalOpen("terms1")}>전문 보기</button>
        </li>

        <li className={`terms 2 ${form.terms2 ? "active" : ""}`}>
          <span onClick={onTermsClick("terms2")}>
            <strong>[필수]</strong> 개인정보 관리정책
          </span>
          <button onClick={onModalOpen("terms2")}>전문 보기</button>
        </li>

        <li className={`terms 3 ${form.terms3 ? "active" : ""}`}>
          <span onClick={onTermsClick("terms3")}>
            <strong>[필수]</strong> 개인정보 3자제공 동의
          </span>
          <button onClick={onModalOpen("terms3")}>전문 보기</button>
        </li>

        <li
          className={`all-terms-agree ${
            form.terms1 && form.terms2 && form.terms3 ? "active" : ""
          }`}
        >
          <button onClick={onAllTermsClick}>모든 약관 동의</button>
        </li>
      </ul>

      {modal.open && <JoinTerms />}

      <div className="join-footer">
        <Button
          type="primary"
          disabled={!(form.terms1 && form.terms2 && form.terms3) || loading}
          onClick={onNextClick}
        >
          {loading ? "loading..." : "다음"}
        </Button>
      </div>
      <CertificationModal />
    </div>
  );
}

export default JoinStep2;
