import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sleep } from "../../../common/utils";
import { updateDashboardById } from "../../../api/dashboard/dashboard";
import { RootState } from "../../index";

type DashboardRenameState = {
  loading: boolean;
  error?: string | Error;
  id?: number;
  dashboardName?: string;
  success: boolean;
  visible: boolean;
};

const initialState: DashboardRenameState = {
  loading: false,
  success: false,
  visible: false,
  id: undefined,
  dashboardName: undefined,
};

export const fetchUpdateDashboard = createAsyncThunk(
  "dashboard/rename",
  async ({ id, name }: { id: number; name: string }) => {
    await sleep(100);
    const { result } = await updateDashboardById(id, name);
    return result;
  }
);

const slice = createSlice({
  name: "dashboard/rename",
  initialState,
  reducers: {
    resetDashboardRename: (state) => ({
      ...state,
      success: initialState.success,
      id: initialState.id,
      dashboardName: initialState.dashboardName,
      visible: initialState.visible,
    }),
    updateDashboardRenameModalVisible: (state, { payload }) => ({
      ...state,
      visible: payload,
    }),
    updateDashboardRenameModalId: (state, { payload }) => ({
      ...state,
      id: payload,
    }),
    updateDashboardRenameModalName: (state, { payload }) => ({
      ...state,
      dashboardName: payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUpdateDashboard.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUpdateDashboard.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = undefined;
      state.success = payload;
    });
    builder.addCase(fetchUpdateDashboard.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message as string;
      state.success = false;
    });
  },
});

export const dashboardRenameSelector = (state: RootState) =>
  state.dashboard.rename;
export const {
  resetDashboardRename,
  updateDashboardRenameModalVisible,
  updateDashboardRenameModalId,
  updateDashboardRenameModalName,
} = slice.actions;

export default slice;
