import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Typography } from "antd";
const { Paragraph } = Typography;
interface popupProps {
  visible: boolean;
  onClickOk: any;
  onClickCancel: any;
}

export function ResignAccountPopup(props: popupProps) {
  const [password, setPassword] = useState("");
  useEffect(() => {}, []);

  return (
    <Modal
      className="resign-modal"
      visible={props.visible}
      closable={false}
      footer={[
        <Button
          key={"cancelBtn"}
          className="cancel"
          onClick={props.onClickCancel}
        >
          취소
        </Button>,
        <Button key={"okBtn"} danger onClick={props.onClickOk}>
          탈퇴
        </Button>,
      ]}
    >
      <h1>회원탈퇴</h1>
      <Typography>
        <Paragraph
          style={{
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: "700",
            color: "gray",
          }}
        >
          정말로 Hejhome Square 회원을 탈퇴 하시겠습니까? 탈퇴할 경우, 아래와
          같은 사항을 확인해야 합니다.
        </Paragraph>
        <Paragraph
          style={{
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: "700",
            color: "gray",
          }}
        >
          <ul>
            <li>
              계정에 연동된 모든 디바이스 삭제 됩니다. 연결이 해제된 디바이스는
              다시 연결해야 사용할 수 있습니다. 또한 모든 연동 서비스가 연동
              해제 됩니다.
            </li>
            <li>
              모든장소 및 대시보드가 삭제 됩니다. 공유한 대시보드는 다른
              사용자가 더 이상 이용할 수 없습니다.
            </li>
          </ul>
        </Paragraph>
        <Paragraph
          style={{
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: "700",
            color: "gray",
          }}
        >
          회원탈퇴를 진행하려면 계정 비밀번호를 입력해주세요.
        </Paragraph>
      </Typography>
      <div className="input-box">
        <Form.Item label="비밀번호" className="resign-password-input-item">
          <Input.Password
            bordered={false}
            className="resign-password-input"
            defaultValue={password}
            placeholder={"탈퇴할 계정의 비밀번호를 입력해주세요"}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </Form.Item>
      </div>
    </Modal>
  );
}
