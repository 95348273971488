import React, { ChangeEvent, useEffect, useState } from "react";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useTitle } from "ahooks";
import { emailRegex } from "../../utils/regax";
import { Button, Checkbox, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchLogin, loginSelector, resetLogin } from "../../store/login";
import { AppDispatch } from "../../store";
import { useNavigate } from "react-router-dom";

declare global {
  interface Window {
    // ⚠️ notice that "Window" is capitalized here
    helloWorld: any;
  }
}

function LoginForm() {
  useTitle("Login | 헤이홈 스퀘어");

  const { success, loading } = useSelector(loginSelector);
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [emailValid, setEmailValid] = useState(false);

  const [password, setPassword] = useState("");
  useEffect(() => {
    success !== undefined && !success && setInvalid(true);
    success && navigate("/dashboard");
  }, [success]);

  const onPasswordVisible = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    dispatch(resetLogin());
    setInvalid(false);
    setEmail(e.target.value);
  };

  const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(resetLogin());
    setInvalid(false);
    setPassword(e.target.value);
  };

  const onAutoLoginClick = (e: CheckboxChangeEvent) => {
    console.log(e.target.checked);
  };

  const onLoginFormSubmit = () => {
    // window.helloWorld();
    const valid = emailRegex(email);
    setEmailValid(!valid);
    if (valid && email && password) {
      dispatch(fetchLogin({ id: email, password }));
    }
  };

  return (
    <div>
      <div className="hejhome-login-form">
        <h1>로그인</h1>
        <Form onFinish={onLoginFormSubmit}>
          <Form.Item
            className={`hejhome-login-input ${invalid ? "invalid" : ""}`}
          >
            <span>이메일</span>
            <Input
              onChange={onEmailChange}
              placeholder="hej-is-hey@email.com"
              autoComplete="off"
              name="email"
              value={email}
            />
          </Form.Item>
          <div className={`email-message ${emailValid && "invalid"}`}>
            올바르지 않은 이메일 형식입니다.
          </div>
          <Form.Item
            className={`hejhome-login-input ${invalid ? "invalid" : ""}`}
          >
            <span>비밀번호</span>
            <Input
              onChange={onPasswordChange}
              type={isPasswordVisible ? "text" : "password"}
              placeholder="8-12자 사이의 숫자, 영문 소문자, 특수문자"
              name="password"
              autoComplete="off"
              value={password}
              addonAfter={
                <Button
                  className={isPasswordVisible ? "visible" : "invisible"}
                  onClick={onPasswordVisible}
                >
                  &nbsp;
                </Button>
              }
            />
          </Form.Item>
          <div className={`password-message ${invalid && "invalid"}`}>
            비밀번호 또는 이메일이 올바르지 않습니다.
          </div>
          <Form.Item className="hejhome-login-buttons">
            <span className="hejhome-button">
              <Checkbox onChange={onAutoLoginClick}>자동 로그인</Checkbox>
              <a href="/login/reset-password?step=1">비밀번호 재설정</a>
            </span>
            <Button
              htmlType="submit"
              disabled={email === "" || password === "" || loading}
              type="primary"
            >
              {loading ? "loading..." : "로그인"}
            </Button>
          </Form.Item>
        </Form>
      </div>

      <div className="hejhome-login-message">
        <span> 본 서비스는 헤이홈 앱 서비스를 기반으로 제공되며,</span>
        <span>
          서비스이용약관 및 개인정보보호정책은 기존 약관이 적용됩니다.
        </span>
      </div>

      <div className="hejhome-login-join">
        <span>Hejhome이 처음이신가요?</span>
        <a className="join-button" href="/login/join?step=1">
          회원가입
        </a>
      </div>
    </div>
  );
}

export default LoginForm;
