import React from "react";
import { Button, Divider } from "antd";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store";
import { updateServiceInfoTerm } from "../../../store/setting/service-info";
import ServiceInfoModal from "./ServiceInfoModal";
function ServiceInfo() {
  const dispatch = useDispatch<AppDispatch>();
  const onClickTerm = (menu: string) => () => {
    dispatch(
      updateServiceInfoTerm({
        visible: true,
        term: menu,
      })
    );
  };
  return (
    <div className="setting-container">
      <div className="setting-title">
        <h1>서비스 정보</h1>
      </div>
      <div className="setting-content">
        <p>서비스 이용약관</p>
        <Divider />
        <div className="setting-item">
          <img
            src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/service_list_icon.png`}
            alt="plan_icon"
          />
          <div className="setting-info">
            <h1>서비스 이용 약관</h1>
          </div>
          <Button type="default" onClick={onClickTerm("service")}>
            전문 보기
          </Button>
        </div>
        <Divider />
        <div className="setting-item">
          <img
            src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/service_list_icon.png`}
            alt="plan_icon"
          />
          <div className="setting-info">
            <h1>개인정보 관리정책</h1>
          </div>
          <Button type="default" onClick={onClickTerm("management")}>
            전문 보기
          </Button>
        </div>
        <Divider />
        <div className="setting-item">
          <img
            src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/service_list_icon.png`}
            alt="plan_icon"
          />
          <div className="setting-info">
            <h1>개인정보 보호 정책</h1>
          </div>
          <Button type="default" onClick={onClickTerm("protect")}>
            전문 보기
          </Button>
        </div>
        <Divider />
      </div>
      <div className="company-info">
        <h1>서비스 제공회사 정보</h1>
        <h2>주식회사 고퀄</h2>
        <div className="company-detail">
          <p>
            전화번호 <u>1644-7098</u> / 이메일 <u>service@goqual.com</u> /
            카카오톡 <u>@hejhome</u>
          </p>
          <p>서울특별시 금천구 가산디지털1로 165, 1301호 (08503)</p>
        </div>
        <p>
          사업자등록번호 : 514-81-92269 / 대표이사 : 우상범 / 통신판매업신고번호
          : 제2017-서울금천=0737호
        </p>
      </div>
      <ServiceInfoModal />
    </div>
  );
}
export default ServiceInfo;
