import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../index";
type RecipeRemoveState = {
  visible: boolean;
};

const initialState: RecipeRemoveState = {
  visible: false,
};

const slice = createSlice({
  name: "recipe/remove",
  initialState,
  reducers: {
    resetRecipeRemoveModal: (state: RecipeRemoveState) => ({
      ...state,
      visible: initialState.visible,
    }),
    updateRecipeRemoveModal: (state: RecipeRemoveState, { payload }) => ({
      ...state,
      visible: payload,
    }),
  },
});

export const recipeRemoveSelector = (state: RootState) => state.recipe.remove;
export const { resetRecipeRemoveModal, updateRecipeRemoveModal } =
  slice.actions;
export default slice;
