import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../index";
import { sleep } from "../../../common/utils";
import { postRegisterRecipe } from "../../../api/recipe/recipe";

export type weatherCondition = {
  // location: string;
  location: { lat: number; lan: number };
  key: string;
  input: number;
  value: string;
};

export type device = {
  deviceId?: string;
  deviceType?: string;
  name?: string;
  key?: string;
  value?: any;
  input?: number;
  display?: {
    key: string;
    value: string;
  };
  placeName?: string;
};

export type timer = {
  display: string;
  value: number;
};

const ConditionTypes = {
  oneClick: "oneClick",
  weather: "weather",
  schedule: "schedule",
  device: "device",
} as const;
const ActionTypes = {
  device: "device",
  recipe: "recipe",
  alarm: "alarm",
  timer: "timer",
} as const;

type ConditionTypes = typeof ConditionTypes[keyof typeof ConditionTypes];
type ActionTypes = typeof ActionTypes[keyof typeof ActionTypes];

export type ConditionType = {
  id: number;
  type: ConditionTypes;
  condition: weatherCondition | device | boolean;
  action?: weatherCondition | device | boolean;
};

export type ActionType = {
  id: number;
  type: ActionTypes;
  action: string | device | boolean | number | timer | undefined;
};

export type RecipeRegisterState = {
  modal: boolean;
  currentActionId?: number;
  currentConditionId?: number;
  oneClick: boolean;
  timeInfo?: {
    repeat?: boolean;
    during?: boolean;
    dayList?: string[];
    startTime?: string;
    endTime?: string;
    display?: {
      startTime?: string;
      endTime?: string;
    };
  };
  conditionList: ConditionType[];
  actionList: ActionType[];
  loading: boolean;
  error?: Error | string;
  success?: boolean;
  message?: any;
};

const initialState: RecipeRegisterState = {
  modal: false,
  currentActionId: undefined,
  currentConditionId: undefined,
  oneClick: false,
  timeInfo: {
    repeat: false,
    during: false,
  },
  conditionList: [],
  actionList: [],
  loading: false,
  success: undefined,
};

export const fetchRegisterRecipe = createAsyncThunk(
  "recipe/register",
  async (body: any) => {
    await sleep(100);
    const { result, message } = await postRegisterRecipe(body);
    return { result, message };
  }
);

const slice = createSlice({
  name: "recipe/register",
  initialState,
  reducers: {
    updateOpenRecipeRegisterModal: (
      state: RecipeRegisterState,
      { payload }
    ) => ({
      ...state,
      modal: payload,
    }),
    updateCurrentActionId: (state: RecipeRegisterState, { payload }) => ({
      ...state,
      currentActionId: payload,
    }),
    updateCurrentConditionId: (state: RecipeRegisterState, { payload }) => ({
      ...state,
      currentConditionId: payload,
    }),
    updateOneClick: (state: RecipeRegisterState, { payload }) => ({
      ...state,
      oneClick: payload,
    }),
    resetRecipeRegisterModal: (state: RecipeRegisterState) => ({
      ...state,
      oneClick: initialState.oneClick,
      modal: initialState.modal,
      currentActionId: initialState.currentActionId,
      currentConditionId: initialState.currentConditionId,
      timeInfo: initialState.timeInfo,
      conditionList: initialState.conditionList,
      actionList: initialState.actionList,
      loading: initialState.loading,
      success: initialState.success,
    }),
    updateRecipeRegisterDayList: (state: RecipeRegisterState, { payload }) => ({
      ...state,
      timeInfo: {
        ...state?.timeInfo,
        dayList: payload,
      },
    }),
    updateRecipeRegisterRepeat: (state: RecipeRegisterState, { payload }) => ({
      ...state,
      timeInfo: {
        ...state.timeInfo,
        repeat: payload,
      },
    }),
    updateRecipeRegisterDuring: (state: RecipeRegisterState, { payload }) => ({
      ...state,
      timeInfo: {
        ...state.timeInfo,
        during: payload,
      },
    }),
    updateRecipeRegisterDisplayStartTime: (
      state: RecipeRegisterState,
      { payload }
    ) => ({
      ...state,
      timeInfo: {
        ...state.timeInfo,
        display: {
          ...state.timeInfo?.display,
          startTime: payload,
        },
      },
    }),
    updateRecipeRegisterDisplayEndTime: (
      state: RecipeRegisterState,
      { payload }
    ) => ({
      ...state,
      timeInfo: {
        ...state.timeInfo,
        display: {
          ...state.timeInfo?.display,
          endTime: payload,
        },
      },
    }),
    updateRecipeRegisterStartTime: (
      state: RecipeRegisterState,
      { payload }
    ) => ({
      ...state,
      timeInfo: {
        ...state.timeInfo,
        startTime: payload,
      },
    }),
    updateRecipeRegisterEndTime: (state: RecipeRegisterState, { payload }) => ({
      ...state,
      timeInfo: {
        ...state.timeInfo,
        endTime: payload,
      },
    }),
    updateRecipeConditionOneClick: (
      state: RecipeRegisterState,
      { payload: { id } }
    ) => ({
      ...state,
      conditionList: [
        ...state.conditionList,
        { id, type: "oneClick", condition: true },
      ],
    }),
    updateRecipeConditionWeather: (
      state: RecipeRegisterState,
      { payload: { id } }
    ) => ({
      ...state,
      conditionList: [
        ...state.conditionList,
        { id, type: "weather", condition: false },
      ],
    }),
    updateRecipeConditionType: (
      state: RecipeRegisterState,
      { payload: { id, type } }
    ) => ({
      ...state,
      conditionList: state.conditionList.map((conditionInfo, index) =>
        index === id
          ? {
              ...conditionInfo,
              type,
              condition: false,
            }
          : conditionInfo
      ),
    }),
    updateRecipeConditionWeatherLocation: (
      state: RecipeRegisterState,
      { payload: { id, location } }
    ) => ({
      ...state,
      conditionList: state.conditionList.map((conditionInfo, index) =>
        index === id
          ? {
              ...conditionInfo,
              condition: {
                ...(conditionInfo.condition as weatherCondition),
                location,
              },
            }
          : conditionInfo
      ),
    }),
    updateRecipeConditionWeatherKey: (
      state: RecipeRegisterState,
      { payload: { id, key } }
    ) => ({
      ...state,
      conditionList: state.conditionList.map((conditionInfo, index) =>
        index === id
          ? {
              ...conditionInfo,
              condition: {
                ...(conditionInfo.condition as weatherCondition),
                key,
              },
            }
          : conditionInfo
      ),
    }),
    updateRecipeConditionWeatherValue: (
      state: RecipeRegisterState,
      { payload: { id, value } }
    ) => ({
      ...state,
      conditionList: state.conditionList.map((conditionInfo, index) =>
        index === id
          ? {
              ...conditionInfo,
              condition: {
                ...(conditionInfo.condition as weatherCondition),
                value,
              },
            }
          : conditionInfo
      ),
    }),
    updateRecipeConditionWeatherInputValue: (
      state: RecipeRegisterState,
      { payload: { id, input } }
    ) => ({
      ...state,
      conditionList: state.conditionList.map((conditionInfo, index) =>
        index === id
          ? {
              ...conditionInfo,
              condition: {
                ...(conditionInfo.condition as weatherCondition),
                input,
              },
            }
          : conditionInfo
      ),
    }),
    updateRecipeConditionDevice: (
      state: RecipeRegisterState,
      { payload: { id, pid, deviceId, deviceType, name, placeName } }
    ) => ({
      ...state,
      conditionList: state.conditionList.map((conditionInfo, index) =>
        index === id
          ? {
              ...conditionInfo,
              condition: {
                ...(conditionInfo.condition as device),
                deviceId,
                pid,
                deviceType,
                name,
                input: undefined,
                placeName,
              },
            }
          : conditionInfo
      ),
    }),
    updateRecipeConditionDeviceKey: (
      state: RecipeRegisterState,
      { payload: { id, key } }
    ) => ({
      ...state,
      conditionList: state.conditionList.map((conditionInfo, index) =>
        index === id
          ? {
              ...conditionInfo,
              condition: {
                ...(conditionInfo.condition as device),
                key,
              },
            }
          : conditionInfo
      ),
    }),
    updateRecipeConditionDeviceValue: (
      state: RecipeRegisterState,
      { payload: { id, value } }
    ) => ({
      ...state,
      conditionList: state.conditionList.map((conditionInfo, index) =>
        index === id
          ? {
              ...conditionInfo,
              condition: {
                ...(conditionInfo.condition as device),
                value,
              },
            }
          : conditionInfo
      ),
    }),
    updateRecipeConditionDeviceDisplayKey: (
      state: RecipeRegisterState,
      { payload: { id, key } }
    ) => ({
      ...state,
      conditionList: state.conditionList.map((conditionInfo, index) =>
        index === id
          ? {
              ...conditionInfo,
              condition: {
                ...(conditionInfo.condition as device),
                display: {
                  ...((conditionInfo.condition as device).display as {
                    key: string;
                    value: string;
                  }),
                  key,
                },
                input: undefined,
              },
            }
          : conditionInfo
      ),
    }),
    updateRecipeConditionDeviceDisplayValue: (
      state: RecipeRegisterState,
      { payload: { id, value } }
    ) => ({
      ...state,
      conditionList: state.conditionList.map((conditionInfo, index) =>
        index === id
          ? {
              ...conditionInfo,
              condition: {
                ...(conditionInfo.condition as device),
                display: {
                  ...((conditionInfo.condition as device).display as {
                    key: string;
                    value: string;
                  }),
                  value,
                },
              },
            }
          : conditionInfo
      ),
    }),
    updateRecipeConditionDeviceInputValue: (
      state: RecipeRegisterState,
      { payload: { id, input } }
    ) => ({
      ...state,
      conditionList: state.conditionList.map((conditionInfo, index) =>
        index === id
          ? {
              ...conditionInfo,
              condition: {
                ...(conditionInfo.condition as device),
                input,
              },
            }
          : conditionInfo
      ),
    }),
    deleteRecipeConditionById: (
      state: RecipeRegisterState,
      { payload }: { payload: number }
    ) => ({
      ...state,
      conditionList: state.conditionList.reduce((list, condition) => {
        if (condition.id === payload) {
          return list;
        } else if (condition.id > payload) {
          condition = {
            ...condition,
            id: condition.id - 1,
          };
        }
        list.push(condition);
        return list;
      }, [] as ConditionType[]),
    }),
    addRecipeAction: (state: RecipeRegisterState, { payload: { id } }) => ({
      ...state,
      actionList: [...state.actionList, { id, type: "recipe", action: false }],
    }),
    updateRecipeActionType: (state, { payload: { id, type } }) => ({
      ...state,
      actionList: state.actionList.map((actionInfo, index) =>
        index === id
          ? {
              ...actionInfo,
              type,
              action: undefined,
            }
          : actionInfo
      ),
    }),
    updateRecipeActionDevice: (
      state: RecipeRegisterState,
      { payload: { id, pid, deviceId, deviceType, name, placeName } }
    ) => ({
      ...state,
      actionList: state.actionList.map((actionInfo, index) =>
        index === id
          ? {
              ...actionInfo,
              action: {
                ...(actionInfo.action as device),
                pid,
                deviceId,
                deviceType,
                name,
                placeName,
              },
            }
          : actionInfo
      ),
    }),
    updateRecipeActionDeviceKey: (
      state: RecipeRegisterState,
      { payload: { id, key } }
    ) => ({
      ...state,
      actionList: state.actionList.map((actionInfo, index) =>
        index === id
          ? {
              ...actionInfo,
              action: {
                ...(actionInfo.action as device),
                key,
              },
            }
          : actionInfo
      ),
    }),
    updateRecipeActionDeviceValue: (
      state: RecipeRegisterState,
      { payload: { id, value } }
    ) => ({
      ...state,
      actionList: state.actionList.map((actionInfo, index) =>
        index === id
          ? {
              ...actionInfo,
              action: {
                ...(actionInfo.action as device),
                value,
              },
            }
          : actionInfo
      ),
    }),
    updateRecipeActionDeviceDisplayKey: (
      state: RecipeRegisterState,
      { payload: { id, key } }
    ) => ({
      ...state,
      actionList: state.actionList.map((actionInfo, index) =>
        index === id
          ? {
              ...actionInfo,
              action: {
                ...(actionInfo.action as device),
                display: {
                  ...((actionInfo.action as device).display as {
                    key: string;
                    value: string;
                  }),
                  key,
                },
              },
            }
          : actionInfo
      ),
    }),
    updateRecipeActionDeviceDisplayValue: (
      state: RecipeRegisterState,
      { payload: { id, value } }
    ) => ({
      ...state,
      actionList: state.actionList.map((actionInfo, index) =>
        index === id
          ? {
              ...actionInfo,
              action: {
                ...(actionInfo.action as device),
                display: {
                  ...((actionInfo.action as device).display as {
                    key: string;
                    value: string;
                  }),
                  value,
                },
              },
            }
          : actionInfo
      ),
    }),
    updateRecipeActionDeviceInputValue: (
      state: RecipeRegisterState,
      { payload: { id, input } }
    ) => ({
      ...state,
      actionList: state.actionList.map((actionInfo, index) =>
        index === id
          ? {
              ...actionInfo,
              action: {
                ...(actionInfo.action as device),
                input,
              },
            }
          : actionInfo
      ),
    }),
    updateRecipeActionTimerValue: (
      state: RecipeRegisterState,
      { payload: { id, time } }
    ) => ({
      ...state,
      actionList: state.actionList.map((actionInfo, index) =>
        index === id
          ? {
              ...actionInfo,
              action: {
                ...(actionInfo.action as timer),
                value: time,
              },
            }
          : actionInfo
      ),
    }),
    updateRecipeActionTimerDisplay: (
      state: RecipeRegisterState,
      { payload: { id, time } }
    ) => ({
      ...state,
      actionList: state.actionList.map((actionInfo, index) =>
        index === id
          ? {
              ...actionInfo,
              action: {
                ...(actionInfo.action as timer),
                display: time,
              },
            }
          : actionInfo
      ),
    }),
    updateRecipeActionRecipe: (
      state: RecipeRegisterState,
      { payload: { id, recipeId } }
    ) => ({
      ...state,
      actionList: state.actionList.map((actionInfo, index) =>
        index === id
          ? {
              ...actionInfo,
              action: recipeId,
            }
          : actionInfo
      ),
    }),
    deleteRecipeActionById: (state: RecipeRegisterState, { payload }) => ({
      ...state,
      actionList: state.actionList.reduce((list, action) => {
        if (action.id === payload) {
          return list;
        } else if (action.id > payload) {
          action = {
            ...action,
            id: action.id - 1,
          };
        }
        list.push(action);
        return list;
      }, [] as ActionType[]),
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRegisterRecipe.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(
      fetchRegisterRecipe.fulfilled,
      (state, { payload: { message, result } }) => {
        state.loading = false;
        state.success = result;
        state.message = message;
        state.error = undefined;
      }
    );
    builder.addCase(fetchRegisterRecipe.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    });
  },
});

export const recipeRegisterSelector = (state: RootState) =>
  state.recipe.register;

export const {
  updateOpenRecipeRegisterModal,
  updateCurrentActionId,
  updateOneClick,
  updateCurrentConditionId,
  resetRecipeRegisterModal,
  updateRecipeRegisterDisplayStartTime,
  updateRecipeRegisterDisplayEndTime,
  updateRecipeRegisterStartTime,
  updateRecipeRegisterEndTime,
  updateRecipeRegisterDayList,
  updateRecipeRegisterDuring,
  updateRecipeRegisterRepeat,
  updateRecipeConditionOneClick,
  updateRecipeConditionType,
  updateRecipeConditionWeatherLocation,
  updateRecipeConditionWeatherKey,
  updateRecipeConditionWeatherValue,
  updateRecipeConditionWeatherInputValue,
  updateRecipeConditionDevice,
  updateRecipeConditionDeviceKey,
  updateRecipeConditionDeviceValue,
  updateRecipeConditionDeviceInputValue,
  updateRecipeConditionWeather,
  deleteRecipeConditionById,
  addRecipeAction,
  updateRecipeActionType,
  updateRecipeActionDevice,
  updateRecipeActionDeviceKey,
  updateRecipeActionDeviceValue,
  updateRecipeActionDeviceInputValue,
  updateRecipeActionTimerValue,
  updateRecipeActionTimerDisplay,
  updateRecipeActionRecipe,
  deleteRecipeActionById,
  updateRecipeActionDeviceDisplayKey,
  updateRecipeActionDeviceDisplayValue,
  updateRecipeConditionDeviceDisplayKey,
  updateRecipeConditionDeviceDisplayValue,
} = slice.actions;

export default slice;
