import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../index";
import { sleep } from "../../../../common/utils";
import { postDisconnectAirPos } from "../../../../api/airPos/airPos";

type AirposDeleteState = {
  visible: boolean;
  success: boolean;
  loading: boolean;
  error?: string | Error;
};

const initialState: AirposDeleteState = {
  visible: false,
  success: false,
  loading: false,
  error: undefined,
};

export const fetchDeleteAirpos = createAsyncThunk(
  "setting/airpos/delete",
  async (app_schema: string) => {
    await sleep(100);
    const { result } = await postDisconnectAirPos(app_schema);
    return result;
  }
);

const slice = createSlice({
  name: "setting/airpos/delete",
  initialState,
  reducers: {
    resetAirposDeleteModalVisible: (state: AirposDeleteState) => ({
      ...state,
      visible: initialState.visible,
      success: initialState.success,
      loading: initialState.loading,
      error: initialState.error,
    }),
    updateAirposDeleteModal: (state: AirposDeleteState, { payload }) => ({
      ...state,
      visible: payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDeleteAirpos.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(fetchDeleteAirpos.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.success = payload;
      state.error = undefined;
    });
    builder.addCase(fetchDeleteAirpos.rejected, (state, { error }) => {
      state.loading = false;
      state.success = false;
      state.error = error.message;
    });
  },
});

export const settingAirposDeleteSelector = (state: RootState) =>
  state.setting.airpos.delete;

export const { resetAirposDeleteModalVisible, updateAirposDeleteModal } =
  slice.actions;

export default slice;
