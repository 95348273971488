import React, { useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTitle } from "ahooks";
import { useDispatch, useSelector } from "react-redux";
import useQuery from "../../utils/useQuery";
import useIsMobile from "../../utils/useIsMobile";
import ResetPasswordStep1 from "./step1/ResetPasswordStep1";
import ResetPasswordStep2 from "./step2/ResetPasswordStep2";
import ResetPasswordStep3 from "./step3/ResetPasswordStep3";
import {
  resetPasswordSelector,
  updateResetPasswordForm,
} from "../../store/reset-password";
import { resetForm } from "../../store/join";
import { resetPasswordMessages } from "./ResetPasswordMessages";
import { Button } from "antd";
import "./ResetPasswordProcess.scss";
import ResetPasswordStep4 from "./step4/ResetPasswordStep4";

function ResetPasswordProcess() {
  useTitle("비밀번호 찾기 | 헤이홈 스퀘어");

  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const query = useQuery();
  const step = Number(query.get("step"));

  const dispatch = useDispatch();
  const { form } = useSelector(resetPasswordSelector);

  useEffect(() => {
    if (
      form.number1 &&
      form.number2 &&
      form.number3 &&
      form.number4 &&
      form.number5 &&
      form.number6
    ) {
      dispatch(
        updateResetPasswordForm({
          certificationNumber: Number(
            form.number1 +
              form.number2 +
              form.number3 +
              form.number4 +
              form.number5 +
              form.number6
          ),
        })
      );
    }
  }, [
    form.number1,
    form.number2,
    form.number3,
    form.number4,
    form.number5,
    form.number6,
    dispatch,
  ]);

  const resolvedPasswordMessage = useMemo(() => {
    return resetPasswordMessages.filter((message) => message.step === step)[0];
  }, [step]);

  const onGoBackClick = useCallback(() => {
    dispatch(resetForm());
    navigate("/login");
  }, []);

  return (
    <div className="hejhome-reset-password-container">
      {isMobile && (
        <div className="hejhome-reset-password-go-back-btn">
          <Button onClick={onGoBackClick}>
            <svg
              width={24}
              height={24}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m8.293 19.707-7-7a1 1 0 0 1 0-1.414l7-7 1.414 1.414L4.414 11H23v2H4.414l5.293 5.293-1.414 1.414Z"
                fill="#10100E"
              />
            </svg>
          </Button>
        </div>
      )}
      <div className="hejhome-reset-password-process">
        <h3>비밀번호 재설정</h3>
        <h1>{resolvedPasswordMessage.title}</h1>
        <p>{resolvedPasswordMessage.subtitle}</p>
        {step === 1 && <ResetPasswordStep1 />}
        {step === 2 && <ResetPasswordStep2 />}
        {step === 3 && <ResetPasswordStep3 />}
        {step === 4 && <ResetPasswordStep4 />}
      </div>

      {!isMobile && (
        <div className="hejhome-reset-password-buttons">
          <Button onClick={onGoBackClick}>
            <svg
              width={18}
              height={18}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.22 14.78.97 9.53a.75.75 0 0 1 0-1.06l5.25-5.25 1.06 1.06-3.97 3.97h13.94v1.5H3.31l3.97 3.97-1.06 1.06Z"
                fill="#504D49"
              />
            </svg>
            뒤로가기
          </Button>
        </div>
      )}
    </div>
  );
}

export default ResetPasswordProcess;
