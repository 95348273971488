import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../index";
import { sleep } from "../../../common/utils";
import { removeSpace } from "../../../api/space/space";

type SpaceRemoveState = {
  loading: boolean;
  error?: string | Error;
  success: boolean | undefined;
  spaceId: number | undefined;
  visible: boolean;
};

const initialState: SpaceRemoveState = {
  loading: false,
  error: undefined,
  spaceId: undefined,
  success: undefined,
  visible: false,
};

export const fetchRemoveSpace = createAsyncThunk(
  "space/remove",
  async (body: { id: number; query?: string }) => {
    await sleep(100);
    const { result } = await removeSpace(body.id, body?.query);
    return result;
  }
);

const slice = createSlice({
  name: "space/remove",
  initialState,
  reducers: {
    resetSpaceRemove: (state: SpaceRemoveState) => ({
      ...state,
      visible: initialState.visible,
      loading: initialState.loading,
      success: initialState.success,
      spaceId: initialState.spaceId,
    }),
    updateSpaceRemoveVisible: (state: SpaceRemoveState, { payload }) => ({
      ...state,
      visible: payload,
    }),
    updateRemoveSpaceId: (state: SpaceRemoveState, { payload }) => ({
      ...state,
      spaceId: payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRemoveSpace.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(fetchRemoveSpace.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = undefined;
      state.success = payload;
    });
    builder.addCase(fetchRemoveSpace.rejected, (state, { error }) => {
      state.loading = false;
      state.success = false;
      state.error = error.message;
    });
  },
});

export const removeSpaceSelector = (state: RootState) => state.space.remove;
export const {
  resetSpaceRemove,
  updateSpaceRemoveVisible,
  updateRemoveSpaceId,
} = slice.actions;

export default slice;
