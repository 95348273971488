import { apiGet, apiPost } from "../../common/api";
import { DeviceType } from "../space/space";

// export type Recipe = {
//   id: number;
//   recipe_name: string;
//   oneClick?: boolean;
//   spaceList: string[];
// };

export type Recipe = {
  id: string;
  user_id: number;
  name: string;
  oneClick: boolean;
  recipeData: string;
  created_at?: string;
  updated_at?: string;
};

export type SpaceDeviceMapping = {
  id: number;
  device_id: number;
  space: {
    id: number;
  };
  userDevices: {
    id: number;
    device_id: string;
  };
};

type RecipeListResponseType = {
  result: {
    recipeList: Recipe[];
    spaceDeviceMappingList: SpaceDeviceMapping[];
  };
};

type RecipeDetailResponseType = {
  result: Recipe;
};

export type ConditionSelectType = {
  select: string;
  value: string;
};
export type ConditionValueType = {
  display: string;
  value: string | number | boolean;
  limit?: number;
  min?: number;
  max?: number;
  unit?: string;
};

type ConditionListResponseType = {
  result: ConditionSelectType[];
};
type ConditionValueResponseType = {
  result: ConditionValueType[];
};
type DeviceListResponseType = {
  result: DeviceType[];
};

type RecipeCreateResponseType = {
  status: number;
  result: boolean;
  message: any;
};

type RecipeUpdateResponseType = {
  status: number;
  result: boolean;
  message: any;
};

type RecipeExecuteResponseType = {
  status: number;
  result: boolean;
  message: any;
};

type RecipeDeleteResponseType = {
  status: number;
  message: any;
};

export const getRecipeList = async () => {
  try {
    const { data } = await apiGet(`/v1.0/openapi/recipe/space/list`);
    return data as RecipeListResponseType;
  } catch (e) {
    throw e;
  }
};
export const getRecipeById = async (recipeId: string) => {
  try {
    const { data } = await apiGet(`/v1.0/openapi/recipe/${recipeId}`);
    return data as RecipeDetailResponseType;
  } catch (e) {
    throw e;
  }
};

export const getConditionListByDeviceType = async (deviceType: string) => {
  try {
    const { data } = await apiGet(
      `/v1.0/openapi/conditions?deviceType=${deviceType}`
    );
    return data as ConditionListResponseType;
  } catch (e) {
    throw e;
  }
};

export const getConditionByName = async (name: string) => {
  try {
    const { data } = await apiGet(`/v1.0/openapi/conditions?name=${name}`);
    return data as ConditionValueResponseType;
  } catch (e) {
    throw e;
  }
};

export const getActionListByDeviceType = async (deviceType: string) => {
  try {
    const { data } = await apiGet(
      `/v1.0/openapi/actions?deviceType=${deviceType}`
    );
    return data as ConditionListResponseType;
  } catch (e) {
    throw e;
  }
};

export const getActionByName = async (name: string) => {
  try {
    const { data } = await apiGet(`/v1.0/openapi/actions?name=${name}`);
    return data as ConditionValueResponseType;
  } catch (e) {
    throw e;
  }
};

export const findAllDeviceListByRecipeType = async (type: string) => {
  try {
    const { data } = await apiGet(`/v1.0/openapi/devices?recipeType=${type}`);
    return data as DeviceListResponseType;
  } catch (e) {
    throw e;
  }
};

export const postRegisterRecipe = async (body: any) => {
  try {
    const { data } = await apiPost(`/v1.0/openapi/recipe/register`, body);
    return data as RecipeCreateResponseType;
  } catch (e) {
    throw e;
  }
};

export const postUpdateRecipe = async (recipeId: string, body: any) => {
  try {
    const { data } = await apiPost(
      `/v1.0/openapi/recipe/update/${recipeId}`,
      body
    );
    return data as RecipeUpdateResponseType;
  } catch (e) {
    throw e;
  }
};

export const postDeleteRecipe = async (deleteIds: string[]) => {
  try {
    const { data } = await apiPost(`/v1.0/openapi/recipe/delete`, {
      deleteIds,
    });
    return data as RecipeDeleteResponseType;
  } catch (e) {
    throw e;
  }
};

export const postExecuteRecipe = async (id: string, recipeData: string) => {
  try {
    const { data } = await apiPost(`/v1.0/openapi/recipe/execute/${id}`, {
      recipeData,
    });
    return data as RecipeExecuteResponseType;
  } catch (e) {
    throw e;
  }
};
