import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sleep } from "../../../common/utils";
import { ActionType, RootState } from "../../index";
import { createSpace } from "../../../api/space/space";

type CreateSpace = {
  loading: boolean;
  error?: string | Error;
  success: boolean | undefined;
  modalOpen: boolean | undefined;
  name: string | undefined;
};

const initialState: CreateSpace = {
  loading: false,
  error: undefined,
  success: undefined,
  modalOpen: undefined,
  name: undefined,
};

type createSpaceRequestType = {
  name: string;
  lan: number;
  lat: number;
  address: string;
  spaceImageUrl: string;
};

export const fetchCreateSpace = createAsyncThunk(
  "space/register",
  async (body: createSpaceRequestType) => {
    await sleep(100);
    const { result } = await createSpace(body);
    return result;
  }
);

const slice = createSlice({
  name: "space/register",
  initialState,
  reducers: {
    resetSpaceRegister: (state) => ({
      ...state,
      success: initialState.success,
      modalOpen: initialState.modalOpen,
    }),
    openRegisterModal: (
      state: CreateSpace,
      action: ActionType<CreateSpace["modalOpen"]>
    ) => ({
      ...state,
      modalOpen: action.payload,
    }),
    updateCreateName: (
      state: CreateSpace,
      action: ActionType<CreateSpace["name"]>
    ) => ({
      ...state,
      name: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCreateSpace.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(fetchCreateSpace.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = undefined;
      state.success = payload;
    });
    builder.addCase(fetchCreateSpace.rejected, (state, { error }) => {
      state.loading = false;
      state.success = false;
      state.error = error.message;
    });
  },
});

export const createSpaceSelector = (state: RootState) => state.space.register;
export const { resetSpaceRegister, openRegisterModal, updateCreateName } =
  slice.actions;

export default slice;
