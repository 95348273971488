import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sleep } from "../../../common/utils";
import { ActionType, RootState } from "../../index";
import { editSpace } from "../../../api/space/space";

type UpdateSpace = {
  loading: boolean;
  error?: string | Error;
  success: boolean | undefined;
  modal: {
    open: boolean;
    originalName: string | undefined;
    updateSpaceId: number | undefined;
    name: string | undefined;
    address: string | undefined;
  };
};

const initialState: UpdateSpace = {
  loading: false,
  error: undefined,
  success: undefined,
  modal: {
    open: false,
    originalName: undefined,
    updateSpaceId: undefined,
    name: undefined,
    address: undefined,
  },
};

export const fetchUpdateSpace = createAsyncThunk(
  "space/update",
  async (body: {
    spaceId: number;
    name: string;
    lan: number;
    lat: number;
    address: string;
    spaceImageUrl: string;
  }) => {
    await sleep(100);
    const { result } = await editSpace(body.spaceId, {
      name: body.name,
      lan: body.lan,
      lat: body.lat,
      address: body.address,
      spaceImageUrl: body.spaceImageUrl,
    });
    return result;
  }
);

const slice = createSlice({
  name: "space/update",
  initialState,
  reducers: {
    resetUpdateSpace: (state) => ({
      ...state,
      success: initialState.success,
      modal: {
        ...initialState.modal,
      },
    }),
    openUpdateModal: (
      state: UpdateSpace,
      action: ActionType<UpdateSpace["modal"]>
    ) => ({
      ...state,
      modal: {
        ...state.modal,
        ...action.payload,
      },
    }),
    updateSpaceInfo: (state: UpdateSpace, { payload: { name, address } }) => ({
      ...state,
      modal: {
        ...state.modal,
        name,
        address,
      },
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUpdateSpace.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(fetchUpdateSpace.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.success = payload;
      state.error = undefined;
    });
    builder.addCase(fetchUpdateSpace.rejected, (state, { error }) => {
      state.loading = false;
      state.success = false;
      state.error = error.message;
    });
  },
});

export const updateSpaceSelector = (state: RootState) => state.space.update;
export const { resetUpdateSpace, openUpdateModal, updateSpaceInfo } =
  slice.actions;

export default slice;
