import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../index";

type SettingState = {
  openSettingModal: boolean;
  settingMenu: string;
  logoutModal: boolean;
};

const initialState: SettingState = {
  openSettingModal: false,
  settingMenu: "account",
  logoutModal: false,
};

const slice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    resetSetting: (state) => ({
      ...state,
      openSettingModal: initialState.openSettingModal,
      settingMenu: initialState.settingMenu,
      logoutModal: initialState.logoutModal,
    }),
    updateOpenSettingModal: (state, { payload }) => ({
      ...state,
      openSettingModal: payload,
    }),
    updateSettingMenu: (state, { payload }) => ({
      ...state,
      settingMenu: payload,
    }),
    updateLogoutModal: (state, { payload }) => ({
      ...state,
      logoutModal: payload,
    }),
  },
});

export const settingSelector = (state: RootState) => state.setting.main;
export const {
  updateOpenSettingModal,
  resetSetting,
  updateSettingMenu,
  updateLogoutModal,
} = slice.actions;

export default slice;
