import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../index";

type ToastcamState = {
  visible: boolean;
  step: number;
  cam?: {
    name: string;
    space: { id: number; name: string };
    camera: { id: number; name: string };
  };
  peristalsis: boolean;
};

const initialState: ToastcamState = {
  visible: false,
  step: 1,
  cam: undefined,
  peristalsis: true,
};

const slice = createSlice({
  name: "setting/Toastcam/main",
  initialState,
  reducers: {
    resetToastcamModalVisible: (state: ToastcamState) => ({
      ...state,
      visible: initialState.visible,
      step: initialState.step,
      cam: initialState.cam,
      peristalsis: initialState.peristalsis,
    }),
    updateToastcamModalVisible: (state: ToastcamState, { payload }) => ({
      ...state,
      visible: payload,
    }),
    updateToastcamStep: (state: ToastcamState) => ({
      ...state,
      step: state.step === 4 ? 2 : state.step + 1,
    }),
    updateToastcamData: (
      state: ToastcamState,
      { payload: { name, space, camera } }
    ) => ({
      ...state,
      cam: {
        ...state.cam,
        name,
        space: {
          ...state.cam?.space,
          ...space,
        },
        camera: {
          ...state.cam?.camera,
          ...camera,
        },
      },
    }),
    updatePeristalsis: (state: ToastcamState, { payload }) => ({
      ...state,
      peristalsis: payload,
    }),
  },
});

export const settingToastcamSelector = (state: RootState) =>
  state.setting.toastcam.main;

export const {
  resetToastcamModalVisible,
  updateToastcamModalVisible,
  updateToastcamStep,
  updateToastcamData,
  updatePeristalsis,
} = slice.actions;

export default slice;
