import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { panelDetailSelector } from "../../store/panel/detail";
import { DeviceDetailType } from "../../api/devices/device";
import { Button } from "antd";
import { AppDispatch } from "../../store";
import { fetchDeviceControl } from "../../store/panel/control";

const TvRemotePanel = ({ action, token }: { action: any; token?: string }) => {
  const { detail } = useSelector(panelDetailSelector);
  const { id, deviceName, spaceName, categoryName, brandName, remoteIndex } =
    detail as DeviceDetailType;
  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const dispatch = useDispatch<AppDispatch>();

  const handleControl = (key: string) => () => {
    id &&
      dispatch(
        fetchDeviceControl({
          id,
          token,
          body: {
            category: categoryName,
            brand: brandName,
            remoteIndex,
            command: key,
          },
        })
      );
  };

  return (
    <section className="device-setting-body tv-remote-wrap">
      <div className="device-setting-top">
        <h1>{deviceName}</h1>
        <div className="device-setting-header">
          <div className="remain-battery">
            <p>{spaceName ? spaceName : "미지정"}</p>
          </div>
          <Button onClick={handleControl("power")} className="power-button">
            <img
              src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/Power.png`}
              alt="power"
            />
          </Button>
        </div>
      </div>
      <div className="function-button-container">
        <Button onClick={handleControl("homepage")}>
          <img
            src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/home.png`}
            alt="home"
          />
        </Button>
        <Button onClick={handleControl("back")}>
          <img
            src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/back.png`}
            alt="back"
          />
        </Button>
        <Button onClick={handleControl("input")}>
          <img
            src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/input.png`}
            alt="input"
          />
        </Button>
      </div>
      <div className="device-display tv-remote">
        <div className="volume">
          <Button onClick={handleControl("volumeUp")}>
            <img
              src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/remote-plus.png`}
              alt="remote-plus"
            />
          </Button>
          <p>볼륨</p>
          <Button onClick={handleControl("volumeDown")}>
            <img
              src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/remote-minus.png`}
              alt="remote-minus"
            />
          </Button>
        </div>
        <div className="navigate">
          <div className="left">
            <Button onClick={handleControl("navigateLeft")} className="left">
              <img
                src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/left.png`}
                alt="left"
              />
            </Button>
          </div>
          <div className="center">
            <Button onClick={handleControl("navigateUp")} className="up">
              <img
                src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/up.png`}
                alt="up"
              />
            </Button>
            <Button onClick={handleControl("ok")} className="ok">
              <img
                src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/ok.png`}
                alt="ok"
              />
            </Button>
            <Button onClick={handleControl("navigateDown")} className="down">
              <img
                src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/down.png`}
                alt="down"
              />
            </Button>
          </div>
          <div className="right">
            <Button onClick={handleControl("navigateRight")} className="right">
              <img
                src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/right.png`}
                alt="right"
              />
            </Button>
          </div>
        </div>
        <div className="channel">
          <Button onClick={handleControl("channelUp")}>
            <img
              src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/up_arrow.png`}
              alt="channelUp"
            />
          </Button>
          <p>채널</p>
          <Button onClick={handleControl("channelDown")}>
            <img
              src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/down_arrow.png`}
              alt="channelDown"
            />
          </Button>
        </div>
      </div>
      <div className="device-control device-control-flex tv-remote">
        <ul>
          {numbers.map((number) => (
            <li onClick={handleControl(number.toString())} key={number}>
              {number}
            </li>
          ))}
          <li onClick={handleControl("mute")} key={11}>
            <img
              src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/mute.png`}
              alt="mute"
            />
          </li>
          <li onClick={handleControl("0")} key={12}>
            0
          </li>
          <li onClick={handleControl("menu")}>
            <img
              src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/setting.png`}
              alt="setting"
            />
          </li>
        </ul>
      </div>
    </section>
  );
};

export default TvRemotePanel;
