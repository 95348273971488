import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sleep } from "../../../common/utils";
import { RootState } from "../../index";
import { postDeviceControlByDeviceId } from "../../../api/devices/device";

type PanelControlState = {
  loading: boolean;
  error?: string | Error;
  success?: boolean;
};

const initialState: PanelControlState = {
  loading: false,
  error: undefined,
  success: undefined,
};

export const fetchDeviceControl = createAsyncThunk(
  "panel/control",
  async ({
    id,
    body,
    token,
  }: {
    id: number;
    body: { [key: string]: any };
    token?: string;
  }) => {
    await sleep(100);
    const { result } = await postDeviceControlByDeviceId(id, body, token);
    return result;
  }
);

const slice = createSlice({
  name: "panel/control",
  initialState,
  reducers: {
    resetDeviceControl: (state: PanelControlState) => ({
      ...state,
      success: initialState.success,
      loading: initialState.loading,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDeviceControl.pending, (state: PanelControlState) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(
      fetchDeviceControl.fulfilled,
      (state: PanelControlState, { payload }) => {
        state.loading = false;
        state.error = undefined;
        state.success = payload;
      }
    );
    builder.addCase(
      fetchDeviceControl.rejected,
      (state: PanelControlState, { error }) => {
        state.loading = false;
        state.error = error.message;
      }
    );
  },
});

export const panelControlSelector = (state: RootState) => state.panel.control;
export const { resetDeviceControl } = slice.actions;

export default slice;
