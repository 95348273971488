import { apiGet, apiPost } from "../../common/api";

export type categoryDeviceType = {
  categoryCode: string;
  categoryName: string;
  deviceList: {
    id: number;
    deviceId: string;
    deviceName: string;
    deviceType: string;
    spaceName: string;
    productName: string;
  }[];
};

export type widgetValue = {
  deviceType: string;
  essentials: string[];
  widgets: {
    default: boolean;
    widgetName: string;
    data: string[];
    style: {
      h: number;
      w: number;
    };
  }[];
};

type WidgetCategoryListType = {
  status: number;
  result: categoryDeviceType[];
};

export type SpaceDeviceType = {
  spaceName: string;
  deviceName: string;
}[];

type SpaceDeviceResponseType = {
  status: number;
  result: SpaceDeviceType;
};

export type WidgetType = {
  default: true;
  widgetName: string;
  data: string[];
  style: {
    w: number;
    h: number;
  };
};

type WidgetListResponseType = {
  status: number;
  result: {
    deviceType: string;
    essentials: string[];
    widgets: WidgetType[];
  };
};

type WidgetAddResponseType = {
  status: number;
  result: boolean;
};

export const getWidgetCategoryDeviceList = async () => {
  try {
    const { data } = await apiGet(`/v1.0/openapi/widgets`);
    return data as WidgetCategoryListType;
  } catch (e) {
    throw e;
  }
};
export const getWidgetListByDeviceType = async (deviceType: string) => {
  try {
    const { data } = await apiGet(`/v1.0/openapi/widgets/${deviceType}`);
    return data as WidgetListResponseType;
  } catch (e) {
    throw e;
  }
};

export const getSpaceDeviceInfoList = async (pid: string) => {
  try {
    const { data } = await apiGet(`/v1.0/openapi/widgets/${pid}/devices`);
    return data as SpaceDeviceResponseType;
  } catch (e) {
    throw e;
  }
};

export const addWidgetInDashboard = async (
  deviceId: number,
  dashboardId: number,
  widgetState: string,
  widgetName: string
) => {
  try {
    const { data } = await apiPost(`/v1.0/openapi/widgets`, {
      deviceId,
      dashboardId,
      widgetState,
      widgetName,
    });
    return data as WidgetAddResponseType;
  } catch (e) {
    throw e;
  }
};
