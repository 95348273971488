import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sleep } from "../../../common/utils";
import { RootState } from "../../index";
import {
  getWidgetListByDeviceType,
  widgetValue,
} from "../../../api/widget/widget";

type WidgetValueState = {
  loading: boolean;
  error?: string | Error;
  widgetValue: widgetValue | undefined;
};

const initialState: WidgetValueState = {
  loading: false,
  error: undefined,
  widgetValue: undefined,
};

export const fetchGetWidgetValueByDeviceType = createAsyncThunk(
  "dashboardManage/widgetList",
  async (deviceType: string) => {
    await sleep(100);
    const { result } = await getWidgetListByDeviceType(deviceType);
    return result;
  }
);

const slice = createSlice({
  name: "dashboardManage/widgetList",
  initialState,
  reducers: {
    resetWidgetValue: (state) => ({
      ...state,
      widgetList: initialState.widgetValue,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetWidgetValueByDeviceType.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(
      fetchGetWidgetValueByDeviceType.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = undefined;
        state.widgetValue = {
          ...state,
          ...payload,
        };
      }
    );
    builder.addCase(
      fetchGetWidgetValueByDeviceType.rejected,
      (state, { error }) => {
        state.loading = false;
        state.error = error.message;
      }
    );
  },
});

export const widgetValueSelector = (state: RootState) =>
  state.dashboardManage.widget;
export const { resetWidgetValue } = slice.actions;

export default slice;
