export const regex = /^#[0-9a-f]{3,6}$/i;

export const parseToDecimal = (value: number) => {
  let index = 2;
  if (value < 100) index = 1;
  const parsedDecimalPoint = value.toString().substring(0, index) + '.' + value.toString().substring(index);
  return Math.round(parseFloat(parsedDecimalPoint));
};

export const parseToDecimalPoint = (value: number) => {
  let index = 2;

  if (value < 0) {
    if (value > -10) {
      index = 1;
    } else if (value > -100) {
      index = 2;
    } else {
      index = 3;
    }
  } else {
    if (value > 1000) {
      index = 3;
    } else if (value < 100) {
      index = 1;
    }
  }

  const parsedDecimalPoint = value.toString().substring(0, index) + '.' + value.toString().substring(index);
  return parseFloat(parsedDecimalPoint);
};
