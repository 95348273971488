import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sleep } from "../../../../../common/utils";
import { RootState } from "../../../../index";
import { getPosSummaryCommon } from "../../../../../api/pos/pos";

type SummaryRateEntity = {
  totalAmount: string;
  salesCount: string;
  rateType: string;
  rate: string;
};

export type commonSummary = {
  rateOfSalesDaily: SummaryRateEntity;
  rateOfSalesYesterday: SummaryRateEntity;
  rateOfSalesYesterdayJust: SummaryRateEntity;
  rateOfSalesWeekly: SummaryRateEntity;
  rateOfSalesMonthly: SummaryRateEntity;
};

type CommonConclusion = {
  loading: boolean;
  error?: string | Error;
  commonConclusion: commonSummary;
};

const initialState: CommonConclusion = {
  loading: false,
  error: undefined,
  commonConclusion: {
    rateOfSalesDaily: {
      totalAmount: "",
      salesCount: "",
      rateType: "",
      rate: "",
    },
    rateOfSalesYesterday: {
      totalAmount: "",
      salesCount: "",
      rateType: "",
      rate: "",
    },
    rateOfSalesYesterdayJust: {
      totalAmount: "",
      salesCount: "",
      rateType: "",
      rate: "",
    },
    rateOfSalesWeekly: {
      totalAmount: "",
      salesCount: "",
      rateType: "",
      rate: "",
    },
    rateOfSalesMonthly: {
      totalAmount: "",
      salesCount: "",
      rateType: "",
      rate: "",
    },
  },
};

export const fetchGetCommonConclusion = createAsyncThunk(
  "airPos/common/conclusion",
  async ({ date, deviceId }: { date: string; deviceId?: number }) => {
    await sleep(100);

    const { result } = await getPosSummaryCommon({
      date,
      deviceId,
    });

    return result;
  }
);

const slice = createSlice({
  name: "airPos/common/conclusion",
  initialState,
  reducers: {
    resetPosCommonConclusion: (state) => ({
      ...state,
      commonConclusion: initialState.commonConclusion,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetCommonConclusion.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(
      fetchGetCommonConclusion.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = undefined;
        state.commonConclusion = payload;
      }
    );
    builder.addCase(fetchGetCommonConclusion.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    });
  },
});

export const posCommonConclusionSelector = (state: RootState) =>
  state.pos.commonConclusion;
export const { resetPosCommonConclusion } = slice.actions;

export default slice;
