import { Button, Radio, RadioChangeEvent } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../store";
import {
  updateToastcamModalVisible,
  updateToastcamStep,
} from "../../../../../store/setting/toascam";
import {
  toastCam,
  settingToastcamListSelector,
  updateSelectedCam,
  fetchGetToastCamList,
} from "../../../../../store/setting/toascam/list";

function ToastcamDeviceList({
  id,
  password,
}: {
  id: string;
  password: string;
}) {
  const dispatch = useDispatch<AppDispatch>();
  const { toastcamList, selectedCam, isAgain } = useSelector(
    settingToastcamListSelector
  );

  useEffect(() => {
    dispatch(
      fetchGetToastCamList({
        checkAgain: isAgain,
        bizUserId: id,
        bizUserPassword: password,
      })
    );
  }, []);

  const onChangeCam = (e: RadioChangeEvent) => {
    dispatch(updateSelectedCam({ id: e.target.value, name: e.target.id }));
  };

  return (
    <>
      <h1>카메라 선택</h1>
      <p>연동할 카메라를 선택해주세요.</p>
      <p>설정되지 않은 기기는 '장소 미지정'에 위치됩니다.</p>
      {toastcamList && !toastcamList.length ? (
        <p>장소가 설정되지 않은 기기가 없습니다.</p>
      ) : (
        <ul className="cam-list">
          <Radio.Group onChange={onChangeCam}>
            {toastcamList &&
              toastcamList.map((cam: toastCam) => {
                return (
                  <li
                    key={cam.id}
                    className={`cam-item ${
                      selectedCam && selectedCam.id === cam.id ? "selected" : ""
                    }`}
                  >
                    <Radio value={cam.id} id={cam.device_name}>
                      <div className="cam-info">
                        <img
                          src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/toastcam_icon.png`}
                          alt="toastcam_icon"
                        />
                        <p>{cam.device_name}</p>
                      </div>
                    </Radio>
                  </li>
                );
              })}
          </Radio.Group>
        </ul>
      )}
      <div className="cam-footer">
        <Button
          disabled={!selectedCam || !toastcamList.length}
          onClick={() => dispatch(updateToastcamStep())}
          type="primary"
        >
          다음
        </Button>
        {((toastcamList && !toastcamList.length) || selectedCam) && (
          <Button onClick={() => dispatch(updateToastcamModalVisible(false))}>
            닫기
          </Button>
        )}
      </div>
    </>
  );
}

export default ToastcamDeviceList;
