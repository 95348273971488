import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ToastcamLogin from "./step1/ToastcamLogin";
import ToastcamDeviceList from "./step2/ToastcamDeviceList";
import ToastcamSetting from "./step3/ToastcamSetting";
import ToastcamComplete from "./step4/ToastcamComplete";
import { settingToastcamSelector } from "../../../../store/setting/toascam";
import {
  resetToastcamModalVisible,
  updateToastcamModalVisible,
} from "../../../../store/setting/toascam";
import { resetToastcamList } from "../../../../store/setting/toascam/list";
import { AppDispatch } from "../../../../store";
import { fetchGetSyncPlatformList } from "../../../../store/setting/account/syncPlatforms";

function ToastCamModal() {
  const { visible, step } = useSelector(settingToastcamSelector);
  const [id, setId] = useState("devteam@goqual.com");
  const [password, setPassword] = useState("toastcam123");

  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    if (!visible) {
      dispatch(fetchGetSyncPlatformList());
      dispatch(resetToastcamModalVisible());
      dispatch(resetToastcamList());
    }
  }, [visible]);
  const onCancel = () => {
    dispatch(fetchGetSyncPlatformList());
    dispatch(updateToastcamModalVisible(false));
  };
  return (
    <Modal
      className="toastcam-modal"
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <img
        src={`${process.env.REACT_APP_S3_URL}/1.0/logos/toastcam_logo.png`}
        alt="airpos_logo"
      />
      {step === 1 && (
        <ToastcamLogin
          id={id}
          password={password}
          setId={setId}
          setPassword={setPassword}
        />
      )}
      {step === 2 && <ToastcamDeviceList id={id} password={password} />}
      {step === 3 && <ToastcamSetting />}
      {step === 4 && <ToastcamComplete />}
    </Modal>
  );
}

export default ToastCamModal;
