import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../index";
import { sleep } from "../../../../common/utils";
import { getPosList, getPosListNoSpace } from "../../../../api/airPos/airPos";

export type Camera = {
  id: string;
  camera_name: string;
};

export type Space = {
  id: string;
  space_name: string;
};

export type Airpos = {
  id: string;
  device_name: string;
};

type AirposListState = {
  loading: boolean;
  error?: string | Error;
  posList: Airpos[];
  selectedPos?: Airpos;
  selectedSpace?: Space;
  selectedCamera?: Camera;
  isAgain: boolean;
  posNewName?: string;
};

const initialState: AirposListState = {
  loading: false,
  error: undefined,
  posList: [],
  selectedPos: undefined,
  selectedSpace: undefined,
  selectedCamera: undefined,
  isAgain: false,
  posNewName: undefined,
};

export const fetchGetAirPosList = createAsyncThunk(
  "setting/AirPos/list",
  async ({
    checkAgain,
    bizUserId,
    bizUserPassword,
  }: {
    checkAgain: boolean;
    bizUserId: string;
    bizUserPassword: string;
  }) => {
    await sleep(100);
    if (!checkAgain) {
      const body = { bizUserId: bizUserId, bizUserPassword: bizUserPassword };
      const { result } = await getPosList(body);
      return result;
    } else {
      const body = { bizUserId: bizUserId, bizUserPassword: bizUserPassword };
      const { result } = await getPosListNoSpace(body);
      return result;
    }
  }
);

const slice = createSlice({
  name: "setting/airpos/list",
  initialState,
  reducers: {
    resetAirposList: (state: AirposListState) => ({
      ...state,
      list: initialState.posList,
      selectedPos: initialState.selectedPos,
    }),
    updateSelectedPos: (
      state: AirposListState,
      { payload: { id, device_name } }
    ) => ({
      ...state,
      selectedPos: {
        ...state.selectedPos,
        id,
        device_name,
      },
    }),
    updateSelectedPosNewName: (
      state: AirposListState,
      { payload: { id, device_name, device_name_new } }
    ) => ({
      ...state,
      selectedPos: {
        ...state.selectedPos,
        id,
        device_name,
        device_name_new,
      },
    }),
    updateSelectedSpace: (
      state: AirposListState,
      { payload: { id, space_name } }
    ) => ({
      ...state,
      selectedSpace: {
        ...state.selectedSpace,
        id,
        space_name,
      },
    }),
    updateSelectedCamera: (
      state: AirposListState,
      { payload: { id, camera_name } }
    ) => ({
      ...state,
      selectedCamera: {
        ...state.selectedCamera,
        id,
        camera_name,
      },
    }),
    updatePosNewName: (
      state: AirposListState,
      { payload: { posNewName } }
    ) => ({
      ...state,
      posNewName: posNewName,
    }),
    updateSettingCamAgain: (state: AirposListState) => ({
      ...state,
      isAgain: true,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetAirPosList.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(fetchGetAirPosList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = undefined;
      state.posList = payload;
    });
    builder.addCase(fetchGetAirPosList.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    });
  },
});

export const settingAirposListSelector = (state: RootState) =>
  state.setting.airpos.list;

export const {
  resetAirposList,
  updateSelectedPos,
  updateSelectedSpace,
  updateSelectedCamera,
  updatePosNewName,
  updateSettingCamAgain,
} = slice.actions;

export default slice;
