import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../index";

type ServiceInfoState = {
  visible: boolean;
  term?: string;
};

const initialState: ServiceInfoState = {
  visible: false,
  term: undefined,
};

const slice = createSlice({
  name: "setting/serviceInfo",
  initialState,
  reducers: {
    resetServiceInfoModalVisible: (state: ServiceInfoState) => ({
      ...state,
      visible: initialState.visible,
      term: initialState.term,
    }),
    updateServiceInfoModalVisible: (state: ServiceInfoState, { payload }) => ({
      ...state,
      visible: payload,
    }),
    updateServiceInfoTerm: (
      state: ServiceInfoState,
      { payload: { visible, term } }
    ) => ({
      ...state,
      visible,
      term,
    }),
  },
});

export const settingServiceInfoSelector = (state: RootState) =>
  state.setting.serviceInfo;

export const {
  resetServiceInfoModalVisible,
  updateServiceInfoModalVisible,
  updateServiceInfoTerm,
} = slice.actions;

export default slice;
