import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "antd";
import { switchPowers } from "../../common/deviceStatePowerKey";
import { panelDetailSelector } from "../../store/panel/detail";
import { DeviceDetailType } from "../../api/devices/device";
import { AppDispatch } from "../../store";
import { fetchDeviceControl } from "../../store/panel/control";

const SwitchPanel = ({ action, token }: { action: any; token?: string }) => {
  const { detail } = useSelector(panelDetailSelector);
  const { id, status, deviceName, spaceName } = detail as DeviceDetailType;
  const length = Object.keys(status as { [key: string]: any }).length;
  const odd = length % 2 !== 0;

  const dispatch = useDispatch<AppDispatch>();

  const handleIndividualPower = useCallback(
    (key: string) => () => {
      if (id && status)
        dispatch(
          fetchDeviceControl({
            id,
            token,
            body: { [key]: !status[key as switchPowers] },
          })
        );
    },
    [status, dispatch, id]
  );

  const handleMainPower = useCallback(
    (value: boolean) => () => {
      const newSwitchPower = { ...status };
      Object.keys(newSwitchPower).forEach((key) => {
        newSwitchPower[key as switchPowers] = value;
      });
      if (id && status)
        dispatch(
          fetchDeviceControl({
            id,
            token,
            body: newSwitchPower,
          })
        );
      action();
    },
    [status, dispatch, id]
  );

  return (
    <section className="device-setting-body">
      <div className="device-setting-top">
        <h1>{deviceName}</h1>
        <div className="device-setting-header">
          <div className="remain-battery">
            <p>{spaceName ? spaceName : "미지정"}</p>
          </div>
        </div>
      </div>
      <div className="device-display switches">
        <h2>개별 제어</h2>
        {odd ? (
          <div className={`display switch-container`}>
            {Object.keys(status as { [p: string]: any }).map((key, i) => (
              <div
                key={i}
                className={`switch-item ${
                  odd && i === 0 ? `odd switch-${length}` : ""
                } ${
                  (status as { [p: string]: any })[`power${i + 1}`] ? "on" : ""
                }`}
              >
                <h1>{i + 1}구</h1>
                <Switch
                  size="default"
                  key={i}
                  checked={(status as { [p: string]: any })[`power${i + 1}`]}
                  onChange={handleIndividualPower(`power${i + 1}`)}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className={`display switch-container`}>
            {Object.keys(status as { [p: string]: any }).map((key, i) => (
              <div
                className={`switch-item ${
                  (status as { [p: string]: any })[`power${i + 1}`] ? "on" : ""
                }`}
              >
                <h1>{i + 1}구</h1>
                <Switch
                  key={i}
                  checked={(status as { [p: string]: any })[`power${i + 1}`]}
                  onChange={handleIndividualPower(`power${i + 1}`)}
                />
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="device-control-wrap">
        <div className="device-control device-control-flex">
          <button onClick={handleMainPower(true)} className="switch-button">
            <img
              src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/power_all_on_icon.png`}
              alt="power_all_on"
            />
            <span>모두 켜기</span>
          </button>
          <button onClick={handleMainPower(false)} className="switch-button">
            <img
              src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/power_all_off_icon.png`}
              alt="power_all_off"
            />
            <span>모두 끄기</span>
          </button>
        </div>
      </div>
    </section>
  );
};

export default SwitchPanel;
