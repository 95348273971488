import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../index";
import { sleep } from "../../../common/utils";
import {
  getCctvList,
  getCctvMotionZones,
  getCctvPlayInformation,
} from "../../../api/cctv/cctv";

type CctvState = {
  loading: boolean;
  error?: string | Error;
  motiondetectzones: {
    message: string;
    result: {
      zones: {
        zoneIndex: string;
        labelName: string;
        color: string;
        coordinate: string;
        modifyDate: number;
        regDate: number;
      }[];
    };
  };
};

const initialState: CctvState = {
  loading: false,
  error: undefined,
  motiondetectzones: {
    message: "",
    result: {
      zones: [],
    },
  },
};

export const fetchGetCctvMotionZones = createAsyncThunk(
  "cctv/motionzones",
  async (cameraId: number) => {
    await sleep(100);
    const { data } = await getCctvMotionZones(cameraId);
    return data;
  }
);

const slice = createSlice({
  name: "cctv/motionzones",
  initialState,
  reducers: {
    resetCctvMotionZones: (state) => ({
      ...state,
      cctvPlayInformation: initialState.motiondetectzones,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetCctvMotionZones.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(fetchGetCctvMotionZones.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = undefined;
      state.motiondetectzones = payload;
    });
    builder.addCase(fetchGetCctvMotionZones.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    });
  },
});

export const cctvMotionZonesSelector = (state: RootState) =>
  state.cctv.motionDetectZones;
export const { resetCctvMotionZones } = slice.actions;

export default slice;
