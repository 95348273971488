import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../index";
import { sleep } from "../../../common/utils";
import {
  ConditionValueType,
  getConditionByName,
} from "../../../api/recipe/recipe";

type RecipeConditionState = {
  loading: boolean;
  error?: string | Error;
  conditionValueList: ConditionValueType[];
};

const initialState: RecipeConditionState = {
  loading: false,
  error: "",
  conditionValueList: [],
};

export const fetchGetConditionValue = createAsyncThunk(
  "recipe/condition",
  async (name: string) => {
    await sleep(100);
    const { result } = await getConditionByName(name);
    return result;
  }
);
const slice = createSlice({
  name: "recipe/condition",
  initialState,
  reducers: {
    resetRecipeConditionValue: (state: RecipeConditionState) => ({
      ...state,
      loading: initialState.loading,
      error: initialState.error,
      conditionValueList: initialState.conditionValueList,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchGetConditionValue.pending,
      (state: RecipeConditionState) => {
        state.loading = true;
      }
    );
    builder.addCase(
      fetchGetConditionValue.fulfilled,
      (state: RecipeConditionState, { payload }) => {
        state.loading = false;
        state.error = undefined;
        state.conditionValueList = payload;
      }
    );
    builder.addCase(
      fetchGetConditionValue.rejected,
      (state: RecipeConditionState, { error }) => {
        state.loading = false;
        state.error = error.message as string;
        state.conditionValueList = [];
      }
    );
  },
});

export const recipeConditionSelector = (state: RootState) =>
  state.recipe.condition;
export const { resetRecipeConditionValue } = slice.actions;
export default slice;
