import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../index";
type RecipeSettingState = {
  visible: boolean;
  selectedRecipeList: string[];
};

const initialState: RecipeSettingState = {
  visible: false,
  selectedRecipeList: [],
};

const slice = createSlice({
  name: "recipe/setting",
  initialState,
  reducers: {
    resetRecipeSettingModal: (state: RecipeSettingState) => ({
      ...state,
      visible: initialState.visible,
      selectedRecipeList: initialState.selectedRecipeList,
    }),
    updateRecipeSettingModal: (state: RecipeSettingState, { payload }) => ({
      ...state,
      visible: payload,
    }),
    addRecipeSelectedList: (state: RecipeSettingState, { payload }) => ({
      ...state,
      selectedRecipeList: [...state.selectedRecipeList, payload],
    }),
    removeRecipeSelectedList: (state: RecipeSettingState, { payload }) => ({
      ...state,
      selectedRecipeList: state.selectedRecipeList.filter(
        (recipe) => recipe !== payload
      ),
    }),
  },
});

export const recipeSettingSelector = (state: RootState) => state.recipe.setting;
export const {
  updateRecipeSettingModal,
  resetRecipeSettingModal,
  addRecipeSelectedList,
  removeRecipeSelectedList,
} = slice.actions;
export default slice;
