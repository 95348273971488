import { Layout, Modal } from "antd";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { settingSelector, resetSetting } from "../../store/setting";
import { AppDispatch } from "../../store";

import "./setting-page.scss";
import SettingMenus from "./navigation/SettingMenus";
import AccountManage from "./content/AccountManage";
import PeristalsisInfo from "./content/PeristalsisInfo";
import PaymentManage from "./content/PaymentManage";
import ServiceInfo from "./content/ServiceInfo";
import { fetchGetSyncPlatformList } from "../../store/setting/account/syncPlatforms";
import LogoutModal from "./LogoutModal";

const { Sider, Content } = Layout;
function SettingModal() {
  const dispatch = useDispatch<AppDispatch>();
  const { openSettingModal, settingMenu } = useSelector(settingSelector);

  useEffect(() => {
    dispatch(fetchGetSyncPlatformList());
  }, []);

  useEffect(() => {
    !openSettingModal && dispatch(resetSetting());
  }, [openSettingModal]);

  const onClose = useCallback(async () => {
    dispatch(resetSetting());
  }, [dispatch]);

  const renderSettingMenu = useCallback(() => {
    switch (settingMenu) {
      case "account":
        return <AccountManage />;
      case "peristalsis":
        return <PeristalsisInfo />;
      case "payment":
        return <PaymentManage />;
      case "service":
        return <ServiceInfo />;
    }
  }, [settingMenu]);

  return (
    <Modal
      className="setting-modal"
      visible={openSettingModal}
      footer={null}
      onCancel={onClose}
    >
      <Layout>
        <Sider trigger={null} collapsible className="setting-sider">
          <SettingMenus />
        </Sider>
        <Layout className="site-layout">
          <Content className="setting-layout-content">
            {renderSettingMenu()}
          </Content>
        </Layout>
      </Layout>
      <LogoutModal />
    </Modal>
  );
}

export default SettingModal;
