import React from "react";
import { useTitle } from "ahooks";
import PanelContainer from "../components/panels/Panel";
function Panel() {
  useTitle("Panel | 헤이홈 스퀘어");

  return (
    <div>
      <PanelContainer />
    </div>
  );
}

export default Panel;
