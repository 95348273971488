import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sleep } from "../../../common/utils";
import {
  deleteDashboardById,
  registerDashboard,
} from "../../../api/dashboard/dashboard";
import { RootState } from "../../index";

type DashboardDeleteState = {
  loading: boolean;
  error?: string | Error;
  id?: number;
  success: boolean;
  visible: boolean;
};

const initialState: DashboardDeleteState = {
  loading: false,
  success: false,
  visible: false,
  id: undefined,
};

export const fetchDeleteDashboard = createAsyncThunk(
  "dashboard/delete",
  async (id: number) => {
    await sleep(100);
    const { result } = await deleteDashboardById(id);
    return result;
  }
);

const slice = createSlice({
  name: "dashboard/delete",
  initialState,
  reducers: {
    resetDashboardDelete: (state) => ({
      ...state,
      success: initialState.success,
      visible: initialState.visible,
      id: initialState.id,
    }),
    updateDashboardDeleteModalVisible: (state, { payload }) => ({
      ...state,
      visible: payload,
    }),
    updateDashboardDeleteId: (state, { payload }) => ({
      ...state,
      id: payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDeleteDashboard.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchDeleteDashboard.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = undefined;
      state.success = payload;
    });
    builder.addCase(fetchDeleteDashboard.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message as string;
      state.success = false;
    });
  },
});

export const dashboardDeleteSelector = (state: RootState) =>
  state.dashboard.delete;
export const {
  resetDashboardDelete,
  updateDashboardDeleteModalVisible,
  updateDashboardDeleteId,
} = slice.actions;

export default slice;
