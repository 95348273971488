import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sleep } from "../../../common/utils";
import { RootState } from "../../index";
import {
  CategoryType,
  getCategoryListByDeviceId,
} from "../../../api/devices/device";

type CategoryListState = {
  loading: boolean;
  error?: string | Error;
  visible: boolean;
  list: CategoryType[];
};

const initialState: CategoryListState = {
  loading: false,
  error: undefined,
  visible: false,
  list: [],
};

export const fetchGetCategoryList = createAsyncThunk(
  "panel/categoryList",
  async ({ id, token }: { id: number; token?: string }) => {
    await sleep(100);
    const { result } = await getCategoryListByDeviceId(id, token);
    return result;
  }
);

const slice = createSlice({
  name: "panel/categoryList",
  initialState,
  reducers: {
    resetCategoryList: (state: CategoryListState) => ({
      ...state,
      loading: initialState.loading,
      visible: initialState.visible,
      list: initialState.list,
    }),
    updateRemoteAddModal: (state: CategoryListState, { payload }) => ({
      ...state,
      visible: payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchGetCategoryList.pending,
      (state: CategoryListState) => {
        state.loading = true;
        state.error = undefined;
      }
    );
    builder.addCase(
      fetchGetCategoryList.fulfilled,
      (state: CategoryListState, { payload }) => {
        state.loading = false;
        state.error = undefined;
        state.list = payload;
      }
    );
    builder.addCase(
      fetchGetCategoryList.rejected,
      (state: CategoryListState, { error }) => {
        state.loading = false;
        state.error = error.message;
      }
    );
  },
});

export const categoryListSelector = (state: RootState) =>
  state.panel.categoryList;
export const { resetCategoryList, updateRemoteAddModal } = slice.actions;

export default slice;
