import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sleep } from "../../../common/utils";
import {
  DashboardDeviceStatusType,
  getDeviceStatusList,
} from "../../../api/dashboard/dashboard";
import { RootState } from "../../index";

export type Dashboard = { id: number; dashboardName: string };

type DashboardListState = {
  loading: boolean;
  error?: string | Error;
  setting: {
    editMenu: string;
    visible: boolean;
  };
  deviceList: DashboardDeviceStatusType[];
  dashboardTabList: Dashboard[];
  currentDashboard?: number;
  refresh: boolean;
};

const initialState: DashboardListState = {
  loading: false,
  deviceList: [],
  setting: {
    editMenu: "",
    visible: false,
  },
  dashboardTabList: [],
  currentDashboard: undefined,
  refresh: false,
};

export const fetchDashboardDeviceStatusList = createAsyncThunk(
  "dashboard/main",
  async (dashboardId: number) => {
    await sleep(100);
    const { result } = await getDeviceStatusList(dashboardId);
    return result;
  }
);

const slice = createSlice({
  name: "dashboard/main",
  initialState,
  reducers: {
    resetDashboard: (state: DashboardListState) => ({
      ...state,
      loading: initialState.loading,
      deviceList: initialState.deviceList,
      setting: initialState.setting,
      dashboardTabList: initialState.dashboardTabList,
      currentDashboard: initialState.currentDashboard,
      refresh: initialState.refresh,
    }),
    updateDeviceStatus: (
      state: DashboardListState,
      { payload: { deviceId, status, time } }
    ) => {
      state.deviceList = state.deviceList.map(
        (device: DashboardDeviceStatusType) => {
          if (
            device.device_id === deviceId ||
            device.device_id?.indexOf(deviceId) !== -1
          ) {
            return {
              ...device,
              status: { ...device.status, ...status },
              time,
            };
          }
          return device;
        }
      );
    },
    updateCurrentDashboard: (state: DashboardListState, { payload }) => ({
      ...state,
      currentDashboard: payload,
    }),
    updateDashboardTabList: (state: DashboardListState, { payload }) => ({
      ...state,
      dashboardTabList: payload,
    }),
    addDashboardTabList: (state: DashboardListState, { payload }) => ({
      ...state,
      dashboardTabList: [...state.dashboardTabList, payload],
    }),
    resetDashboardDeviceList: (state: DashboardListState) => ({
      ...state,
      deviceList: initialState.deviceList,
      setting: initialState.setting,
    }),
    updateSettingEditMenu: (state: DashboardListState, { payload }) => ({
      ...state,
      setting: {
        ...state.setting,
        editMenu: payload,
      },
    }),
    updateSettingVisible: (state: DashboardListState, { payload }) => ({
      ...state,
      setting: {
        ...state.setting,
        visible: payload,
      },
    }),
    renameDashboardTab: (
      state: DashboardListState,
      { payload: { id, name } }
    ) => ({
      ...state,
      dashboardTabList: state.dashboardTabList.map((dashboardTab) => {
        if (dashboardTab.id === id) {
          return {
            ...dashboardTab,
            dashboardName: name,
          };
        }
        return dashboardTab;
      }),
    }),
    removeDashboardTab: (state: DashboardListState, { payload }) => ({
      ...state,
      dashboardTabList: state.dashboardTabList.filter(
        (dashboardTab) => dashboardTab.id !== payload
      ),
    }),
    updateDashboardRefresh: (state: DashboardListState, { payload }) => ({
      ...state,
      refresh: payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchDashboardDeviceStatusList.pending,
      (state: DashboardListState) => {
        state.loading = true;
      }
    );
    builder.addCase(
      fetchDashboardDeviceStatusList.fulfilled,
      (state: DashboardListState, { payload }) => {
        state.loading = false;
        state.error = undefined;
        state.deviceList = payload;
      }
    );
    builder.addCase(
      fetchDashboardDeviceStatusList.rejected,
      (state: DashboardListState, { error }) => {
        state.loading = false;
        state.error = error.message as string;
        state.deviceList = [];
      }
    );
  },
});

export const dashboardSelector = (state: RootState) => state.dashboard.list;
export const {
  updateDeviceStatus,
  resetDashboardDeviceList,
  updateCurrentDashboard,
  updateDashboardTabList,
  updateSettingVisible,
  updateSettingEditMenu,
  addDashboardTabList,
  renameDashboardTab,
  removeDashboardTab,
  resetDashboard,
  updateDashboardRefresh,
} = slice.actions;

export default slice;
