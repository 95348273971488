import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sleep } from "../../../common/utils";
import { RootState } from "../../index";
import { DeviceType, getDeviceListInSpace } from "../../../api/space/space";

type DeviceListInSpaceState = {
  loading: boolean;
  error?: string | Error;
  deviceList: DeviceType[];
};

const initialState: DeviceListInSpaceState = {
  loading: false,
  error: undefined,
  deviceList: [],
};

export const fetchGetDeviceListInSpace = createAsyncThunk(
  "space/deviceList",
  async ({ spaceId, type }: { spaceId: number; type?: string }) => {
    await sleep(100);
    const { result } = await getDeviceListInSpace(spaceId, type);
    return result;
  }
);

const slice = createSlice({
  name: "space/deviceList",
  initialState,
  reducers: {
    resetDeviceListInSpace: (state) => ({
      ...state,
      deviceList: initialState.deviceList,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetDeviceListInSpace.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(
      fetchGetDeviceListInSpace.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = undefined;
        state.deviceList = payload;
      }
    );
    builder.addCase(fetchGetDeviceListInSpace.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    });
  },
});

export const deviceListInSpaceSelector = (state: RootState) =>
  state.space.deviceList;
export const { resetDeviceListInSpace } = slice.actions;

export default slice;
