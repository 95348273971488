import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../index";
import { sleep } from "../../../common/utils";
import { getCctvEvents } from "../../../api/cctv/cctv";

export type CctvEventType = {
  id: string;
  durationMs: string;
  startTime: string;
  endTime: string;
  playStartTime: string;
  thumbnailUrl: string;
  eventZones: {
    zoneId: string;
    zoneName: string;
    eventType: string;
    filtered: boolean;
  }[];
}[];

type CctvEventState = {
  loading: boolean;
  error?: string | Error;
  cctvEvents: {
    message: string;
    result: {
      events: CctvEventType;
    };
  };
};

const initialState: CctvEventState = {
  loading: false,
  error: undefined,
  cctvEvents: {
    message: "",
    result: {
      events: [],
    },
  },
};

export const fetchGetCctvEvents = createAsyncThunk(
  "cctv/events",
  async ({
    id,
    start,
    end,
    scale,
  }: {
    id: number;
    start: string;
    end: string;
    scale?: string;
  }) => {
    await sleep(100);
    const { data } = await getCctvEvents(id, start, end, scale);
    return data;
  }
);

const slice = createSlice({
  name: "cctv/events",
  initialState,
  reducers: {
    resetCctvEvents: (state) => ({
      ...state,
      cctvEvents: initialState.cctvEvents,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetCctvEvents.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(fetchGetCctvEvents.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = undefined;
      state.cctvEvents = payload;
    });
    builder.addCase(fetchGetCctvEvents.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    });
  },
});

export const cctvEventsSelector = (state: RootState) => state.cctv.event;
export const { resetCctvEvents } = slice.actions;

export default slice;
