import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../index";

export type CameraType = {
  id: number;
  name: string;
};

type CameraListState = {
  list: CameraType[];
  selectedCamera?: CameraType;
};

const initialState: CameraListState = {
  list: [
    // dummy data
    {
      id: 1,
      name: "토스트캠 01",
    },
    {
      id: 2,
      name: "토스트캠 02",
    },
    {
      id: 3,
      name: "토스트캠 03",
    },
    {
      id: 4,
      name: "토스트캠 04",
    },
    {
      id: 5,
      name: "토스트캠 05",
    },
    {
      id: 6,
      name: "토스트캠 06",
    },
    {
      id: 7,
      name: "토스트캠 07",
    },
  ],
  selectedCamera: undefined,
};

const slice = createSlice({
  name: "setting/Toastcam/camera",
  initialState,
  reducers: {
    resetCameraList: (state: CameraListState) => ({
      ...state,
      list: initialState.list,
      selectedCamera: initialState.selectedCamera,
    }),
    updateSelectedCamera: (
      state: CameraListState,
      { payload: { id, name } }
    ) => ({
      ...state,
      selectedCamera: {
        ...state.selectedCamera,
        id,
        name,
      },
    }),
  },
});

export const settingCameraListSelector = (state: RootState) =>
  state.setting.toastcam.cameraList;

export const { resetCameraList, updateSelectedCamera } = slice.actions;

export default slice;
