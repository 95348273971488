import React from "react";
import { Button } from "antd";
import { openUpdateModal, updateSpaceSelector } from "../../store/space/update";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";
import { spaceDetailSelector } from "../../store/space/detail";
import {
  createSpaceSelector,
  openRegisterModal,
} from "../../store/space/register";
import { updateSpaceRemoveVisible } from "../../store/space/remove";
import { resetSpaceDeviceSelectedList } from "../../store/space/device-setting/list";

function LayoutSpaceSettingPopover() {
  const dispatch = useDispatch<AppDispatch>();
  const { modal: updateModalOpen } = useSelector(updateSpaceSelector);
  const { space, currentSpaceId } = useSelector(spaceDetailSelector);
  const { modalOpen: registerModalOpen } = useSelector(createSpaceSelector);

  const onClick = (mode: string) => () => {
    switch (mode) {
      case "register":
        dispatch(openRegisterModal(!registerModalOpen));
        dispatch(resetSpaceDeviceSelectedList());
        break;
      case "update":
        dispatch(
          openUpdateModal({
            open: !updateModalOpen.open,
            originalName: space && space.name,
            updateSpaceId: currentSpaceId,
          })
        );
        dispatch(resetSpaceDeviceSelectedList());
        break;
      case "move":
        dispatch(updateSpaceRemoveVisible(true));
        dispatch(resetSpaceDeviceSelectedList());
        break;
    }
  };

  return (
    <div className="layout-setting-list">
      <Button onClick={onClick("register")}>
        <img
          src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/new_space.png`}
          alt="new_space"
        />
        새 장소 추가
      </Button>
      <Button onClick={onClick("update")}>
        <img
          src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/edit_black.png`}
          alt="edit_black"
        />
        장소 수정
      </Button>
      <Button onClick={onClick("move")}>
        <img
          src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/trash_red.png`}
          alt="edit_black"
        />
        장소 이관 및 삭제
      </Button>
    </div>
  );
}

export default LayoutSpaceSettingPopover;
