import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { joinSelector } from "../../../store/join";
import { Button } from "antd";

function JoinStep6() {
  const { form } = useSelector(joinSelector);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !form.terms1 ||
      !form.terms2 ||
      !form.terms3 ||
      !form.email ||
      !form.certificationNumber ||
      !form.password2
    )
      navigate("/login/join?step=1");
  }, []);

  const onNextClick = () => {
    navigate(`/login`);
  };

  return (
    <div>
      <div className="join-footer">
        <Button type="primary" onClick={onNextClick}>
          로그인 하기
        </Button>
      </div>
    </div>
  );
}

export default JoinStep6;
