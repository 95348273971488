import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sleep } from "../../../common/utils";
import { RootState } from "../../index";
import { getPosList } from "../../../api/pos/pos";

type AirPos = {
  id: string;
  device_name: string;
};

type PosList = {
  loading: boolean;
  error?: string | Error;
  posList: AirPos[];
  selectedPosId: string;
};

const initialState: PosList = {
  loading: false,
  error: undefined,
  posList: [],
  selectedPosId: "",
};

export const fetchGetPosList = createAsyncThunk(
  "pos/list",
  async ({ spaceId }: { spaceId: number | undefined }) => {
    await sleep(100);
    const { result } = await getPosList(spaceId);
    return result;
  }
);

const slice = createSlice({
  name: "pos/list",
  initialState,
  reducers: {
    resetPosList: (state) => ({
      ...state,
      posList: initialState.posList,
      loading: initialState.loading,
    }),
    selectPosId: (state, { payload: { posId } }) => ({
      ...state,
      selectedPosId: posId,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetPosList.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(fetchGetPosList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = undefined;
      state.posList = payload;
    });
    builder.addCase(fetchGetPosList.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    });
  },
});

export const posListSelector = (state: RootState) => state.pos.list;
export const { resetPosList, selectPosId } = slice.actions;

export default slice;
