import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sleep } from "../../../common/utils";
import { ActionType, RootState } from "../../index";
import { createDashboard } from "../../../api/dashboard-manage/dashboard-manage";

type CreateDashboardState = {
  loading: boolean;
  error?: string | Error;
  success: boolean | undefined;
  modalOpen: boolean;
};

const initialState: CreateDashboardState = {
  loading: false,
  error: undefined,
  success: undefined,
  modalOpen: false,
};

type createDashboardRequestType = {
  name: string;
};

export const fetchCreateDashboard = createAsyncThunk(
  "dashboardManage/register",
  async (body: createDashboardRequestType) => {
    await sleep(100);
    const { result } = await createDashboard(body);
    return result;
  }
);

const slice = createSlice({
  name: "dashboardManage/register",
  initialState,
  reducers: {
    resetDashboardRegister: (state) => ({
      ...state,
      success: initialState.success,
      modalOpen: initialState.modalOpen,
    }),
    openRegisterModal: (
      state: CreateDashboardState,
      action: ActionType<CreateDashboardState["modalOpen"]>
    ) => ({
      ...state,
      modalOpen: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCreateDashboard.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(fetchCreateDashboard.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = undefined;
      state.success = payload;
    });
    builder.addCase(fetchCreateDashboard.rejected, (state, { error }) => {
      state.loading = false;
      state.success = false;
      state.error = error.message;
    });
  },
});

export const createDashboardSelector = (state: RootState) =>
  state.dashboardManage.register;
export const { resetDashboardRegister, openRegisterModal } = slice.actions;

export default slice;
