import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { resetPasswordSelector } from "../../../store/reset-password";
import { Button } from "antd";

function ResetPasswordStep4() {
  const { success } = useSelector(resetPasswordSelector);
  const navigate = useNavigate();
  const onNextClick = useCallback(async () => {
    success && navigate(`/login`);
  }, [success]);

  return (
    <div>
      <div className="reset-password-footer">
        <Button type="primary" onClick={onNextClick}>
          로그인 하기
        </Button>
      </div>
    </div>
  );
}

export default ResetPasswordStep4;
