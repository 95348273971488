import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sleep } from "../../../common/utils";
import { RootState } from "../../index";
import { findAllDeviceListByRecipeType } from "../../../api/recipe/recipe";
import { DeviceType } from "../../../api/space/space";

type AllDeviceListActionState = {
  loading: boolean;
  error?: string | Error;
  deviceList: DeviceType[];
};

const initialState: AllDeviceListActionState = {
  loading: false,
  error: undefined,
  deviceList: [],
};

export const fetchGetAllDeviceListAction = createAsyncThunk(
  "recipe/allDeviceListAction",
  async () => {
    await sleep(100);
    const { result } = await findAllDeviceListByRecipeType("action");
    return result;
  }
);

const slice = createSlice({
  name: "recipe/allDeviceListAction",
  initialState,
  reducers: {
    resetAllDeviceList: (state) => ({
      ...state,
      deviceList: initialState.deviceList,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchGetAllDeviceListAction.pending,
      (state: AllDeviceListActionState) => {
        state.loading = true;
        state.error = undefined;
      }
    );
    builder.addCase(
      fetchGetAllDeviceListAction.fulfilled,
      (state: AllDeviceListActionState, { payload }) => {
        state.loading = false;
        state.error = undefined;
        state.deviceList = payload;
      }
    );
    builder.addCase(
      fetchGetAllDeviceListAction.rejected,
      (state: AllDeviceListActionState, { error }) => {
        state.loading = false;
        state.error = error.message;
      }
    );
  },
});

export const allDeviceListActionSelector = (state: RootState) =>
  state.recipe.allDeviceListAction;
export const { resetAllDeviceList } = slice.actions;

export default slice;
