import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  ChangeEvent,
} from "react";
import Color from "color";
import { useSelector, useDispatch } from "react-redux";
import { Button, Input, InputNumber, Slider, Switch } from "antd";

import { fetchDeviceControl } from "../../store/panel/control";
import { AppDispatch } from "../../store";
import { regex } from "../../utils/panelFunction";
import { DeviceDetailType } from "../../api/devices/device";
import { panelDetailSelector } from "../../store/panel/detail";
import "../../assets/scss/panels/light.scss";
import { kelvin_to_rgb } from "../../common/utils";

const LightPanel = ({ action, token }: { action: any; token?: string }) => {
  const { detail } = useSelector(panelDetailSelector);
  const { id, status, deviceType, deviceName, spaceName } =
    detail as DeviceDetailType;
  const [brightness, setBrightness] = useState(
    deviceType === "lightWw"
      ? status?.brightness
        ? status.brightness
        : 0
      : status?.lightMode === "colour"
      ? status?.brightness
      : status?.hsvColor?.value
  );
  const [temperature, setTemperature] = useState(
    status?.temperature ? status.temperature : 0
  );
  const [hue, setHue] = useState(
    status?.hsvColor?.hue ? (status.hsvColor?.hue / 360) * 100 : 0
  );
  const [saturation, setSaturation] = useState(
    status?.hsvColor?.saturation ? status.hsvColor?.saturation : 0
  );

  const dispatch = useDispatch<AppDispatch>();

  const previewRef = useRef<HTMLElement>();
  const saturationRef = useRef<HTMLElement>();
  const colorValue = useMemo(() => {
    if (status)
      return Color({
        h: status.hsvColor?.hue,
        s: status.hsvColor?.saturation,
        v: status.hsvColor?.value,
      });
  }, [
    status,
    status?.hsvColor,
    status?.hsvColor?.hue,
    status?.hsvColor?.saturation,
    status?.hsvColor?.value,
  ]);
  const saturationLeastValue = useMemo(() => {
    if (status)
      return Color({
        h: status.hsvColor?.hue,
        s: 0,
        v: status.hsvColor?.value,
      });
  }, [
    status,
    status?.hsvColor,
    status?.hsvColor?.hue,
    status?.hsvColor?.saturation,
    status?.hsvColor?.value,
  ]);
  const saturationMaxValue = useMemo(() => {
    if (status)
      return Color({
        h: status.hsvColor?.hue,
        s: 100,
        v: status.hsvColor?.value,
      });
  }, [
    status,
    status?.hsvColor,
    status?.hsvColor?.hue,
    status?.hsvColor?.saturation,
    status?.hsvColor?.value,
  ]);
  const lightTemperature = useMemo(() => {
    return Math.floor(((temperature as number) * 35) / 100 + 30) * 100;
  }, [temperature]);
  const lightTemperatureRgb = useMemo(() => {
    return Color(kelvin_to_rgb(lightTemperature)).hsl().toString();
  }, [temperature, lightTemperature]);

  const [hex, setHex] = useState(colorValue?.hex());
  const [recentHex, setRecentHex] = useState(colorValue?.hex());

  useEffect(() => {
    if (colorValue && status && saturationMaxValue && saturationLeastValue) {
      if (previewRef.current) {
        deviceType === "lightWw"
          ? (previewRef.current.style.background = lightTemperatureRgb)
          : (previewRef.current.style.background = colorValue.hsl().toString());
      }
      if (saturationRef.current) {
        saturationRef.current.style.background = `linear-gradient(270deg, ${saturationMaxValue.hex()} 0.15%, ${saturationLeastValue.hex()} 92.72%)`;
      }
      deviceType === "lightWw"
        ? setBrightness(status.brightness)
        : status.lightMode === "colour"
        ? setBrightness(status.hsvColor?.value)
        : setBrightness(status.brightness);
      setTemperature(status.temperature);
      setHue((status.hsvColor?.hue / 360) * 100);
      setSaturation(status.hsvColor?.saturation);
      setHex(colorValue.hex());
      setRecentHex(colorValue.hex());
    }
  }, [
    status,
    status?.hsvColor,
    status?.hsvColor?.hue,
    status?.hsvColor?.saturation,
    status?.hsvColor?.value,
  ]);

  const handleLightPower = useCallback(() => {
    if (id && status)
      dispatch(
        fetchDeviceControl({
          id,
          body: { power: !status.power },
          token,
        })
      );
  }, [dispatch, status, id]);

  const onChange = (value: number, type: string) => {
    switch (type) {
      case "brightness":
        setBrightness(value);
        break;
      case "temperature":
        setTemperature(value);
        break;
      case "hue":
        setHue(value);
        break;
      case "saturation":
        setSaturation(value);
        break;
      case "value":
        setBrightness(value);
    }
  };

  const onAfterChange = (value: number, type: string) => {
    if (id && status)
      switch (type) {
        case "brightness":
          dispatch(
            fetchDeviceControl({
              id,
              token,
              body: { brightness: value },
            })
          );
          break;
        case "temperature":
          dispatch(
            fetchDeviceControl({
              id,
              token,
              body: { temperature: value },
            })
          );
          break;
        case "hue":
          dispatch(
            fetchDeviceControl({
              id,
              token,
              body: {
                hsvColor: {
                  hue: Math.round((value / 100) * 360),
                  saturation,
                  brightness,
                },
              },
            })
          );
          break;
        case "saturation":
          dispatch(
            fetchDeviceControl({
              id,
              token,
              body: {
                hsvColor: {
                  hue: Math.round((hue / 100) * 360),
                  saturation: value,
                  brightness,
                },
              },
            })
          );
          break;
        case "value":
          status.lightMode === "colour"
            ? dispatch(
                fetchDeviceControl({
                  id,
                  token,
                  body: {
                    hsvColor: {
                      hue: Math.round((hue / 100) * 360),
                      saturation,
                      brightness: value,
                    },
                  },
                })
              )
            : dispatch(
                fetchDeviceControl({
                  id,
                  token,
                  body: {
                    brightness: value,
                  },
                })
              );
          break;
      }
  };

  const onChangeInputNumber = (value: number | null, type: string) => {
    if (value === null) return;
    switch (type) {
      case "brightness":
        setBrightness(value);
        break;
      case "temperature":
        if (value <= 6500 || value >= 3000)
          setTemperature(Math.round(((value / 100 - 30) * 100) / 35));
        break;
      case "hue":
        setHue(value);
        break;
      case "saturation":
        setSaturation(value);
        break;
      case "value":
        setBrightness(value);
        break;
    }
  };

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    let newValue = e.currentTarget.value;
    setHex(newValue);
    if (
      (newValue.length === 7 && !regex.test(newValue)) ||
      newValue.length > 7
    ) {
      newValue = recentHex as string;
      setHex(newValue);
    }
  };

  const onClickLightMode = () => {
    if (id && status)
      dispatch(
        fetchDeviceControl({
          id,
          token,
          body: {
            lightMode: status.lightMode === "white" ? "colour" : "white",
          },
        })
      );
  };

  const handleInputCommand = (
    event:
      | React.KeyboardEvent<HTMLInputElement>
      | React.FocusEvent<HTMLInputElement>,
    type: string
  ) => {
    if (id && status && hex)
      switch (type) {
        case "brightness":
          dispatch(
            fetchDeviceControl({
              id,
              token,
              body: { brightness },
            })
          );
          break;
        case "temperature":
          dispatch(
            fetchDeviceControl({
              id,
              token,
              body: { temperature },
            })
          );
          break;
        case "hex":
          if ((hex.length !== 7 && !regex.test(hex)) || hex.length < 7) {
            setHex(recentHex);
          } else {
            const color = Color(hex);
            dispatch(
              fetchDeviceControl({
                id,
                token,
                body: {
                  hsvColor: {
                    hue: Math.round(color.hue()),
                    saturation: Math.round(color.saturationv()),
                    brightness: Math.round(color.value()),
                  },
                },
              })
            );
          }
          break;
        case "saturation":
          dispatch(
            fetchDeviceControl({
              id,
              token,
              body: {
                hsvColor: {
                  hue: Math.round((hue / 100) * 360),
                  saturation: saturation,
                  brightness,
                },
              },
            })
          );
          break;
        case "value":
          status.lightMode === "colour"
            ? dispatch(
                fetchDeviceControl({
                  id,
                  token,
                  body: {
                    hsvColor: {
                      hue: Math.round((hue / 100) * 360),
                      saturation,
                      brightness,
                    },
                  },
                })
              )
            : dispatch(
                fetchDeviceControl({
                  id,
                  token,
                  body: {
                    brightness,
                  },
                })
              );
          break;
      }
  };

  return (
    <>
      <section className="device-setting-body">
        <div className="device-setting-top">
          <h1>{deviceName}</h1>
          <div className="device-setting-header">
            <div className="remain-battery">
              <p>{spaceName ? spaceName : "미지정"}</p>
            </div>
            <Switch
              onChange={handleLightPower}
              checked={status && status.power}
            />
          </div>
        </div>
        {(deviceType === "lightWw" ||
          (status && status.lightMode === "colour")) && (
          <div
            className={`preview ${deviceType === "lightWw" && "black"}`}
            ref={previewRef as React.RefObject<HTMLDivElement>}
          >
            <p>미리보기</p>
          </div>
        )}
        {deviceType === "lightWw" ? (
          <>
            <div className="light-control temperature">
              <div className="light-control-header">
                <h2>색온도</h2>
                <InputNumber
                  min={3000}
                  max={6500}
                  value={lightTemperature}
                  formatter={(value) => `${value}K`}
                  parser={(value) => Number(value!.replace("K", ""))}
                  onChange={(value) =>
                    onChangeInputNumber(value, "temperature")
                  }
                  onPressEnter={(event) =>
                    handleInputCommand(event, "temperature")
                  }
                  onBlur={(event) => handleInputCommand(event, "temperature")}
                />
              </div>
              <div className="light-control-footer">
                <Slider
                  value={temperature}
                  onChange={(value) => onChange(value, "temperature")}
                  onAfterChange={(value) => onAfterChange(value, "temperature")}
                  handleStyle={{ background: lightTemperatureRgb }}
                />
              </div>
            </div>
            <div className="light-control brightness">
              <div className="light-control-header">
                <h2>밝기</h2>
                <InputNumber
                  min={0}
                  max={100}
                  value={brightness}
                  formatter={(value) => `${value}%`}
                  parser={(value) => Number(value!.replace("%", ""))}
                  onChange={(value) => onChangeInputNumber(value, "brightness")}
                  onPressEnter={(event) =>
                    handleInputCommand(event, "brightness")
                  }
                  onBlur={(event) => handleInputCommand(event, "brightness")}
                />
              </div>
              <div className="light-control-footer">
                <Slider
                  value={brightness}
                  onChange={(value) => onChange(value, "brightness")}
                  onAfterChange={(value) => onAfterChange(value, "brightness")}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            {status && status.lightMode === "colour" && (
              <>
                <div className="light-control color">
                  <div className="light-control-header">
                    <h2>컬러</h2>
                    <Input
                      value={hex}
                      onChange={onChangeInput}
                      onPressEnter={(event) => handleInputCommand(event, "hex")}
                      onBlur={(event) => handleInputCommand(event, "hex")}
                    />
                  </div>
                  <div className="light-control-footer">
                    <Slider
                      value={hue}
                      onChange={(value) => onChange(value, "hue")}
                      onAfterChange={(value) => onAfterChange(value, "hue")}
                      handleStyle={{ background: colorValue?.hsl().toString() }}
                    />
                  </div>
                </div>
                <div className="light-control saturation">
                  <div className="light-control-header">
                    <h2>채도</h2>
                    <InputNumber
                      min={0}
                      max={100}
                      value={saturation}
                      formatter={(value) => `${value}%`}
                      parser={(value) => Number(value!.replace("%", ""))}
                      onChange={(value) =>
                        onChangeInputNumber(value, "saturation")
                      }
                      onPressEnter={(event) =>
                        handleInputCommand(event, "saturation")
                      }
                      onBlur={(event) =>
                        handleInputCommand(event, "saturation")
                      }
                    />
                  </div>
                  <div
                    className="light-control-footer"
                    ref={saturationRef as React.RefObject<HTMLDivElement>}
                  >
                    <Slider
                      value={saturation}
                      onChange={(value) => onChange(value, "saturation")}
                      onAfterChange={(value) =>
                        onAfterChange(value, "saturation")
                      }
                      handleStyle={{ background: colorValue?.hsl().toString() }}
                    />
                  </div>
                </div>
              </>
            )}
            <div className="light-control brightness">
              <div className="light-control-header">
                <h2>밝기</h2>
                <InputNumber
                  min={0}
                  max={100}
                  value={brightness}
                  formatter={(value) => `${value}%`}
                  parser={(value) => Number(value!.replace("%", ""))}
                  onChange={(value) => onChangeInputNumber(value, "value")}
                  onPressEnter={(event) => handleInputCommand(event, "value")}
                  onBlur={(event) => handleInputCommand(event, "value")}
                />
              </div>
              <div className="light-control-footer">
                <Slider
                  value={brightness}
                  onChange={(value) => onChange(value, "value")}
                  onAfterChange={(value) => onAfterChange(value, "value")}
                />
              </div>
            </div>
          </>
        )}
        {deviceType &&
          status &&
          deviceType.indexOf("Ww") === -1 &&
          status.power && (
            <Button className="alarm-setting-button" onClick={onClickLightMode}>
              <img
                src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/mode_switch_icon.png`}
                alt="mode_switch_icon"
              />
              {status.lightMode === "white" ? "컬러 모드" : "화이트 모드"}
            </Button>
          )}
      </section>
    </>
  );
};

export default LightPanel;
