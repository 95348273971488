import { apiGet, apiPost } from "../../common/api";

export type DeviceType = {
  id: number;
  deviceId?: string;
  deviceName: string;
  pid: string;
  deviceType?: string;
  spaceName?: string;
};

type SpaceListResponseType = {
  status: number;
  result: {
    id: number;
    name: string;
    address: string;
    lan: string;
    lat: string;
  }[];
};

type SpaceDetailResponseType = {
  status: number;
  result: {
    id: number;
    name: string;
    address: string;
    lat: number;
    lan: number;
    imageUrl: string;
  };
};

type NoSpaceDeviceListResponseType = {
  status: number;
  result: DeviceType[];
};

type createSpaceRequestType = {
  name: string;
};

type booleanResultResponseType = {
  status: number;
  result: boolean;
  message: string;
};

type updateSpaceRequestType = {
  name: string;
  lan: number;
  lat: number;
  address: string;
  spaceImageUrl: string;
};

type addDeviceInSpaceRequestType = {
  deviceId: number;
};

type getDeviceListInSpaceResponseType = {
  status: number;
  result: DeviceType[];
};

type deviceListRequestType = {
  deviceList: number[];
};

export const getSpaceList = async () => {
  try {
    const { data } = await apiGet(`/v1.0/openapi/space`);
    return data as SpaceListResponseType;
  } catch (e) {
    throw e;
  }
};

export const getSpaceDetail = async (spaceId: number) => {
  try {
    const { data } = await apiGet(`/v1.0/openapi/space/${spaceId}`);
    return data as SpaceDetailResponseType;
  } catch (e) {
    throw e;
  }
};

export const getNoSpaceDeviceList = async (type?: string) => {
  try {
    const { data } = await apiGet(
      type
        ? `/v1.0/openapi/nospace/devices?recipeType=${type}`
        : `/v1.0/openapi/nospace/devices`
    );
    return data as NoSpaceDeviceListResponseType;
  } catch (e) {
    throw e;
  }
};

export const createSpace = async (body: createSpaceRequestType) => {
  try {
    const { data } = await apiPost(`/v1.0/openapi/space`, body);
    return data as booleanResultResponseType;
  } catch (e) {
    throw e;
  }
};

export const editSpace = async (
  spaceId: number,
  body: updateSpaceRequestType
) => {
  try {
    const { data } = await apiPost(
      `/v1.0/openapi/space/${spaceId}/update`,
      body
    );
    return data as booleanResultResponseType;
  } catch (e) {
    throw e;
  }
};

export const addDeviceInSpace = async (
  spaceId: number,
  body: addDeviceInSpaceRequestType
) => {
  try {
    const { data } = await apiPost(
      `/v1.0/openapi/space/${spaceId}/device`,
      body
    );
    return data as booleanResultResponseType;
  } catch (e) {
    throw e;
  }
};

export const getDeviceListInSpace = async (spaceId: number, type?: string) => {
  try {
    const url = `/v1.0/openapi/space/${spaceId}/devices${
      type ? `?recipeType=${type}` : ""
    }`;
    const { data } = await apiGet(url);
    return data as getDeviceListInSpaceResponseType;
  } catch (e) {
    throw e;
  }
};

export const removeDeviceListInSpace = async (
  spaceId: number,
  body: deviceListRequestType
) => {
  try {
    const { data } = await apiPost(
      `/v1.0/openapi/space/${spaceId}/delete/devices`,
      body
    );
    return data as booleanResultResponseType;
  } catch (e) {
    throw e;
  }
};

export const moveDeviceListInSpace = async (
  spaceId: number,
  body: deviceListRequestType
) => {
  try {
    const { data } = await apiPost(
      `/v1.0/openapi/space/${spaceId}/update/devices`,
      body
    );
    return data as booleanResultResponseType;
  } catch (e) {
    throw e;
  }
};

export const removeSpace = async (spaceId: number, query?: string) => {
  try {
    const url = `/v1.0/openapi/space/${spaceId}/delete${
      query ? `?type=${query}` : ""
    }`;
    const { data } = await apiPost(url);
    return data as booleanResultResponseType;
  } catch (e) {
    throw e;
  }
};

export const removeNoSpaceDeviceList = async (body: deviceListRequestType) => {
  try {
    const { data } = await apiPost(`/v1.0/openapi/devices/delete`, body);
    return data as booleanResultResponseType;
  } catch (e) {
    throw e;
  }
};
