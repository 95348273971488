import { apiGet, apiPost } from "../../common/api";

type updatePasswordBody = {
  oldPassword: string;
  password: string;
};

export type UpdateUserName = {
  name: string;
};

type updateUserNameResponseType = {
  status: number;
  result: boolean;
  message: string;
};

type userPlatformsResponseType = {
  status: number;
  result: {
    id: string;
    user_info_id: number;
    sync_platform_id: string;
    uid: string;
    user_id: string;
    created_at: string;
    updated_at: string;
    syncPlatformInfo: {
      id: string;
      platform_type_code: string;
      app_schema: string;
      platform_name: string;
      package_name: string;
    };
  }[];
};

export const postUpdateUserName = async (name: string) => {
  try {
    const { data } = await apiPost(`/v1.0/openapi/user/update`, {
      name,
    });
    return data as updateUserNameResponseType;
  } catch (e) {
    throw e;
  }
};

export const postUpdatePassword = async (body: updatePasswordBody) => {
  try {
    const { data } = await apiPost(
      `/v1.0/openapi/user/update/password?updateType=reset`,
      body
    );
    return data as updateUserNameResponseType;
  } catch (e) {
    throw e;
  }
};

export const getUserPlatforms = async () => {
  try {
    const { data } = await apiGet(`/v1.0/openapi/user/platforms`);
    return data as userPlatformsResponseType;
  } catch (e) {
    throw e;
  }
};
