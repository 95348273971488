import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sleep } from "../../../common/utils";
import { RootState } from "../../index";
import { DeviceType, getNoSpaceDeviceList } from "../../../api/space/space";

type NoSpaceList = {
  loading: boolean;
  error?: string | Error;
  noSpaceDeviceList: DeviceType[];
};

const initialState: NoSpaceList = {
  loading: false,
  error: undefined,
  noSpaceDeviceList: [],
};

export const fetchGetNoSpaceListAction = createAsyncThunk(
  "recipe/noSpaceListAction",
  async () => {
    await sleep(100);
    const { result } = await getNoSpaceDeviceList("action");
    return result;
  }
);

const slice = createSlice({
  name: "recipe/noSpaceListAction",
  initialState,
  reducers: {
    resetNoSpaceDeviceListAction: (state) => ({
      ...state,
      noSpaceDeviceList: initialState.noSpaceDeviceList,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetNoSpaceListAction.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(
      fetchGetNoSpaceListAction.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = undefined;
        state.noSpaceDeviceList = payload;
      }
    );
    builder.addCase(fetchGetNoSpaceListAction.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    });
  },
});

export const noSpaceDeviceListActionSelector = (state: RootState) =>
  state.recipe.noSpaceDeviceListAction;
export const { resetNoSpaceDeviceListAction } = slice.actions;

export default slice;
