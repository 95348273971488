import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sleep } from "../../../../common/utils";
import { remoteWidgetDevice } from "../../../../api/dashboard/dashboard";
import { RootState } from "../../../index";

type DashboardControlState = {
  loading: boolean;
  error?: string | Error;
  success: boolean;
};

const initialState: DashboardControlState = {
  loading: false,
  success: false,
};

export const fetchControlDashboardWidget = createAsyncThunk(
  "dashboard/widget/control",
  async ({
    dashboardId,
    widgetId,
    body,
  }: {
    dashboardId: number;
    widgetId: number;
    body: {
      [key: string]: any;
    };
  }) => {
    await sleep(100);
    const { result } = await remoteWidgetDevice(dashboardId, widgetId, body);
    return result;
  }
);

const slice = createSlice({
  name: "dashboard/widget/control",
  initialState,
  reducers: {
    resetDashboardControl: (state) => ({
      ...state,
      success: initialState.success,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchControlDashboardWidget.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchControlDashboardWidget.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = undefined;
        state.success = payload;
      }
    );
    builder.addCase(
      fetchControlDashboardWidget.rejected,
      (state, { error }) => {
        state.loading = false;
        state.error = error.message as string;
        state.success = false;
      }
    );
  },
});

export const dashboardControlSelector = (state: RootState) =>
  state.dashboard.widget.control;
export const { resetDashboardControl } = slice.actions;

export default slice;
