import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../index";

type AirposLoginState = {
  visible: boolean;
};

const initialState: AirposLoginState = {
  visible: false,
};

const slice = createSlice({
  name: "setting/airpos/login",
  initialState,
  reducers: {
    resetAirposModalVisible: (state: AirposLoginState) => ({
      ...state,
      visible: initialState.visible,
    }),
    updateAirposModalVisible: (state: AirposLoginState, { payload }) => ({
      ...state,
      visible: payload,
    }),
  },
});

export const settingAirpostLoginSelector = (state: RootState) =>
  state.setting.airpos.login;

export const { resetAirposModalVisible } = slice.actions;

export default slice;
