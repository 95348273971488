import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../index";
import { sleep } from "../../../../common/utils";
import { postDisconnectNhnToastCam } from "../../../../api/nhn/nhn";

type ToastcamDeleteState = {
  visible: boolean;
  success: boolean;
  loading: boolean;
  error?: string | Error;
};

const initialState: ToastcamDeleteState = {
  visible: false,
  success: false,
  loading: false,
  error: undefined,
};

export const fetchDeleteToastCam = createAsyncThunk(
  "setting/Toastcam/delete",
  async (app_schema: string) => {
    await sleep(100);
    const { result } = await postDisconnectNhnToastCam(app_schema);
    return result;
  }
);

const slice = createSlice({
  name: "setting/Toastcam/delete",
  initialState,
  reducers: {
    resetToastcamDeleteModalVisible: (state: ToastcamDeleteState) => ({
      ...state,
      visible: initialState.visible,
      success: initialState.success,
      loading: initialState.loading,
      error: initialState.error,
    }),
    updateToastcamDeleteModal: (state: ToastcamDeleteState, { payload }) => ({
      ...state,
      visible: payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDeleteToastCam.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(fetchDeleteToastCam.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = undefined;
      state.success = payload;
    });
    builder.addCase(fetchDeleteToastCam.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message;
      state.success = false;
    });
  },
});

export const settingToastcamDeleteSelector = (state: RootState) =>
  state.setting.toastcam.delete;

export const { resetToastcamDeleteModalVisible, updateToastcamDeleteModal } =
  slice.actions;

export default slice;
