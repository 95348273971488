import React, { useEffect, useState } from "react";
import { Button, Col, InputNumber, Slider } from "antd";
import Battery from "../../assets/img/dashboard/battery";
import { useDispatch, useSelector } from "react-redux";
import { panelDetailSelector } from "../../store/panel/detail";
import { DeviceDetailType } from "../../api/devices/device";
import { fetchDeviceControl } from "../../store/panel/control";
import { AppDispatch } from "../../store";

const SensorThPanel = ({
  action,
  token,
  deviceType,
}: {
  action: any;
  token?: string;
  deviceType: string;
}) => {
  const { detail } = useSelector(panelDetailSelector);
  const { id, status, deviceName, spaceName } = detail as DeviceDetailType;
  const [minTemperature, setMinTemperature] = useState<number>(
    deviceType === "sensorRefTh" ? status?.miniTempSet : 0
  );
  const dispatch = useDispatch<AppDispatch>();
  const [maxTemperature, setMaxTemperature] = useState<number>(
    deviceType === "sensorRefTh" ? status?.maxTempSet : 0
  );
  const [minHumidity, setMinHumidity] = useState<number>(
    deviceType === "sensorRefTh" ? status?.miniHumSet : 0
  );
  const [maxHumidity, setMaxHumidity] = useState<number>(
    deviceType === "sensorRefTh" ? status?.maxHumSet : 0
  );

  useEffect(() => {
    setMinTemperature(status?.miniTempSet);
    setMaxTemperature(status?.maxTempSet);
    setMinHumidity(status?.miniHumSet);
    setMaxHumidity(status?.maxHumSet);
  }, [status]);

  const onChange = (value: number | null, type: string) => {
    switch (type) {
      case "minTemperature":
        setMinTemperature(Number(value));
        break;
      case "maxTemperature":
        setMaxTemperature(Number(value));
        break;
      case "minHumidity":
        setMinHumidity(Number(value));
        break;
      case "maxHumidity":
        setMaxHumidity(Number(value));
        break;
    }
  };

  const onChangeTemperature = (value: [number, number]) => {
    setMinTemperature(value[0]);
    setMaxTemperature(value[1]);
  };
  const onAfterChangeTemperature = (value: [number, number]) => {
    setMinTemperature(value[0]);
    setMaxTemperature(value[1]);
  };
  const onChangeHumidity = (value: [number, number]) => {
    setMinHumidity(value[0]);
    setMaxHumidity(value[1]);
  };
  const onAfterChangeHumidity = (value: [number, number]) => {
    setMinHumidity(value[0]);
    setMaxHumidity(value[1]);
  };

  const onClick = () => {
    if (id)
      dispatch(
        fetchDeviceControl({
          id,
          token,
          body: {
            miniTempSet: minTemperature,
            maxTempSet: maxTemperature,
            miniHumSet: minHumidity,
            maxHumSet: maxHumidity,
          },
        })
      );
  };

  const setRef = () => {
    return (
      <>
        <div className="ref-setting temperature">
          <h2>온도 경보 알람 설정</h2>
          <div className="input-container">
            <div className="input-item">
              <InputNumber
                min={-40}
                max={120}
                value={minTemperature}
                formatter={(value) => `${value}°C`}
                parser={(value) => Number(value!.replace("°C", ""))}
                onChange={(value) => onChange(value, "minTemperature")}
              />
              <p>이하</p>
            </div>
            <div className="input-item">
              <p>이상</p>
              <InputNumber
                min={-40}
                max={120}
                value={maxTemperature}
                formatter={(value) => `${value}°C`}
                parser={(value) => Number(value!.replace("°C", ""))}
                onChange={(value) => onChange(value, "maxTemperature")}
              />
            </div>
          </div>
          <div className="slider">
            <Col span={24}>
              <Slider
                range
                step={1}
                min={-40}
                max={120}
                value={[minTemperature, maxTemperature]}
                onChange={onChangeTemperature}
                onAfterChange={onAfterChangeTemperature}
              />
            </Col>
          </div>
        </div>
        <div className="ref-setting humidity">
          <h2>습도 경보 알람 설정</h2>
          <div className="input-container">
            <div className="input-item">
              <InputNumber
                min={0}
                max={100}
                value={minHumidity}
                formatter={(value) => `${value}%`}
                parser={(value) => Number(value!.replace("%", ""))}
                onChange={(value) => onChange(value, "minHumidity")}
              />
              <p>이하</p>
            </div>
            <div className="input-item">
              <p>이상</p>
              <InputNumber
                min={0}
                max={100}
                value={maxHumidity}
                formatter={(value) => `${value}%`}
                parser={(value) => Number(value!.replace("%", ""))}
                onChange={(value) => onChange(value, "minHumidity")}
              />
            </div>
          </div>
          <div className="slider">
            <Col span={24}>
              <Slider
                range
                step={1}
                min={0}
                max={100}
                value={[minHumidity, maxHumidity]}
                onChange={onChangeHumidity}
                onAfterChange={onAfterChangeHumidity}
              />
            </Col>
          </div>
        </div>
        <Button className="alarm-setting-button" onClick={onClick}>
          <img
            src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/check_icon.png`}
            alt="check_icon"
          />
          <p>설정 저장하기</p>
        </Button>
      </>
    );
  };

  return (
    <section className="device-setting-body alarm-wrap">
      <div className="device-setting-top">
        <h1>{deviceName}</h1>
        <div className="device-setting-header">
          <div className="remain-battery">
            <Battery batteryLevel={status?.battery} />
            <p>{spaceName ? spaceName : "미지정"}</p>
          </div>
        </div>
      </div>
      <div className={`device-display th`}>
        <h2>실시간 온습도</h2>
        <div className="display">
          <div className="temperature">
            <h1>{`${status && status.temperature}°C`}</h1>
            <p>온도</p>
          </div>
          <div className="humidity">
            <h1>{`${status && status.humidity}%`}</h1>
            <p>습도</p>
          </div>
        </div>
      </div>
      {deviceType === "sensorRefTh" && setRef()}
    </section>
  );
};

export default SensorThPanel;
