import { Button } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../store";
import {
  updateAirposModalVisible,
  updateAirposStep,
} from "../../../../../store/setting/airpos";
import { settingAirposListSelector } from "../../../../../store/setting/airpos/list";

function AirposComplete() {
  const { selectedPos, selectedSpace, selectedCamera, posNewName } =
    useSelector(settingAirposListSelector);
  const dispatch = useDispatch<AppDispatch>();

  return (
    <>
      <h1>POS/키오스크 연동 완료</h1>
      <p>기기 연동이 완료되었습니다.</p>
      <div className="pos-main">
        <div className="pos-detail">
          {/*<p>기기: 고퀄POS 5</p>*/}
          <p>기기: {selectedPos ? selectedPos.device_name : "-"}</p>
          <p>이름: {posNewName ? posNewName : "-"}</p>
          <p>장소: {selectedSpace ? selectedSpace.space_name : "-"}</p>
          <p>카메라: {selectedCamera ? selectedCamera.camera_name : "-"}</p>
        </div>
      </div>
      <div className="pos-footer">
        <Button
          disabled={!selectedPos}
          onClick={() => dispatch(updateAirposStep())}
          type="primary"
        >
          다음
        </Button>
        {selectedPos && (
          <Button onClick={() => dispatch(updateAirposModalVisible(false))}>
            닫기
          </Button>
        )}
      </div>
    </>
  );
}

export default AirposComplete;
