import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sleep } from "../../../common/utils";
import { RootState } from "../../index";
import {
  DeviceDetailType,
  getDeviceDetailByDeviceId,
} from "../../../api/devices/device";

type PanelDetailState = {
  loading: boolean;
  error?: string | Error;
  detail: DeviceDetailType | undefined;
  deviceId: number | undefined;
};

const initialState: PanelDetailState = {
  loading: false,
  error: undefined,
  detail: undefined,
  deviceId: undefined,
};

export const fetchGetDeviceDetail = createAsyncThunk(
  "panel/detail",
  async ({ id, token }: { id: number; token?: string }) => {
    await sleep(100);
    const { result } = await getDeviceDetailByDeviceId(id, token);
    return result;
  }
);

const slice = createSlice({
  name: "panel/detail",
  initialState,
  reducers: {
    resetDeviceDetail: (state: PanelDetailState) => ({
      ...state,
      detail: initialState.detail,
      deviceId: initialState.deviceId,
      loading: initialState.loading,
    }),
    updateDeviceId: (state: PanelDetailState, { payload }) => ({
      ...state,
      deviceId: payload,
    }),
    updatePanelDeviceStatus: (
      state: PanelDetailState,
      { payload: { status, time } }
    ) => ({
      ...state,
      detail: {
        ...state.detail,
        status: {
          ...state.detail?.status,
          ...status,
        },
        time,
      },
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetDeviceDetail.pending, (state: PanelDetailState) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(
      fetchGetDeviceDetail.fulfilled,
      (state: PanelDetailState, { payload }) => {
        state.loading = false;
        state.error = undefined;
        state.detail = {
          ...state.detail,
          ...payload,
        };
      }
    );
    builder.addCase(
      fetchGetDeviceDetail.rejected,
      (state: PanelDetailState, { error }) => {
        state.loading = false;
        state.error = error.message;
      }
    );
  },
});

export const panelDetailSelector = (state: RootState) => state.panel.detail;
export const { resetDeviceDetail, updatePanelDeviceStatus, updateDeviceId } =
  slice.actions;

export default slice;
