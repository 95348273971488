import React from "react";
import { Button, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../store";
import {
  settingToastcamDeleteSelector,
  resetToastcamDeleteModalVisible,
  fetchDeleteToastCam,
} from "../../../../store/setting/toascam/delete";

function ToastCamDeleteModal() {
  const { visible } = useSelector(settingToastcamDeleteSelector);
  const dispatch = useDispatch<AppDispatch>();
  const onCancel = () => {
    dispatch(resetToastcamDeleteModalVisible());
  };
  const onClickDelete = () => {
    dispatch(fetchDeleteToastCam("ToastCam"));
  };
  return (
    <Modal
      className="toastcam-delete-modal"
      visible={visible}
      onCancel={onCancel}
      footer={
        <>
          <Button onClick={onCancel}>취소</Button>
          <Button onClick={onClickDelete} danger type="primary">
            연동 해제
          </Button>
        </>
      }
    >
      <img
        src={`${process.env.REACT_APP_S3_URL}/1.0/logos/toastcam_logo.png`}
        alt="airpos_logo"
      />
      <h1>토스트캠 연동을 해제하시겠습니까?</h1>
      <p>계정 연동을 해제하시겠습니까?</p>
      <p>
        계정 연동을 해제하면 카메라 x대도 함께 연결이 해제되며, 클라우드에
        저장된 영상, 정보 및 자료 등이 삭제됩니다. 또한 토스트캠과 연동된
        기능들이 무효화되므로, 각별한 주의를 부탁드립니다.
      </p>
    </Modal>
  );
}

export default ToastCamDeleteModal;
