export function emailRegex(email: string) {
  const regex = new RegExp(
    /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i
  );
  return regex.test(email);
}

export function passwordRegex(password: string) {
  const regex =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/;
  return regex.test(password);
}

export function generateKey(pre: string) {
  return `${pre}_${new Date().getTime()}`;
}
