import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sleep } from "../../../../common/utils";
import {
  getWidgetCategoryDeviceList,
  categoryDeviceType,
} from "../../../../api/widget/widget";
import { RootState } from "../../../index";

type DeviceTypeInfoState = {
  loading: boolean;
  error?: string | Error;
  widgetList: categoryDeviceType[] | undefined;
};

const initialState: DeviceTypeInfoState = {
  loading: false,
  widgetList: undefined,
};

export const fetchGetWidgetCategoryDeviceList = createAsyncThunk(
  "dashboard/widget/deviceType",
  async () => {
    await sleep(100);
    const { result } = await getWidgetCategoryDeviceList();
    return result;
  }
);

const slice = createSlice({
  name: "dashboard/widget/categoryDeviceList",
  initialState,
  reducers: {
    resetDashboardRegister: (state) => ({
      ...state,
      widgetList: initialState.widgetList,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetWidgetCategoryDeviceList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchGetWidgetCategoryDeviceList.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = undefined;
        state.widgetList = payload;
      }
    );
    builder.addCase(
      fetchGetWidgetCategoryDeviceList.rejected,
      (state, { error }) => {
        state.loading = false;
        state.error = error.message as string;
        state.widgetList = [];
      }
    );
  },
});

export const widgetDeviceTypeSelector = (state: RootState) =>
  state.dashboard.widget.categoryDeviceList;
export const { resetDashboardRegister } = slice.actions;

export default slice;
