import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sleep } from "../../../common/utils";
import { RootState } from "../../index";
import { DeviceType, getNoSpaceDeviceList } from "../../../api/space/space";

type NoSpaceList = {
  loading: boolean;
  error?: string | Error;
  noSpaceDeviceList: DeviceType[];
};

const initialState: NoSpaceList = {
  loading: false,
  error: undefined,
  noSpaceDeviceList: [],
};

export const fetchGetNoSpaceListCondition = createAsyncThunk(
  "recipe/noSpaceListCondition",
  async () => {
    await sleep(100);
    const { result } = await getNoSpaceDeviceList("condition");
    return result;
  }
);

const slice = createSlice({
  name: "recipe/noSpaceListCondition",
  initialState,
  reducers: {
    resetNoSpaceDeviceListCondition: (state) => ({
      ...state,
      noSpaceDeviceList: initialState.noSpaceDeviceList,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetNoSpaceListCondition.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(
      fetchGetNoSpaceListCondition.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = undefined;
        state.noSpaceDeviceList = payload;
      }
    );
    builder.addCase(
      fetchGetNoSpaceListCondition.rejected,
      (state, { error }) => {
        state.loading = false;
        state.error = error.message;
      }
    );
  },
});

export const noSpaceDeviceListConditionSelector = (state: RootState) =>
  state.recipe.noSpaceDeviceListCondition;
export const { resetNoSpaceDeviceListCondition } = slice.actions;

export default slice;
