import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sleep } from "../../../common/utils";
import { RootState } from "../../index";
import {
  getRemoteListByDeviceId,
  RemoteType,
} from "../../../api/devices/device";

type RemoteListState = {
  loading: boolean;
  error?: string | Error;
  list: RemoteType[];
};

const initialState: RemoteListState = {
  loading: false,
  error: undefined,
  list: [],
};

export const fetchGetRemoteDeviceList = createAsyncThunk(
  "panel/remoteList",
  async ({ id, token }: { id: number; token?: string }) => {
    await sleep(100);
    const { result } = await getRemoteListByDeviceId(id, token);
    return result;
  }
);

const slice = createSlice({
  name: "panel/remoteList",
  initialState,
  reducers: {
    resetRemoteList: (state: RemoteListState) => ({
      ...state,
      loading: initialState.loading,
      list: initialState.list,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchGetRemoteDeviceList.pending,
      (state: RemoteListState) => {
        state.loading = true;
        state.error = undefined;
      }
    );
    builder.addCase(
      fetchGetRemoteDeviceList.fulfilled,
      (state: RemoteListState, { payload }) => {
        state.loading = false;
        state.error = undefined;
        state.list = payload;
      }
    );
    builder.addCase(
      fetchGetRemoteDeviceList.rejected,
      (state: RemoteListState, { error }) => {
        state.loading = false;
        state.error = error.message;
      }
    );
  },
});

export const remoteListSelector = (state: RootState) => state.panel.remoteList;
export const { resetRemoteList } = slice.actions;

export default slice;
