import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sleep } from "../../../common/utils";
import { RootState } from "../../index";
import { findAllDeviceListByRecipeType } from "../../../api/recipe/recipe";
import { DeviceType } from "../../../api/space/space";

type AllDeviceListConditionState = {
  loading: boolean;
  error?: string | Error;
  deviceList: DeviceType[];
};

const initialState: AllDeviceListConditionState = {
  loading: false,
  error: undefined,
  deviceList: [],
};

export const fetchGetAllDeviceListCondition = createAsyncThunk(
  "recipe/allDeviceListCondition",
  async () => {
    await sleep(100);
    const { result } = await findAllDeviceListByRecipeType("condition");
    return result;
  }
);

const slice = createSlice({
  name: "recipe/allDeviceListCondition",
  initialState,
  reducers: {
    resetAllDeviceList: (state) => ({
      ...state,
      deviceList: initialState.deviceList,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchGetAllDeviceListCondition.pending,
      (state: AllDeviceListConditionState) => {
        state.loading = true;
        state.error = undefined;
      }
    );
    builder.addCase(
      fetchGetAllDeviceListCondition.fulfilled,
      (state: AllDeviceListConditionState, { payload }) => {
        state.loading = false;
        state.error = undefined;
        state.deviceList = payload;
      }
    );
    builder.addCase(
      fetchGetAllDeviceListCondition.rejected,
      (state: AllDeviceListConditionState, { error }) => {
        state.loading = false;
        state.error = error.message;
      }
    );
  },
});

export const allDeviceListConditionSelector = (state: RootState) =>
  state.recipe.allDeviceListCondition;
export const { resetAllDeviceList } = slice.actions;

export default slice;
