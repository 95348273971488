import { airPosGet, airPosPost, apiGet, apiPost } from "../../common/api";

type toastCamAccountCheckResult = {
  result: string;
};

type toastCamList = {
  status: number;
  result: {
    id: string;
    device_id: string;
    pid: string;
    device_name: string;
  }[];
};

export const postAccountCheck = async (
  bizUserId: string,
  bizUserPassword: string
) => {
  try {
    const body = { bizUserId: bizUserId, bizUserPassword: bizUserPassword };
    const { data } = await apiPost(
      `/v1.0/openapi/toastcam/account/check`,
      body
    );
    return data as toastCamAccountCheckResult;
  } catch (e) {
    throw e;
  }
};

type booleanResult = {
  result: boolean;
};

export const getNhnToastCamList = async (body: {
  bizUserId: string;
  bizUserPassword: string;
}) => {
  try {
    const { data } = await apiPost(`/v1.0/openapi/toastcam`, body);
    return data as toastCamList;
  } catch (e) {
    throw e;
  }
};

export const getNhnToastCamListNoSpace = async () => {
  try {
    const { data } = await apiGet(`/v1.0/openapi/toastcam/nospaces`);
    return data as toastCamList;
  } catch (e) {
    throw e;
  }
};

export const postDisconnectNhnToastCam = async (appSchema: string) => {
  try {
    const body = { appSchema: appSchema };
    const { data } = await apiPost(`/v1.0/openapi/toastcam/disconnect`, body);
    return data as booleanResult;
  } catch (e) {
    throw e;
  }
};
