import React, { useEffect } from "react";
import AppRoute from "./AppRoute";
import NavigationMenus from "./layouts/navigation/NavigationMenus";
import { Outlet, useNavigate } from "react-router-dom";
import { Layout } from "antd";
import SettingModal from "./components/setting/SettingModal";

import { settingSelector } from "./store/setting";
import { useSelector } from "react-redux";
const { Sider, Content, Footer } = Layout;

function AppContainer() {
  const token = localStorage.getItem("accessToken");
  const navigate = useNavigate();

  useEffect(() => {
    !token && navigate("/login");
  }, [token]);
  const { openSettingModal } = useSelector(settingSelector);
  return (
    <Layout className="" style={{ minHeight: "100%" }}>
      <Layout>
        <Sider trigger={null} collapsible className="layout">
          <NavigationMenus />
        </Sider>
        <Layout className="site-layout">
          <Content>
            <AppRoute />
          </Content>
          <Footer style={{ textAlign: "center" }}>
            Hejhome Square ©2022 Created by GOQUAL DEVELOPMENT TEAM
          </Footer>
        </Layout>
      </Layout>
      <Outlet />
      {openSettingModal ? <SettingModal /> : <></>}
    </Layout>
  );
}

export default AppContainer;
