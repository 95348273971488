import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { panelDetailSelector } from "../../store/panel/detail";
import { DeviceDetailType } from "../../api/devices/device";
import { Button, Skeleton } from "antd";
import { AppDispatch } from "../../store";
import {
  fetchGetRemoteDeviceList,
  remoteListSelector,
  resetRemoteList,
} from "../../store/panel/remote-list";
import RemoteDeviceContent from "./remote-hub/RemoteDeviceContent";
import {
  deviceUpdateNameSelector,
  resetUpdateDeviceName,
} from "../../store/update-device-name";
import {
  fetchGetCategoryList,
  resetCategoryList,
  updateRemoteAddModal,
} from "../../store/panel/category-list";
import RemoteDeviceRegisterDrawer from "./remote-hub/RemoteDeviceRegisterDrawer";
import RemoteDeviceRegisterModal from "./remote-hub/RemoteDeviceRegisterModal";
import {
  registerRemoteDeviceSelector,
  resetRegisterRemoteDevice,
} from "../../store/panel/add-remote-device";

const RemoteHubPanel = ({
  action,
  token,
  app,
}: {
  action: any;
  token?: string;
  app?: string;
}) => {
  const { detail } = useSelector(panelDetailSelector);
  const { id, deviceName, spaceName } = detail as DeviceDetailType;
  const { success } = useSelector(deviceUpdateNameSelector);
  const { success: registerSuccess } = useSelector(
    registerRemoteDeviceSelector
  );
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    return () => {
      dispatch(resetRemoteList());
      dispatch(resetCategoryList());
    };
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(fetchGetRemoteDeviceList({ id, token }));
    } else if (id && success) {
      dispatch(fetchGetRemoteDeviceList({ id, token }));
      dispatch(resetUpdateDeviceName());
    }
  }, [id, success]);

  useEffect(() => {
    if (id && registerSuccess) {
      dispatch(resetCategoryList());
      dispatch(resetRegisterRemoteDevice());
      dispatch(fetchGetRemoteDeviceList({ id, token }));
    }
  }, [registerSuccess]);

  const { loading, list } = useSelector(remoteListSelector);
  const onClickModal = () => {
    dispatch(updateRemoteAddModal(true));
    id && dispatch(fetchGetCategoryList({ id, token }));
  };
  return (
    <section key={id + "remoteHub"} className="device-setting-body hub-wrap">
      <div className="device-setting-top">
        <h1>{deviceName}</h1>
        <div className="device-setting-header">
          <div className="remain-battery">
            <p>{spaceName ? spaceName : "미지정"}</p>
          </div>
        </div>
      </div>
      <div className="device-connect-header">
        <p>연결된 제품</p>
        <div className="connect-device-count">
          <p>{loading ? 0 : list.length}개</p>
        </div>
      </div>
      {loading ? (
        <Skeleton />
      ) : (
        <div className="device-display">
          {list &&
            list.length > 0 &&
            list.map((remoteDevice) => {
              return (
                <RemoteDeviceContent
                  key={remoteDevice.id}
                  remoteDevice={remoteDevice}
                />
              );
            })}
        </div>
      )}
      <Button
        onClick={onClickModal}
        disabled={loading}
        className="add-device-button"
      >
        <img
          src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/add_button.png`}
          alt="add"
        />
        <p>제품 추가</p>
      </Button>
      {app ? (
        <RemoteDeviceRegisterDrawer id={id as number} token={token} />
      ) : (
        <RemoteDeviceRegisterModal id={id as number} token={token} />
      )}
    </section>
  );
};

export default RemoteHubPanel;
