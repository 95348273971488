import { airPosGet, airPosPost, apiGet, apiPost } from "../../common/api";

type PosListResponseType = {
  code: string;
  message: string;
  result: {
    id: string;
    device_name: string;
  }[];
};

type SaleEntity = {
  amount: string;
  saleCount: string;
};

type SalesHistory = {
  id: string;
  posId: string;
  saleTime: string;
  salesType: string;
  salesAmount: string;
}[];

type SummaryEntity = {
  totalAmount: string;
  salesCount: string;
  rateType: string;
  rate: string;
};

type PosSummaryMonthly = {
  rateOfSalesMonthly: {
    type: string;
    amount: string;
    salesCount: string;
    rateType: string;
    rate: string;
  }[];
  records: {
    id: string;
    salesTime: string;
    rateOfSales: {
      salesAll: SaleEntity;
      salesCard: SaleEntity;
      saleCash: SaleEntity;
    };
  };
};

type PosSummaryDaily = {
  totalAmount: string;
  rateOfSalesDaily: {
    type: string;
    amount: string;
    rate: string;
    salesCount: string;
  };
  records: {
    id: string;
    posId: string;
    saleTime: string;
    salesType: string;
    salesAmount: string;
  }[];
};

type PosSummaryCommon = {
  rateOfSalesDaily: SummaryEntity;
  rateOfSalesYesterday: SummaryEntity;
  rateOfSalesYesterdayJust: SummaryEntity;
  rateOfSalesWeekly: SummaryEntity;
  rateOfSalesMonthly: SummaryEntity;
};

type SaleReceipt = {
  id: string;
  posId: string;
  saleTime: string;
  salesType: string;
  salesAmount: string;
  saleList: {
    product_code: string;
    product_name: string;
    quantity: string;
    price: string;
    discount: string;
  }[];
};

type toastCamAccountCheckResult = {
  result: string;
};

type booleanResult = {
  result: boolean;
};

export const checkAirPosAccount = async (
  bizUserId: string,
  bizUserPassword: string
) => {
  const body = { bizUserId: bizUserId, bizUserPassword: bizUserPassword };
  const { data } = await apiPost(`/v1.0/openapi/airpos/account/check`, body);
  return data as toastCamAccountCheckResult;
};

export const getPosAccessToken = async () => {
  try {
    const { data } = await airPosPost(`/goqual/acesstoken`);
    return data as PosListResponseType;
  } catch (e) {
    throw e;
  }
};

export const getPosList = async (body: {
  bizUserId: string;
  bizUserPassword: string;
}) => {
  try {
    const { data } = await apiPost(`/v1.0/openapi/airpos/list`, body);
    return data as PosListResponseType;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const getPosListNoSpace = async (body: {
  bizUserId: string;
  bizUserPassword: string;
}) => {
  try {
    const { data } = await apiPost(`/v1.0/openapi/airpos/list/nospace`, body);
    return data as PosListResponseType;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const getCameraListInSpace = async (spaceId: number) => {
  try {
    const { data } = await apiGet(`/v1.0/openapi/toastcam/space/${spaceId}`);
    return data as PosListResponseType;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const postDisconnectAirPos = async (appSchema: string) => {
  try {
    const body = { appSchema: appSchema };
    const { data } = await apiPost(`/v1.0/openapi/airpos/disconnect`, body);
    return data as booleanResult;
  } catch (e) {
    throw e;
  }
};
