import React, { useEffect } from "react";
import { Button, Divider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store";
import {
  // settingAirposSelector,
  updateAirposModalVisible,
} from "../../../store/setting/airpos";
import { updateToastcamModalVisible } from "../../../store/setting/toascam";
import AirposModal from "./airpos/AirposModal";
import AirposDeleteModal from "./airpos/AirposDeleteModal";
import ToastCamModal from "./toastcam/ToastCamModal";
import {
  fetchGetSyncPlatformList,
  settingSyncPlatformListSelector,
} from "../../../store/setting/account/syncPlatforms";
import { postDisconnectAirPos } from "../../../api/airPos/airPos";
import {
  resetAirposDeleteModalVisible,
  settingAirposDeleteSelector,
  updateAirposDeleteModal,
} from "../../../store/setting/airpos/delete";
import {
  resetToastcamDeleteModalVisible,
  settingToastcamDeleteSelector,
  updateToastcamDeleteModal,
} from "../../../store/setting/toascam/delete";
import ToastCamDeleteModal from "./toastcam/ToastCamDeleteModal";

function PeristalsisInfo() {
  const dispatch = useDispatch<AppDispatch>();
  const { success: airposDeleteSuccess } = useSelector(
    settingAirposDeleteSelector
  );
  const { success: toastcamDeleteSuccess } = useSelector(
    settingToastcamDeleteSelector
  );
  const syncPlatforms = useSelector(settingSyncPlatformListSelector);

  useEffect(() => {
    if (airposDeleteSuccess || toastcamDeleteSuccess) {
      dispatch(fetchGetSyncPlatformList);
      resetAirposDeleteModalVisible();
      resetToastcamDeleteModalVisible();
    }
  }, [airposDeleteSuccess, toastcamDeleteSuccess]);

  const isSyncPlatform = (platformType: string) => {
    const { syncPlatformList } = syncPlatforms;
    return syncPlatformList.find(
      ({ syncPlatformInfo }: { syncPlatformInfo: { platform_name: string } }) =>
        syncPlatformInfo.platform_name === platformType
    );
  };

  const SyncCCTV = () => {
    const syncInfo = isSyncPlatform("ToastCam");

    return syncInfo ? (
      <>
        <Divider />
        <div className="setting-item">
          <img
            src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/cctv_icon.png`}
            alt="cctv_icon"
          />
          <div className="setting-info">
            <h1>CCTV</h1>
            <p>{syncInfo ? `연동됨 - ${syncInfo.user_id}` : null}</p>
          </div>
          {syncInfo ? (
            <Button
              type="default"
              onClick={() => dispatch(updateToastcamDeleteModal(true))}
            >
              연동 해제
            </Button>
          ) : (
            <Button
              type="default"
              onClick={() => {
                dispatch(updateToastcamModalVisible(true));
              }}
            >
              연동
            </Button>
          )}
        </div>
      </>
    ) : null;
  };

  const SyncPos = () => {
    const syncInfo = isSyncPlatform("AirPos");

    return syncInfo ? (
      <>
        <Divider />
        <div className="setting-item">
          <img
            src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/airporce_icon.png`}
            alt="airpos_icon"
          />
          <div className="setting-info">
            <h1>에어포스</h1>
            <p>{syncInfo ? `연동됨 - ${syncInfo.user_id}` : null}</p>
          </div>
          {syncInfo ? (
            <Button
              type="default"
              onClick={() => dispatch(updateAirposDeleteModal(true))}
            >
              연동 해제
            </Button>
          ) : (
            <Button
              type="default"
              onClick={() => {
                dispatch(updateAirposModalVisible(true));
              }}
            >
              연동
            </Button>
          )}
        </div>
      </>
    ) : null;
  };

  return (
    <div className="setting-container">
      <div className="setting-title">
        <h1>연동 정보</h1>
      </div>
      <div className="setting-content">
        <p>연동된 서비스</p>
        <Divider />
        <SyncCCTV />
        <SyncPos />
        <Divider />
        {/*<div className="setting-item">*/}
        {/*  <img*/}
        {/*    src="https://hejhome-square.s3.ap-northeast-2.amazonaws.com/1.0/button-icons/alarm_talk.png"*/}
        {/*    alt="alarm_talk_icon"*/}
        {/*  />*/}
        {/*  <div className="setting-info">*/}
        {/*    <h1>카카오톡 알림톡</h1>*/}
        {/*    <p>연동됨 - kakaoaccount@email.com</p>*/}
        {/*  </div>*/}
        {/*  <Button type="default">연동 해제</Button>*/}
        {/*</div>*/}
        {/*<Divider />*/}
        {/*<div className="setting-item">*/}
        {/*  <img*/}
        {/*    src="https://hejhome-square.s3.ap-northeast-2.amazonaws.com/1.0/button-icons/phone_icon.png"*/}
        {/*    alt="phone_icon"*/}
        {/*  />*/}
        {/*  <div className="setting-info">*/}
        {/*    <h1>휴대전화 번호 인증</h1>*/}
        {/*    <p>연동됨 - +82102345678</p>*/}
        {/*  </div>*/}
        {/*  <Button type="default">연동 해제</Button>*/}
        {/*</div>*/}
        <Divider />
      </div>
      <div className="setting-content">
        <p>연동 가능한 서비스</p>
        <Divider />
        {isSyncPlatform("AirPos") ? null : (
          <>
            <div className="setting-item">
              <img
                src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/airporce_icon.png`}
                alt="airporce_icon"
              />
              <div className="setting-info">
                <h1>에어포스</h1>
                <p>
                  에어포스와 연동하여 POS 기기 관리 및 매출 정보 확인을 Hejhome
                  Square에서 할 수 있습니다.
                </p>
              </div>
              <Button
                type="primary"
                onClick={() => {
                  dispatch(updateAirposModalVisible(true));
                }}
              >
                연동
              </Button>
            </div>
          </>
        )}
        {isSyncPlatform("ToastCam") ? null : (
          <>
            <div className="setting-item">
              <img
                src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/airporce_icon.png`}
                alt="airporce_icon"
              />
              <div className="setting-info">
                <h1>CCTV</h1>
                <p> </p>
              </div>
              <Button
                type="primary"
                onClick={() => {
                  dispatch(updateToastcamModalVisible(true));
                }}
              >
                연동
              </Button>
            </div>
          </>
        )}
      </div>
      <AirposModal />
      <AirposDeleteModal />
      <ToastCamDeleteModal />
      <ToastCamModal />
    </div>
  );
}
export default PeristalsisInfo;
