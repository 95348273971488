import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sleep } from "../../../common/utils";
import { RootState } from "../../index";
import { getPosMapping } from "../../../api/pos/pos";

type Pos = {
  id: string;
  device_id: string;
  device_name: string;
  pid: string;
  mapping_info: {
    id: number;
    pos: number;
    camera: {
      id: number;
      space_id: number;
      device_id: number;
    };
  };
};

type PosList = {
  loading: boolean;
  error?: string | Error;
  posMappingInfo: Pos;
};

const initialState: PosList = {
  loading: false,
  error: undefined,
  posMappingInfo: {
    id: "",
    device_id: "",
    device_name: "",
    pid: "",
    mapping_info: {
      id: 0,
      pos: 0,
      camera: {
        id: 0,
        space_id: 0,
        device_id: 0,
      },
    },
  },
};

export const fetchGetPosMappingInfo = createAsyncThunk(
  "pos/mapping",
  async ({ id }: { id: string }) => {
    await sleep(100);
    const { result } = await getPosMapping({ id: id });
    return result;
  }
);

const slice = createSlice({
  name: "pos/mapping",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGetPosMappingInfo.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(fetchGetPosMappingInfo.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = undefined;
      state.posMappingInfo = payload;
    });
    builder.addCase(fetchGetPosMappingInfo.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    });
  },
});

export const posSettingSelector = (state: RootState) => state.pos.posSetting;
export const {} = slice.actions;

export default slice;
