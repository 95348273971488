import { loginPost } from "../../common/api";
import qs from "qs";
type ResultLoginResponse = {
  result: {
    id: number;
    email: string;
    username: string;
    access_token: string;
  };
};

type LoginRequest = {
  id: string;
  password: string;
};

export const login = async ({ id, password }: LoginRequest) => {
  try {
    const { data } = await loginPost(
      "/v1.0/auth/login-square",
      qs.stringify({
        id,
        password,
      })
    );
    return data as ResultLoginResponse;
  } catch (e) {
    throw e;
  }
};
