import React from "react";
import { Routes, Route } from "react-router";
import LoginContainer from "./pages/Login";
import AppContainer from "./AppContainer";
import "./styles/index.scss";
import "./App.css";
import MultiView from "./pages/MultiView";
import Panel from "./pages/Panel";

function App() {
  return (
    <Routes>
      <Route path="/multi-view" element={<MultiView />} />
      <Route path="/login/*" element={<LoginContainer />} />
      <Route path="*" element={<AppContainer />} />
      <Route path="/device/panel/*" element={<Panel />} />
    </Routes>
  );
}

export default App;
