import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sleep } from "../../../../../common/utils";
import { RootState } from "../../../../index";
import { getPosSummaryWeekly } from "../../../../../api/pos/pos";

export type searchWeekly = {
  Day: string;
  history:
    | {
        totalAmount: string;
        records:
          | {
              id: string;
              posId: string;
              saleTime: string;
              salesType: string;
              salesAmount: string;
            }[]
          | [];
      }
    | string;
  isBefore: boolean;
}[];

type SearchConclusion = {
  loading: boolean;
  error?: string | Error;
  search: searchWeekly;
};

const initialState: SearchConclusion = {
  loading: false,
  error: undefined,
  search: [],
};

export const fetchGetSearchWeekly = createAsyncThunk(
  "airPos/search/receipt/weekly",
  async ({ date, deviceId }: { date: string; deviceId?: number }) => {
    await sleep(100);
    const { result } = await getPosSummaryWeekly({
      date: date,
      deviceId,
    });

    return result;
  }
);

const slice = createSlice({
  name: "airPos/search/weekly",
  initialState,
  reducers: {
    resetSearchWeekly: (state) => ({
      ...state,
      search: initialState.search,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetSearchWeekly.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(fetchGetSearchWeekly.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = undefined;
      state.search = payload;
    });
    builder.addCase(fetchGetSearchWeekly.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    });
  },
});

export const posSearchWeeklySelector = (state: RootState) =>
  state.pos.weeklyConclusion;
export const { resetSearchWeekly } = slice.actions;

export default slice;
