import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../index";
type RecipeHistoryState = {
  visible: boolean;
  date: {
    start: string;
    end: string;
  };
};

const initialState: RecipeHistoryState = {
  visible: false,
  date: {
    start: "",
    end: "",
  },
};

const slice = createSlice({
  name: "recipe/history",
  initialState,
  reducers: {
    resetRecipeHistoryModal: (state: RecipeHistoryState) => ({
      ...state,
      visible: initialState.visible,
    }),
    updateRecipeHistoryModal: (state: RecipeHistoryState, { payload }) => ({
      ...state,
      visible: payload,
    }),
    updateRecipeHistoryStartDate: (state: RecipeHistoryState, { payload }) => ({
      ...state,
      date: {
        ...state.date,
        start: payload,
      },
    }),
    updateRecipeHistoryEndDate: (state: RecipeHistoryState, { payload }) => ({
      ...state,
      date: {
        ...state.date,
        end: payload,
      },
    }),
  },
});

export const recipeHistorySelector = (state: RootState) => state.recipe.history;
export const {
  resetRecipeHistoryModal,
  updateRecipeHistoryModal,
  updateRecipeHistoryEndDate,
  updateRecipeHistoryStartDate,
} = slice.actions;
export default slice;
