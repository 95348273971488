import React from "react";

const CctvMultiviewArrowIcon = ({ direction }: { direction: string }) => {
  switch (direction) {
    case "left":
      return (
        <svg
          width={20}
          height={20}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m7.963 7.525 3.114-3.114 1.179 1.178-3.114 3.115c-.34.34-.56.56-.712.74-.147.173-.185.255-.199.299a.833.833 0 0 0 0 .514c.014.045.052.126.199.3.153.18.372.4.712.74l3.114 3.114-1.179 1.178-3.137-3.137c-.31-.31-.578-.578-.781-.817-.214-.252-.404-.526-.513-.862a2.5 2.5 0 0 1 0-1.546c.11-.336.3-.61.513-.862.203-.239.47-.507.781-.817l.023-.023Z"
            fill="#F7F4F1"
          />
        </svg>
      );
    case "right":
      return (
        <svg
          width={20}
          height={20}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m12.037 12.475-3.114 3.114-1.179-1.178 3.114-3.115c.34-.34.56-.56.712-.74.147-.173.185-.254.199-.298a.835.835 0 0 0 0-.516c-.014-.044-.052-.125-.199-.298-.153-.18-.372-.4-.712-.74L7.744 5.589l1.179-1.178 3.137 3.137c.31.31.578.578.781.817.214.252.404.526.513.862a2.5 2.5 0 0 1 0 1.546c-.11.336-.3.61-.513.862-.203.239-.47.507-.781.817l-.023.023Z"
            fill="#F7F4F1"
          />
        </svg>
      );
    default:
      return null;
  }
};

export default CctvMultiviewArrowIcon;
