import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../index";
import { sleep } from "../../../../common/utils";
import { addWidgetInDashboard } from "../../../../api/widget/widget";

type DashboardWidgetRegisterState = {
  visible: boolean;
  loading: boolean;
  success: boolean;
  deviceId?: number;
  error?: string | Error;
};

const initialState: DashboardWidgetRegisterState = {
  visible: false,
  loading: false,
  success: false,
};

export const registerWidgetInDashboard = createAsyncThunk(
  "dashboard/widget/register",
  async ({
    deviceId,
    dashboardId,
    widgetState,
    widgetName,
  }: {
    deviceId: number;
    dashboardId: number;
    widgetState: string;
    widgetName: string;
  }) => {
    await sleep(100);
    const { result } = await addWidgetInDashboard(
      deviceId,
      dashboardId,
      widgetState,
      widgetName
    );
    return result;
  }
);

const slice = createSlice({
  name: "dashboard/widget/register",
  initialState,
  reducers: {
    resetDashboardWidgetRegister: (state: DashboardWidgetRegisterState) => ({
      ...state,
      visible: initialState.visible,
      loading: initialState.loading,
      success: initialState.success,
      deviceId: initialState.deviceId,
    }),
    updateVisible: (state: DashboardWidgetRegisterState, { payload }) => ({
      ...state,
      visible: payload,
    }),
    updateDeviceId: (state: DashboardWidgetRegisterState, { payload }) => ({
      ...state,
      deviceId: payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(registerWidgetInDashboard.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      registerWidgetInDashboard.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = undefined;
        state.success = payload;
      }
    );
    builder.addCase(registerWidgetInDashboard.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message as string;
      state.success = false;
    });
  },
});

export const dashboardWidgetRegisterSelector = (state: RootState) =>
  state.dashboard.widget.register;
export const { resetDashboardWidgetRegister, updateVisible, updateDeviceId } =
  slice.actions;

export default slice;
