import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sleep } from "../../../../common/utils";
import { RootState } from "../../../index";
import { getPosList } from "../../../../api/airPos/airPos";
import { getPosHistory } from "../../../../api/pos/pos";

type ReceiptHistory = {
  id: string;
  posId: string;
  saleTime: string;
  salesType: string;
  salesAmount: string;
};

type today = {
  loading: boolean;
  error?: string | Error;
  today: ReceiptHistory[];
};

const initialState: today = {
  loading: false,
  error: undefined,
  today: [],
};

export const fetchGetToday = createAsyncThunk(
  "pos/today",
  async ({ date, deviceId }: { date: string; deviceId: string }) => {
    await sleep(100);
    const { result } = await getPosHistory({
      date: date,
      deviceId: deviceId,
    });

    return result;
  }
);

const slice = createSlice({
  name: "pos/today",
  initialState,
  reducers: {
    resetPosToday: (state) => ({
      ...state,
      today: initialState.today,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetToday.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(fetchGetToday.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = undefined;
      state.today = payload;
    });
    builder.addCase(fetchGetToday.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    });
  },
});

export const posTodaySelector = (state: RootState) => state.pos.posToday;
export const { resetPosToday } = slice.actions;

export default slice;
