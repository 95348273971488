import { Modal } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store";
import {
  settingServiceInfoSelector,
  updateServiceInfoModalVisible,
} from "../../../store/setting/service-info";

function ServiceInfoModal() {
  const dispatch = useDispatch<AppDispatch>();
  const { visible, term } = useSelector(settingServiceInfoSelector);
  const onCancel = () => {
    dispatch(updateServiceInfoModalVisible(false));
  };
  return (
    <Modal
      className="service-info-modal"
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      {term ? term : <></>}
    </Modal>
  );
}
export default ServiceInfoModal;
