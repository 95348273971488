import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../index";
import { sleep } from "../../../common/utils";
import {
  ConditionValueType,
  getActionByName,
} from "../../../api/recipe/recipe";

type RecipeActionState = {
  loading: boolean;
  error?: string | Error;
  actionValueList: ConditionValueType[];
};

const initialState: RecipeActionState = {
  loading: false,
  error: "",
  actionValueList: [],
};

export const fetchGetActionValue = createAsyncThunk(
  "recipe/action",
  async (name: string) => {
    await sleep(100);
    const { result } = await getActionByName(name);
    return result;
  }
);
const slice = createSlice({
  name: "recipe/action",
  initialState,
  reducers: {
    resetRecipeActionValue: (state: RecipeActionState) => ({
      ...state,
      loading: initialState.loading,
      error: initialState.error,
      actionValueList: initialState.actionValueList,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetActionValue.pending, (state: RecipeActionState) => {
      state.loading = true;
    });
    builder.addCase(
      fetchGetActionValue.fulfilled,
      (state: RecipeActionState, { payload }) => {
        state.loading = false;
        state.error = undefined;
        state.actionValueList = payload;
      }
    );
    builder.addCase(
      fetchGetActionValue.rejected,
      (state: RecipeActionState, { error }) => {
        state.loading = false;
        state.error = error.message as string;
        state.actionValueList = [];
      }
    );
  },
});

export const recipeActionSelector = (state: RootState) => state.recipe.action;
export const { resetRecipeActionValue } = slice.actions;
export default slice;
