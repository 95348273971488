import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../index";

type WidgetState = {
  visible: boolean;
};

const initialState: WidgetState = {
  visible: false,
};

const slice = createSlice({
  name: "dashboard/widget/main",
  initialState,
  reducers: {
    resetDashboardWidget: (state) => ({
      ...state,
      visible: initialState.visible,
    }),
    updateDashboardWidgetVisible: (state, { payload }) => ({
      ...state,
      visible: payload,
    }),
  },
});

export const dashboardWidgetMainSelector = (state: RootState) =>
  state.dashboard.widget.main;
export const { updateDashboardWidgetVisible, resetDashboardWidget } =
  slice.actions;

export default slice;
