import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { categoryListSelector } from "../../../store/panel/category-list";
import { AppDispatch } from "../../../store";
import { Skeleton, Radio, RadioChangeEvent } from "antd";
import {
  registerRemoteDeviceSelector,
  updateBrandId,
  updateCategoryId,
} from "../../../store/panel/add-remote-device";

function RemoteDeviceSelectContent() {
  const { list, loading } = useSelector(categoryListSelector);
  const { categoryId, brandId } = useSelector(registerRemoteDeviceSelector);
  const dispatch = useDispatch<AppDispatch>();

  const onChangeCategory = (e: RadioChangeEvent) => {
    const value = e.target.value;
    dispatch(updateCategoryId(value));
    dispatch(updateBrandId(undefined));
  };
  const onChangeBrand = (e: RadioChangeEvent) => {
    const value = e.target.value;
    dispatch(updateBrandId(value));
  };
  return (
    <div className="category-container">
      {loading ? (
        <Skeleton />
      ) : (
        list &&
        list.length > 0 && (
          <Radio.Group onChange={onChangeCategory} value={categoryId}>
            {list.map((categoryInform) => {
              return (
                <div className="category-list" key={categoryInform.categoryId}>
                  <div
                    className="category-content"
                    key={categoryInform.categoryId}
                  >
                    <div className="category-item">
                      <img
                        src={`${process.env.REACT_APP_S3_URL}/1.0/device-icons/${categoryInform.deviceType}.png`}
                        alt="category-device-icon"
                      />
                      <p>{categoryInform.categoryName}</p>
                    </div>
                    <Radio value={categoryInform.categoryId} />
                  </div>
                  {categoryId === categoryInform.categoryId &&
                    categoryInform.brandList &&
                    categoryInform.brandList.length > 0 && (
                      <div className="brand-list">
                        <p>제조사 선택</p>
                        <Radio.Group onChange={onChangeBrand} value={brandId}>
                          {categoryInform.brandList.map((brand) => {
                            return (
                              <div className="brand-inform" key={brand.brandId}>
                                <p>{brand.brandName}</p>
                                <Radio value={brand.brandId} />
                              </div>
                            );
                          })}
                        </Radio.Group>
                      </div>
                    )}
                </div>
              );
            })}
          </Radio.Group>
        )
      )}
    </div>
  );
}
export default RemoteDeviceSelectContent;
