import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../index";
import { sleep } from "../../../../common/utils";
import {
  getNhnToastCamList,
  getNhnToastCamListNoSpace,
} from "../../../../api/nhn/nhn";

export type ToastcamType = {
  id: string;
  name: string;
  newName: string;
};

export type Space = {
  id: string;
  name: string;
};

export type toastCam = {
  id: string;
  device_id: string;
  pid: string;
  device_name: string;
};

type ToastcamListState = {
  loading: boolean;
  error?: string | Error;
  toastcamList: toastCam[];
  selectedCam?: ToastcamType;
  selectedSpace?: Space;
  isAgain: boolean;
  loginId: string;
  loginPassword: string;
};

const initialState: ToastcamListState = {
  loading: false,
  error: undefined,
  toastcamList: [],
  selectedCam: undefined,
  selectedSpace: undefined,
  isAgain: false,
  loginId: "",
  loginPassword: "",
};

export const fetchGetToastCamList = createAsyncThunk(
  "setting/Toastcam/list",
  async ({
    checkAgain,
    bizUserId,
    bizUserPassword,
  }: {
    checkAgain: boolean;
    bizUserId: string;
    bizUserPassword: string;
  }) => {
    await sleep(100);
    if (!checkAgain) {
      const body = { bizUserId: bizUserId, bizUserPassword: bizUserPassword };
      const { result } = await getNhnToastCamList(body);
      return result;
    } else {
      const { result } = await getNhnToastCamListNoSpace();
      return result;
    }
  }
);

const slice = createSlice({
  name: "setting/Toastcam/list",
  initialState,
  reducers: {
    resetToastcamList: (state: ToastcamListState) => ({
      ...state,
      list: initialState.toastcamList,
      selectedCam: initialState.selectedCam,
    }),
    updateSelectedCam: (
      state: ToastcamListState,
      { payload: { id, name, newName } }
    ) => ({
      ...state,
      selectedCam: {
        ...state.selectedCam,
        id,
        name,
        newName,
      },
    }),
    updateSelectedSpace: (
      state: ToastcamListState,
      { payload: { id, name } }
    ) => ({
      ...state,
      selectedSpace: {
        ...state.selectedSpace,
        id,
        name,
      },
    }),
    updateSettingCamAgain: (state: ToastcamListState) => ({
      ...state,
      isAgain: true,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetToastCamList.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(fetchGetToastCamList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = undefined;
      state.toastcamList = payload;
    });
    builder.addCase(fetchGetToastCamList.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    });
  },
});

export const settingToastcamListSelector = (state: RootState) =>
  state.setting.toastcam.list;

export const {
  resetToastcamList,
  updateSelectedCam,
  updateSelectedSpace,
  updateSettingCamAgain,
} = slice.actions;

export default slice;
