import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../index";

type ToastcamLoginState = {
  visible: boolean;
};

const initialState: ToastcamLoginState = {
  visible: false,
};

const slice = createSlice({
  name: "setting/Toastcam/login",
  initialState,
  reducers: {
    resetToastcamModalVisible: (state: ToastcamLoginState) => ({
      ...state,
      visible: initialState.visible,
    }),
    updateToastcamModalVisible: (state: ToastcamLoginState, { payload }) => ({
      ...state,
      visible: payload,
    }),
  },
});

export const settingToastcamtLoginSelector = (state: RootState) =>
  state.setting.toastcam.login;

export const { resetToastcamModalVisible } = slice.actions;

export default slice;
