import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchResetPassword,
  resetPasswordSelector,
} from "../../../store/reset-password";
import PasswordForm from "../../common/password-form/PasswordForm";
import { Button } from "antd";
import { AppDispatch } from "../../../store";
import useQuery from "../../../utils/useQuery";

function ResetPasswordStep3() {
  const { form, success } = useSelector(resetPasswordSelector);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const query = useQuery();
  const step = Number(query.get("step"));

  useEffect(() => {
    success && navigate(`/login/reset-password?step=${step + 1}`);
  }, [success]);

  const onNextClick = useCallback(async () => {
    await dispatch(
      fetchResetPassword({
        email: form.email as string,
        password: form.password1 as string,
      })
    );
  }, [dispatch, form.email, form.password1]);

  return (
    <div>
      <PasswordForm />
      <div className="reset-password-footer">
        <Button
          type="primary"
          disabled={!form.email || form.email === ""}
          onClick={onNextClick}
        >
          다음
        </Button>
      </div>
    </div>
  );
}

export default ResetPasswordStep3;
