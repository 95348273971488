import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../index";
import { sleep } from "../../../../common/utils";
import { getCameraListInSpace } from "../../../../api/airPos/airPos";

export type CameraType = {
  id: string;
  device_name: string;
};

type CameraListState = {
  loading: boolean;
  error?: string | Error;
  cameraList: CameraType[];
  selectedCamera?: CameraType;
};

const initialState: CameraListState = {
  loading: false,
  error: undefined,
  cameraList: [],
  selectedCamera: undefined,
};

export const fetchGetSpaceCameraList = createAsyncThunk(
  "setting/Space/Camera",
  async ({ spaceId }: { spaceId: number }) => {
    await sleep(100);
    const { result } = await getCameraListInSpace(spaceId);
    return result;
  }
);

const slice = createSlice({
  name: "setting/airpos/camera",
  initialState,
  reducers: {
    resetCameraList: (state: CameraListState) => ({
      ...state,
      cameraList: initialState.cameraList,
      selectedCamera: initialState.selectedCamera,
    }),
    updateSelectedCamera: (
      state: CameraListState,
      { payload: { id, device_name } }
    ) => ({
      ...state,
      selectedCamera: {
        ...state.selectedCamera,
        id,
        device_name,
      },
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetSpaceCameraList.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(fetchGetSpaceCameraList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = undefined;
      state.cameraList = payload;
    });
    builder.addCase(fetchGetSpaceCameraList.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    });
  },
});

export const settingCameraListSelector = (state: RootState) =>
  state.setting.airpos.cameraList;

export const { resetCameraList, updateSelectedCamera } = slice.actions;

export default slice;
