import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sleep } from "../../common/utils";
import { emailVerify } from "../../api/join/join";
import { RootState } from "../index";

type VerifyEmail = {
  loading: boolean;
  error?: string | Error;
  valid: boolean | undefined;
};

const initialState: VerifyEmail = {
  loading: false,
  error: undefined,
  valid: undefined,
};

export const fetchEmailVerify = createAsyncThunk(
  "email-verify",
  async (email: string) => {
    await sleep(100);
    const { result } = await emailVerify(email);
    return result;
  }
);

const slice = createSlice({
  name: "email-verify",
  initialState,
  reducers: {
    resetValid: (state) => ({
      ...state,
      valid: initialState.valid,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEmailVerify.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(fetchEmailVerify.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = undefined;
      state.valid = payload;
    });
    builder.addCase(fetchEmailVerify.rejected, (state, { error }) => {
      state.loading = false;
      state.valid = false;
      state.error = error.message;
    });
  },
});

export const emailVerifySelector = (state: RootState) => state.verifyEmail;
export const { resetValid } = slice.actions;

export default slice;
