import axiosGlobal from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
const authUrl = process.env.REACT_APP_AUTH_URL;
const AirPosUrl = "https://iot4shop.asp2.kr";
// const AirPosUrl = "http://218.234.20.206:8080";

/**
 * axios 인스턴스를 만들 때 구성 기본 값 설정
 */
let apiAxios = axiosGlobal.create({
  baseURL: apiUrl,
  timeout: 30000,
  withCredentials: false,
  headers: {
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json",
  },
});

let authAxios = axiosGlobal.create({
  baseURL: authUrl,
  timeout: 30000,
  withCredentials: false,
  headers: {
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json",
  },
});

let loginAxios = axiosGlobal.create({
  baseURL: authUrl,
  timeout: 30000,
  withCredentials: false,
  headers: {
    Authorization: "Basic YXBwbGljYXRpb246c2VjcmV0",
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

let airPosAxios = axiosGlobal.create({
  baseURL: AirPosUrl,
  timeout: 3000,
  headers: {
    Authorization: "90b4f9dbb894eee4ff94681e9b25cfe2",
  },
});

export function checkAccessTokenHeader() {
  const token = localStorage.getItem("accessToken") as string;
  apiAxios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

checkAccessTokenHeader();

export async function loginPost(url: string, data: string) {
  try {
    return await loginAxios.post(authUrl + url, data);
  } catch (e) {
    throw e;
  }
}

export async function apiPost(url: string, data?: object, headers?: any) {
  try {
    return await apiAxios.post(apiUrl + url, data, { headers });
  } catch (e) {
    throw e;
  }
}

export async function apiDelete(url: string, headers?: any) {
  try {
    return await apiAxios.delete(apiUrl + url, { headers });
  } catch (e) {
    throw e;
  }
}

export async function apiGet(url: string, headers?: any) {
  try {
    return await apiAxios.get(apiUrl + url, { headers });
  } catch (e) {
    throw e;
  }
}

export async function authPost(url: string, data: object, headers?: any) {
  try {
    return await authAxios.post(authUrl + url, data, headers);
  } catch (e) {
    throw e;
  }
}

export async function authGet(url: string) {
  try {
    return await authAxios.get(authUrl + url);
  } catch (e) {
    throw e;
  }
}

export async function airPosGet(url: string) {
  try {
    return await airPosAxios.get(AirPosUrl + url);
  } catch (e) {
    throw e;
  }
}

export async function airPosPost(url: string, data?: object, headers?: any) {
  try {
    return await airPosAxios.post(AirPosUrl + url, data, { headers });
  } catch (e) {
    throw e;
  }
}
