import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sleep } from "../../../common/utils";
import { RootState } from "../../index";
import { addDeviceInSpace } from "../../../api/space/space";

type AddDeviceInSpace = {
  loading: boolean;
  error?: string | Error;
  success: boolean | undefined;
};

const initialState: AddDeviceInSpace = {
  loading: false,
  error: undefined,
  success: undefined,
};

export const fetchAddDeviceInSpace = createAsyncThunk(
  "space/addDevice",
  async (body: { spaceId: number; deviceId: number }) => {
    await sleep(100);
    const { result } = await addDeviceInSpace(body.spaceId, {
      deviceId: body.deviceId,
    });
    return result;
  }
);

const slice = createSlice({
  name: "space/addDevice",
  initialState,
  reducers: {
    resetAddDeviceInSpace: (state) => ({
      ...state,
      success: initialState.success,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAddDeviceInSpace.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(fetchAddDeviceInSpace.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = undefined;
      state.success = payload;
    });
    builder.addCase(fetchAddDeviceInSpace.rejected, (state, { error }) => {
      state.loading = false;
      state.success = false;
      state.error = error.message;
    });
  },
});

export const addDeviceInSpaceSelector = (state: RootState) =>
  state.space.addDevice;
export const { resetAddDeviceInSpace } = slice.actions;

export default slice;
