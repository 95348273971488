import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../index";
import { sleep } from "../../../common/utils";
import {
  getRecipeList,
  Recipe,
  SpaceDeviceMapping,
} from "../../../api/recipe/recipe";

type RecipeListState = {
  loading: boolean;
  data: {
    recipeList: Recipe[];
    spaceDeviceMappingList: SpaceDeviceMapping[];
  };
  error?: string | Error;
};

const initialState: RecipeListState = {
  loading: false,
  data: {
    recipeList: [],
    spaceDeviceMappingList: [],
  },
  error: undefined,
};

export const fetchGetRecipeList = createAsyncThunk("recipe/list", async () => {
  await sleep(100);
  const { result } = await getRecipeList();
  return result;
});

const slice = createSlice({
  name: "recipe/list",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGetRecipeList.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(fetchGetRecipeList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.error = undefined;
    });
    builder.addCase(fetchGetRecipeList.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    });
  },
});

export const recipeListSelector = (state: RootState) => state.recipe.list;

export default slice;
