import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../index";
import { sleep } from "../../../../common/utils";
import { postUpdateUserName } from "../../../../api/user/user";

type UpdateState = {
  loading: boolean;
  success: boolean;
  error?: string | Error;
};

const initialState: UpdateState = {
  loading: false,
  success: false,
  error: undefined,
};

export const fetchUpdateAccountUserName = createAsyncThunk(
  "setting/account/update",
  async (name: string) => {
    await sleep(100);
    const { result } = await postUpdateUserName(name);
    return result;
  }
);

const slice = createSlice({
  name: "setting/account/update",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUpdateAccountUserName.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(
      fetchUpdateAccountUserName.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.success = payload;
        state.error = undefined;
      }
    );
    builder.addCase(fetchUpdateAccountUserName.rejected, (state, { error }) => {
      state.loading = false;
      state.success = false;
      state.error = error.message;
    });
  },
});

export const settingAccountUpdateSelector = (state: RootState) =>
  state.setting.account.update;

export default slice;
