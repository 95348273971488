import { Button, Drawer } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store";
import {
  categoryListSelector,
  updateRemoteAddModal,
} from "../../../store/panel/category-list";
import React from "react";
import RemoteDeviceSelectContent from "./RemoteDeviceSelectContent";
import {
  fetchRegisterRemoteDevice,
  registerRemoteDeviceSelector,
} from "../../../store/panel/add-remote-device";

function RemoteDeviceRegisterDrawer({
  id,
  token,
}: {
  id: number;
  token?: string;
}) {
  const { visible } = useSelector(categoryListSelector);
  const { categoryId, brandId, loading } = useSelector(
    registerRemoteDeviceSelector
  );
  const dispatch = useDispatch<AppDispatch>();
  const onClose = () => {
    dispatch(updateRemoteAddModal(false));
  };

  const onAgreeClick = () => {
    categoryId &&
      brandId &&
      dispatch(fetchRegisterRemoteDevice({ id, categoryId, brandId, token }));
  };
  return (
    <Drawer
      placement="bottom"
      onClose={onClose}
      visible={visible}
      className="remote-device-register-drawer"
      footer={[
        <Button onClick={onClose} key="close">
          취소
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={onAgreeClick}
          disabled={!(categoryId && brandId)}
          htmlType="submit"
        >
          추가
        </Button>,
      ]}
    >
      <h1>제품 추가</h1>
      <p>스마트 리모컨허브에 추가할 제품을 선택해주세요.</p>
      <RemoteDeviceSelectContent />
    </Drawer>
  );
}
export default RemoteDeviceRegisterDrawer;
