import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../index";
import { sleep } from "../../../common/utils";
import { getHistory, HistoryType } from "../../../api/history/history";

type HistoryState = {
  loading: boolean;
  error?: string | Error;
  historyToday: HistoryType;
  historyDaily: HistoryType;
  startTime: string | undefined;
  endTime: string | undefined;
  date: string | undefined;
  historyTabType: string;
};

const initialState: HistoryState = {
  loading: false,
  historyToday: {},
  historyDaily: {},
  historyTabType: "today",
  startTime: undefined,
  endTime: undefined,
  date: undefined,
};

export const fetchSearchHistory = createAsyncThunk(
  "history",
  async ({
    startTime,
    endTime,
    historyTabType,
  }: {
    startTime: number | string;
    endTime?: number | string;
    historyTabType?: string;
  }) => {
    await sleep(100);
    const { result } = await getHistory(startTime, endTime, historyTabType);
    return result;
  }
);

const slice = createSlice({
  name: "history",
  initialState,
  reducers: {
    resetHistory: (state: HistoryState) => ({
      ...state,
      historyToday: initialState.historyToday,
      historyDaily: initialState.historyDaily,
      startTime: initialState.startTime,
      endTime: initialState.endTime,
      date: initialState.date,
      historyTabType: initialState.historyTabType,
    }),
    updateHistoryTabType: (state: HistoryState, { payload }) => ({
      ...state,
      historyTabType: payload,
    }),
    updateHistoryStartTime: (state: HistoryState, { payload }) => ({
      ...state,
      startTime: payload,
    }),
    updateHistoryEndTime: (state: HistoryState, { payload }) => ({
      ...state,
      endTime: payload,
    }),
    updateHistoryDate: (state: HistoryState, { payload }) => ({
      ...state,
      date: payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSearchHistory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSearchHistory.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.historyTabType === "daily"
        ? (state.historyDaily = payload)
        : (state.historyToday = payload);
      state.error = undefined;
    });
    builder.addCase(fetchSearchHistory.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message as string;
      state.historyToday = {};
      state.historyDaily = {};
    });
  },
});

export const HistorySelector = (state: RootState) => state.history.main;
export const {
  resetHistory,
  updateHistoryDate,
  updateHistoryEndTime,
  updateHistoryStartTime,
  updateHistoryTabType,
} = slice.actions;

export default slice;
