import React, { useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTitle } from "ahooks";
import { useDispatch, useSelector } from "react-redux";
import { joinSelector, resetForm, updateJoinForm } from "../../store/join";
import { sendCodeSelector } from "../../store/send-code";
import { checkCodeSelector } from "../../store/check-code";
import { emailVerifySelector } from "../../store/verify-email";
import useQuery from "../../utils/useQuery";
import useIsMobile from "../../utils/useIsMobile";
import JoinStep1 from "./step1/JoinStep1";
import JoinStep2 from "./step2/JoinStep2";
import JoinStep3 from "./step3/JoinStep3";
import JoinStep4 from "./step4/JoinStep4";
import JoinStep5 from "./step5/JoinStep5";
import { joinMessages } from "./JoinMessages";
import { Button, message } from "antd";
import "./JoinProcess.scss";
import JoinStep6 from "./step6/JoinStep6";

function JoinProcess() {
  useTitle("회원가입 | 헤이홈 스퀘어");

  const isMobile = useIsMobile();

  const navigate = useNavigate();
  const query = useQuery();
  const step = Number(query.get("step"));

  const dispatch = useDispatch();
  const { form, error: formError } = useSelector(joinSelector);
  const { error: codeError } = useSelector(sendCodeSelector);
  const { error: checkError } = useSelector(checkCodeSelector);
  const { error: emailVerifyError } = useSelector(emailVerifySelector);

  useEffect(() => {
    if (formError || codeError || checkError || emailVerifyError) {
      message.success({
        content: "일시적인 문제가 발생했습니다. 다시 시도해주세요.",
        duration: 3,
        className: "custom-message",
        style: {
          marginTop: "90vh",
        },
      });
    }
  }, [formError, codeError, checkError, emailVerifyError]);

  useEffect(() => {
    if (
      form.number1 &&
      form.number2 &&
      form.number3 &&
      form.number4 &&
      form.number5 &&
      form.number6
    ) {
      dispatch(
        updateJoinForm({
          certificationNumber: Number(
            form.number1 +
              form.number2 +
              form.number3 +
              form.number4 +
              form.number5 +
              form.number6
          ),
        })
      );
    }
  }, [
    form.number1,
    form.number2,
    form.number3,
    form.number4,
    form.number5,
    form.number6,
    dispatch,
  ]);

  const resolvedJoinMessage = useMemo(() => {
    return joinMessages.filter((message) => message.step === step)[0];
  }, [step]);

  const onGoBackClick = useCallback(() => {
    dispatch(resetForm());
    navigate("/login");
  }, []);

  return (
    <div className="hejhome-join-container">
      {isMobile && (
        <div className="hejhome-join-go-back-btn">
          <Button onClick={onGoBackClick}>
            <svg
              width={24}
              height={24}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m8.293 19.707-7-7a1 1 0 0 1 0-1.414l7-7 1.414 1.414L4.414 11H23v2H4.414l5.293 5.293-1.414 1.414Z"
                fill="#10100E"
              />
            </svg>
          </Button>
        </div>
      )}
      <div className="hejhome-join-process">
        <h3>회원가입</h3>
        <h1>{resolvedJoinMessage.title}</h1>
        <p>{resolvedJoinMessage.subtitle}</p>
        {step === 1 && <JoinStep1 />}
        {step === 2 && <JoinStep2 />}
        {step === 3 && <JoinStep3 />}
        {step === 4 && <JoinStep4 />}
        {step === 5 && <JoinStep5 />}
        {step === 6 && <JoinStep6 />}
      </div>

      {!isMobile && (
        <div className="hejhome-join-buttons">
          <Button onClick={onGoBackClick}>
            <svg
              width={18}
              height={18}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.22 14.78.97 9.53a.75.75 0 0 1 0-1.06l5.25-5.25 1.06 1.06-3.97 3.97h13.94v1.5H3.31l3.97 3.97-1.06 1.06Z"
                fill="#504D49"
              />
            </svg>
            뒤로가기
          </Button>
        </div>
      )}
    </div>
  );
}

export default JoinProcess;
