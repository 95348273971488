export const joinMessages = [
  {
    step: 1,
    title: "Hejhome Square에 오신 것을 환영합니다!",
    subtitle: "계정에 사용할 이메일을 입력해주세요.",
  },
  {
    step: 2,
    title: "약관을 확인하고 동의해주세요",
    subtitle:
      "Hejhome Square 서비스를 사용하려면 아래의 약관에 동의해야 합니다.",
  },
  {
    step: 3,
    title: "인증번호가 담긴 이메일을 보냈어요",
    subtitle: "이메일에 담긴 6자리 인증번호를 입력해주세요.",
  },
  {
    step: 4,
    title: "사용할 비밀번호를 입력해주세요",
    subtitle:
      "8-12자 사이의 숫자, 영문 소문자, 특수문자를 조합하여 비밀번호를 만들어주세요.",
  },
  {
    step: 5,
    title: "계정에서 사용할 이름을 입력해주세요",
    subtitle:
      "1-20 사이의 한글, 영문, 특수문자를 사용하여 이름을 입력해주세요. 나중에 변경할 수 있으니 걱정마세요!",
  },
  {
    step: 6,
    title: "Hejhome 서비스 가입을 축하합니다!",
    subtitle:
      "성공적으로 회원가입을 마쳤어요.\n지금부터 Hejhome과 함께 몰랐던 편안함을 경험해보세요!",
  },
];
