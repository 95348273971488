import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { panelDetailSelector } from "../../store/panel/detail";
import { DeviceDetailType } from "../../api/devices/device";
import { Button } from "antd";
import { fetchDeviceControl } from "../../store/panel/control";
import { AppDispatch } from "../../store";
import aircondition from "../../common/aircondtion.json";
import airconditionStatus from "../../common/airconditionStatus.json";
import {
  airConditionBrandIdType,
  airConditionStatusType,
} from "../../common/Types";
const AirConditionPanel = ({
  action,
  token,
}: {
  action: any;
  token?: string;
}) => {
  const { detail } = useSelector(panelDetailSelector);
  const { id, deviceName, brandId, spaceName, status } =
    detail as DeviceDetailType;
  const [mode, setMode] = useState(status?.mode ? Number(status.mode) : 0);
  const modeArray = Object.keys(aircondition.mode);

  const windArray = useMemo(() => {
    if (brandId) {
      return airconditionStatus[brandId.toString() as airConditionBrandIdType][
        status?.mode.toString() as keyof airConditionStatusType
      ].wind;
    } else {
      return [];
    }
  }, [status?.mode]);

  const [wind, setWind] = useState(
    status?.wind ? windArray.indexOf(status.wind) : 0
  );
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (status?.mode) {
      setWind(0);
      setMode(Number(status.mode));
    }
  }, [status?.mode]);

  const handleControl = (key: string, value: any) => () => {
    if (id && key === "mode") {
      if (value === modeArray.length) {
        value = 0;
        setMode(0);
      }
      dispatch(
        fetchDeviceControl({
          id,
          token,
          body: {
            [key]: modeArray[value],
          },
        })
      );
      setMode(value);
    } else if (id && key === "wind") {
      if (value === windArray.length) {
        value = 0;
        setWind(0);
      }
      dispatch(
        fetchDeviceControl({
          id,
          token,
          body: {
            [key]: windArray[value],
          },
        })
      );
      setWind(value);
    } else {
      id &&
        dispatch(
          fetchDeviceControl({
            id,
            token,
            body: {
              [key]: value,
            },
          })
        );
    }
  };

  return (
    <>
      <section className="device-setting-body air-conditioner-remote-wrap">
        <div className="device-setting-top">
          <h1>{deviceName}</h1>
          <div className="device-setting-header">
            <div className="remain-battery">
              <p>{spaceName ? spaceName : "미지정"}</p>
            </div>
            <Button
              onClick={handleControl("power", !status?.power)}
              className="power-button"
            >
              <img
                src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/Power.png`}
                alt="power"
              />
            </Button>
          </div>
        </div>
        <div
          className={`device-display air-conditioner ${
            !status?.power && "disabled"
          }`}
        >
          <h2>에어컨 상태</h2>
          <div className={status?.power1 ? "display active" : "display"}>
            <div className="top">
              <h1>
                {["2", "4"].includes(status?.mode.toString())
                  ? "-"
                  : `${status?.temperature}°C`}
              </h1>
              <p>희망 온도</p>
            </div>

            <div className="bottom">
              <div>
                <span>
                  {aircondition.mode[status?.mode as "0" | "1" | "3" | "4"]}
                </span>
                <p>모드</p>
              </div>
              <div>
                <span>
                  {aircondition.wind[status?.wind as "0" | "1" | "2" | "3"]}
                </span>
                <p>풍속</p>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`temperature-control air-conditioner ${
            !status?.power && "disabled"
          }`}
        >
          <Button
            onClick={handleControl("temperature", status?.temperature - 1)}
            disabled={["2", "4"].includes(status?.mode.toString())}
          >
            <img
              src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/remote-minus.png`}
              alt="remote-minus"
            />
          </Button>
          <div className="display temperature">
            <h3>온도</h3>
            <p>
              {["2", "4"].includes(status?.mode.toString())
                ? "-"
                : `${status?.temperature}°C`}
            </p>
          </div>
          <Button
            onClick={handleControl("temperature", status?.temperature + 1)}
            disabled={["2", "4"].includes(status?.mode.toString())}
          >
            <img
              src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/remote-plus.png`}
              alt="remote-plus"
            />
          </Button>
        </div>
        <div
          className={`air-conditioner-control ${!status?.power && "disabled"}`}
        >
          <div className="control-item">
            <Button onClick={handleControl("mode", mode + 1)}>
              <img
                src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/${
                  aircondition.modeImage[
                    status?.mode as "0" | "1" | "2" | "3" | "4"
                  ]
                }.png`}
                alt={`${
                  aircondition.modeImage[
                    status?.mode as "0" | "1" | "2" | "3" | "4"
                  ]
                }`}
              />
              <p>
                {aircondition.mode[status?.mode as "0" | "1" | "2" | "3" | "4"]}
              </p>
            </Button>
            <p>모드</p>
          </div>
          <div className="control-item">
            <Button
              onClick={handleControl("wind", wind + 1)}
              disabled={["2", "4"].includes(status?.mode.toString())}
              className={`${
                ["2", "4"].includes(status?.mode.toString()) && "disabled"
              }`}
            >
              <img
                src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/${
                  aircondition.windImage[status?.wind as "0" | "1" | "2" | "3"]
                }.png`}
                alt={`${
                  aircondition.windImage[status?.wind as "0" | "1" | "2" | "3"]
                }`}
              />
              <p>{aircondition.wind[status?.wind as "0" | "1" | "2" | "3"]}</p>
            </Button>
            <p>풍속</p>
          </div>
          <div className="control-item more">
            <Button>
              <img
                src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/horizontal-menu.png`}
                alt="horizontal-menu"
              />
            </Button>
            <p>더보기</p>
          </div>
        </div>
      </section>
    </>
  );
};

export default AirConditionPanel;
