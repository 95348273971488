import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchAccountRegister, joinSelector } from "../../../store/join";
import { AppDispatch } from "../../../store";
import AccountForm from "../../common/account-form/AccountForm";
import { Button } from "antd";
import useQuery from "../../../utils/useQuery";

function JoinStep5() {
  const { form, joinSuccess, loading } = useSelector(joinSelector);
  const navigate = useNavigate();
  const query = useQuery();
  const step = Number(query.get("step"));

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (
      !form.terms1 ||
      !form.terms2 ||
      !form.terms3 ||
      !form.email ||
      !form.certificationNumber ||
      !form.password2
    )
      navigate("/login/join?step=1");
  }, []);

  useEffect(() => {
    joinSuccess && navigate(`/login/join?step=${step + 1}`);
  }, [joinSuccess]);

  const onNextClick = useCallback(async () => {
    await dispatch(
      fetchAccountRegister({
        email: form.email as string,
        password: form.password1 as string,
        name: form.username as string,
      })
    );
  }, [dispatch, form.email, form.password1, form.username]);

  return (
    <div>
      <AccountForm />
      <div className="join-footer">
        <Button
          type="primary"
          disabled={!form.username || form.username === "" || loading}
          onClick={onNextClick}
        >
          {loading ? "loading..." : "다음"}
        </Button>
      </div>
    </div>
  );
}

export default JoinStep5;
