import { apiGet } from "../../common/api";

export type HistoryType = {
  [key: string]: {
    deviceName: string;
    deviceType: string;
    spaceName: string | null;
    data: any;
  };
};

type HistoryResponseType = {
  status: number;
  result: HistoryType;
};

export const getHistory = async (
  startTime: number | string,
  endTime?: number | string,
  historyTabType?: string
) => {
  try {
    const { data } = await apiGet(
      `/v1.0/openapi/history?startTime=${startTime}${
        endTime ? `&endTime=${endTime}` : ""
      }${historyTabType ? `&type=${historyTabType}` : ""}`
    );
    return data as HistoryResponseType;
  } catch (e) {
    throw e;
  }
};
