import { Button, Modal } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkCodeSelector, updateVisible } from "../../../store/check-code";
import { AppDispatch } from "../../../store";
import "./CertificationModal.scss";
function CertificationModal() {
  const { visible } = useSelector(checkCodeSelector);
  const dispatch = useDispatch<AppDispatch>();
  const onAgreeClick = () => {
    dispatch(updateVisible(false));
  };
  return (
    <Modal
      className="code-modal"
      visible={visible}
      onOk={onAgreeClick}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={onAgreeClick}
          htmlType="submit"
        >
          확인
        </Button>,
      ]}
    >
      <h1>인증번호를 다시 보낼 수 없습니다.</h1>
      <p>
        인증 번호 다시 보내기를 5회 이상 시도했습니다. 잠시 후 다시
        시도해주세요.
      </p>
    </Modal>
  );
}

export default CertificationModal;
