import React from "react";

const CctvMultiViewSizeIcon = () => {
  return (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.63.833h.74c1.37 0 2.448 0 3.315.071.885.073 1.625.223 2.297.565a5.833 5.833 0 0 1 2.549 2.55c.342.671.492 1.41.565 2.296.07.868.07 1.946.07 3.315v.74c0 1.37 0 2.448-.07 3.315-.073.885-.223 1.625-.565 2.297a5.833 5.833 0 0 1-2.55 2.549c-.671.342-1.41.492-2.296.565-.867.07-1.946.07-3.315.07h-.74c-1.37 0-2.447 0-3.315-.07-.885-.073-1.625-.223-2.297-.565a5.833 5.833 0 0 1-2.549-2.55c-.342-.671-.492-1.41-.565-2.296-.07-.867-.07-1.946-.07-3.315v-.74c0-1.37 0-2.447.07-3.315.073-.885.223-1.625.565-2.297A5.833 5.833 0 0 1 4.02 1.47c.671-.342 1.41-.492 2.296-.565.868-.07 1.946-.07 3.315-.07ZM6.45 2.565c-.782.064-1.277.187-1.675.39a4.167 4.167 0 0 0-1.82 1.82c-.203.398-.326.893-.39 1.676-.056.695-.064 1.558-.065 2.716h6.667V2.5c-1.158.001-2.021.009-2.716.065Zm4.383-.065v6.667H17.5c-.001-1.158-.009-2.021-.065-2.716-.064-.783-.186-1.278-.39-1.676a4.167 4.167 0 0 0-1.82-1.82c-.398-.203-.893-.326-1.675-.39-.696-.056-1.56-.064-2.717-.065Zm6.667 8.333h-6.667V17.5c1.158-.001 2.021-.009 2.716-.065.783-.064 1.278-.186 1.676-.39a4.167 4.167 0 0 0 1.82-1.82c.203-.398.326-.893.39-1.675.056-.696.064-1.56.065-2.717ZM9.167 17.5v-6.667H2.5c.001 1.158.009 2.021.065 2.716.064.783.187 1.278.39 1.676a4.167 4.167 0 0 0 1.82 1.82c.398.203.893.326 1.676.39.695.056 1.558.064 2.716.065Z"
        fill="#fff"
      />
    </svg>
  );
};

export default CctvMultiViewSizeIcon;
