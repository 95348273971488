import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../index";
import { sleep } from "../../../common/utils";
import { CctvDetailType, getCctvDetail } from "../../../api/cctv/cctv";

type CctvDetailState = {
  loading: boolean;
  error?: string | Error;
  detail?: CctvDetailType;
};

const initialState: CctvDetailState = {
  loading: false,
  error: undefined,
  detail: undefined,
};

export const fetchGetCctvDetail = createAsyncThunk(
  "cctv/detail",
  async (deviceId: number) => {
    await sleep(100);
    const { result } = await getCctvDetail(deviceId);
    return result;
  }
);

const slice = createSlice({
  name: "cctv/detail",
  initialState,
  reducers: {
    resetCctvDetail: (state) => ({
      ...state,
      cctvList: initialState.detail,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetCctvDetail.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(fetchGetCctvDetail.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = undefined;
      state.detail = payload;
    });
    builder.addCase(fetchGetCctvDetail.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    });
  },
});

export const cctvDetailSelector = (state: RootState) => state.cctv.detail;
export const { resetCctvDetail } = slice.actions;

export default slice;
