import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sleep } from "../../common/utils";
import { SendCodeRequest, sendEmailCode } from "../../api/join/join";
import { RootState } from "../index";

type SendCode = {
  loading: boolean;
  error?: string | Error;
  success: boolean | undefined;
  code: boolean | undefined;
};

const initialState: SendCode = {
  loading: false,
  error: undefined,
  success: undefined,
  code: undefined,
};

export const fetchSendCode = createAsyncThunk(
  "send-code",
  async (body: SendCodeRequest) => {
    await sleep(100);
    const { result } = await sendEmailCode(body);
    return result;
  }
);

const slice = createSlice({
  name: "send-code",
  initialState,
  reducers: {
    resetSend: (state) => ({
      ...state,
      success: initialState.success,
      code: initialState.code,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSendCode.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(
      fetchSendCode.fulfilled,
      (state, { payload: { success, code } }) => {
        state.loading = false;
        state.error = undefined;
        state.success = success;
        state.code = code;
      }
    );
    builder.addCase(fetchSendCode.rejected, (state, { error }) => {
      state.loading = false;
      state.success = undefined;
      state.code = undefined;
      state.error = error.message;
    });
  },
});

export const sendCodeSelector = (state: RootState) => state.sendCode;
export const { resetSend } = slice.actions;

export default slice;
