import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../index";

type LayoutState = {
  menu: string;
  id: number;
};

const initialState: LayoutState = {
  menu: "",
  id: 0,
};

const slice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    resetLayout: (state: LayoutState) => ({
      ...state,
      menu: initialState.menu,
      id: initialState.id,
    }),
    updateLayout: (state: LayoutState, { payload }) => ({
      ...state,
      menu: payload,
    }),
    updateLayoutId: (state: LayoutState, { payload }) => ({
      ...state,
      id: payload,
    }),
  },
});

export const layoutSelector = (state: RootState) => state.layout;
export const { updateLayout, resetLayout, updateLayoutId } = slice.actions;

export default slice;
