import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "./store";
import { BrowserRouter as Router } from "react-router-dom";
import { ConfigProvider as AntdProvider } from "antd";
import locale from "antd/lib/locale/ko_KR";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import "antd/dist/antd.variable.min.css";
import "./index.css";

AntdProvider.config({
  theme: {
    primaryColor: "#00A576",
    errorColor: "#D0767B",
    warningColor: "#FFC846",
    successColor: "#00A872",
    infoColor: "#00A872",
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <Router>
        <AntdProvider locale={locale}>
          <App />
        </AntdProvider>
      </Router>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
