import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sleep } from "../../common/utils";
import { checkCode, CheckCodeRequest } from "../../api/join/join";
import { RootState } from "../index";

type CheckCode = {
  loading: boolean;
  error?: string | Error;
  check: boolean;
  visible: boolean;
};

const initialState: CheckCode = {
  loading: false,
  error: undefined,
  check: false,
  visible: false,
};

export const fetchCheckCode = createAsyncThunk(
  "check-code",
  async (body: CheckCodeRequest) => {
    await sleep(100);
    const { result } = await checkCode(body);
    return result;
  }
);

const slice = createSlice({
  name: "check-code",
  initialState,
  reducers: {
    resetCheck: (state) => ({
      ...state,
      check: initialState.check,
      visible: initialState.visible,
    }),
    updateVisible: (state, { payload }) => ({
      ...state,
      visible: payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCheckCode.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(fetchCheckCode.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = undefined;
      state.check = payload;
    });
    builder.addCase(fetchCheckCode.rejected, (state, { error }) => {
      state.loading = false;
      state.check = false;
      state.error = error as Error;
    });
  },
});

export const checkCodeSelector = (state: RootState) => state.checkCode;
export const { resetCheck, updateVisible } = slice.actions;

export default slice;
