import { apiGet, apiPost } from "../../common/api";

type DashboardListResponseType = {
  status: number;
  result: {
    id: number;
    dashboardName: string;
  }[];
};

type createDashbardRequestType = {
  name: string;
};

type createDashboardResponseType = {
  status: number;
  result: boolean;
  message: string;
};

export const getDashboardList = async () => {
  try {
    const { data } = await apiGet(`/v1.0/openapi/dashboard`);
    return data as DashboardListResponseType;
  } catch (e) {
    throw e;
  }
};

export const createDashboard = async (body: createDashbardRequestType) => {
  try {
    const { data } = await apiPost(`/v1.0/openapi/dashboard`, body);
    return data as createDashboardResponseType;
  } catch (e) {
    throw e;
  }
};
