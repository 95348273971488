import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Modal } from "antd";
const md5 = require("md5");
import { passwordRegex } from "../../../utils/regax";
import {
  fetchUpdateAccountPassword,
  settingAccountUpdatePasswordSelector,
} from "../../../store/setting/account/updatePassword";
import { AppDispatch } from "../../../store";
import "./SettingContent.scss";

interface popupProps {
  visible: boolean;
  onClickOk: any;
  onClickCancel: any;
}

export function ChangePasswordPopup(props: popupProps) {
  const dispatch = useDispatch<AppDispatch>();
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(true);
  const [newPasswordValid, setNewPasswordValid] = useState(true);
  const [newRepeatPasswordValid, setRepeatNewPasswordValid] = useState(true);
  const { loading: updateLoading, success: updateResult } = useSelector(
    settingAccountUpdatePasswordSelector
  );

  useEffect(() => {
    if (updateResult) props.onClickOk();
  }, [updateLoading, updateResult]);

  const onCLickSubmit = () => {
    if (password === "") {
      alert("기존 비밀번호를 입력하세요.");
      setPasswordValid(false);
    } else if (!passwordValid) {
      alert("기존 비밀번호가 규칙에 맞지 않습니다.");
    } else if (newPassword === "" || repeatNewPassword === "") {
      alert("변경할 패스워드를 입력하세요.");
      setNewPasswordValid(false);
      setRepeatNewPasswordValid(false);
    } else if (!passwordValid || !newPasswordValid) {
      alert("패스워드가 규칙에 맞지 않습니다.");
      !passwordValid && setNewPasswordValid(false);
      !newPasswordValid && setNewPasswordValid(false);
    } else if (newPassword !== repeatNewPassword) {
      alert("새로운 패스워드가 일치하지 않습니다.");
      setNewPasswordValid(false);
      setRepeatNewPasswordValid(false);
    } else {
      dispatch(
        fetchUpdateAccountPassword({
          oldPassword: md5(password),
          password: md5(repeatNewPassword),
        })
      );
    }
  };

  const inputPassword = (type: string, e: ChangeEvent<HTMLInputElement>) => {
    const isValid = passwordRegex(e.target.value);

    if (type === "password") {
      setPassword(e.target.value);
      setPasswordValid(isValid);
    } else if (type === "newPassword") {
      setNewPassword(e.target.value);
      setNewPasswordValid(isValid);
    } else if (type === "repeatNewPassword") {
      setRepeatNewPassword(e.target.value);
      setRepeatNewPasswordValid(isValid);
    }
  };

  return (
    <Modal
      className={"Change-Password-Modal"}
      visible={props.visible}
      closable={false}
      footer={[
        <button
          key={"cancelBtn"}
          className="Change-Password-Button-Cancel"
          onClick={props.onClickCancel}
        >
          취소
        </button>,
        <button
          key={"okBtn"}
          className="Change-Password-Button-Ok"
          onClick={onCLickSubmit}
        >
          변경
        </button>,
      ]}
    >
      <h1>비밀번호 변경</h1>
      <p className="Change-Password-Desc">
        보안을 위해 새로운 비밀번호를 설정해주세요
      </p>
      <div
        className={
          passwordValid
            ? "Change-Password-Input-Original-Desc-Normal"
            : "Change-Password-Input-Original-Desc-Warning"
        }
      >
        <p className="Change-Password-Input-Title">현재 비밀번호</p>
        <Input.Password
          bordered={false}
          defaultValue={password}
          placeholder={"현재 사용중인 비밀번호를 입력해주세요."}
          onChange={(e) => {
            inputPassword("password", e);
          }}
        />
      </div>
      <div
        className={
          newPasswordValid
            ? "Change-Password-Input-New-Desc-Normal"
            : "Change-Password-Input-New-Desc-Warning"
        }
      >
        <p className="Change-Password-Input-Title">새 비밀번호</p>
        <Input.Password
          bordered={false}
          defaultValue={newPassword}
          placeholder={"새로운 비밀번호를 입력해주세요."}
          onChange={(e) => {
            inputPassword("newPassword", e);
          }}
        />
      </div>
      <div
        className={
          newRepeatPasswordValid
            ? "Change-Password-Input-Repeat-Desc-Normal"
            : "Change-Password-Input-Repeat-Desc-Warning"
        }
      >
        <p className="Change-Password-Input-Title">새 비밀번호 재입력</p>
        <Input.Password
          bordered={false}
          defaultValue={repeatNewPassword}
          placeholder={"8~12자 사이의 숫자,영문 소문자, 특수문자"}
          onChange={(e) => {
            inputPassword("repeatNewPassword", e);
          }}
        />
      </div>
    </Modal>
  );
}
