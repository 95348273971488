import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sleep } from "../../../../common/utils";
import { RootState } from "../../../index";
import { getReceiptDetail } from "../../../../api/pos/pos";

type ReceiptHistory = {
  id: string;
  posId: string;
  saleTime: string;
  salesType: string;
  salesAmount: string;
  saleList: {
    product_code: string;
    product_name: string;
    quantity: string;
    price: string;
    discount: string;
  }[];
};

type Receipt = {
  loading: boolean;
  error?: string | Error;
  receipt: ReceiptHistory;
};

const initialState: Receipt = {
  loading: false,
  error: undefined,
  receipt: {
    id: "",
    posId: "",
    saleTime: "",
    salesType: "",
    salesAmount: "",
    saleList: [],
  },
};

export const fetchGetReceipt = createAsyncThunk(
  "airPos/receipt",
  async (receiptId: string) => {
    await sleep(100);

    const { result } = await getReceiptDetail({
      receiptId: receiptId,
    });

    return result;
  }
);

const slice = createSlice({
  name: "airPos/receipt",
  initialState,
  reducers: {
    resetPosReceipt: (state) => ({
      ...state,
      receipt: initialState.receipt,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetReceipt.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(fetchGetReceipt.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = undefined;
      state.receipt = payload;
    });
    builder.addCase(fetchGetReceipt.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    });
  },
});

export const posReceiptSelector = (state: RootState) => state.pos.posReceipt;
export const { resetPosReceipt } = slice.actions;

export default slice;
