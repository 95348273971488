import React from "react";
import LightPanel from "../components/panels/light";
import PowerStripPanel from "../components/panels/powerStrip";
import PlugPanel from "../components/panels/plug";
import SwitchPanel from "../components/panels/switch";
import AlarmPanel from "../components/panels/alarm";
import RelayPanel from "../components/panels/relay";
import SensorPanel from "../components/panels/sensor";
import SensorThPanel from "../components/panels/sensorTh";
import RemoteHubPanel from "../components/panels/remoteHub";
import TvRemotePanel from "../components/panels/tvRemote";
import AirConditionPanel from "../components/panels/aircondition";
import DoorLockPanel from "../components/panels/doorLock";

export const getDevicePanel = (
  deviceType: string,
  token?: string,
  app?: string
) => {
  switch (deviceType) {
    case "lightRgbw":
    case "ledStripRgbw":
    case "moodLight":
    case "lightWw":
      return <LightPanel action={() => {}} token={token} />;
    case "powerStrip":
      return <PowerStripPanel action={() => {}} token={token} />;
    case "switch1":
    case "switch2":
    case "switch3":
    case "switch4":
    case "switch5":
    case "switch6":
    case "zigbeeSwitch1":
    case "zigbeeSwitch2":
    case "zigbeeSwitch3":
      return <SwitchPanel action={() => {}} token={token} />;
    case "bruntPlug":
    case "plug":
      return <PlugPanel action={() => {}} token={token} />;
    case "relayController":
    case "relayControllerDc":
      return <RelayPanel action={() => {}} token={token} />;
    case "audibleAlarm":
      return <AlarmPanel action={() => {}} token={token} />;
    case "sensorWater":
    case "sensorSmoke":
    case "sensorGas":
    case "sensorDo":
    case "sensorMo":
      return <SensorPanel action={() => {}} deviceType={deviceType} />;
    case "sensorTh":
    case "sensorThMeter":
    case "sensorRefTh":
      return (
        <SensorThPanel
          action={() => {}}
          token={token}
          deviceType={deviceType}
        />
      );
    case "smartHubRemote":
      return <RemoteHubPanel action={() => {}} token={token} app={app} />;
    case "smartHubTv":
      return <TvRemotePanel action={() => {}} token={token} />;
    case "smartHubAircondition":
      return <AirConditionPanel action={() => {}} token={token} />;
    case "zigbeeDoorLock":
    case "zigbeePushPullDoorLock":
      return <DoorLockPanel action={() => {}} token={token} />;
  }
};
