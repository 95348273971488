import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { sleep } from "../../common/utils";
import { getCctvList } from "../../api/cctv/cctv";

type CctvState = {
  loading: boolean;
  error?: string | Error;
  cctvList: {
    id: number;
    device_name: string;
    device_id: string;
    pid: string;
    thumbnailUrl: string;
  }[];
};

const initialState: CctvState = {
  loading: false,
  error: undefined,
  cctvList: [],
};

export const fetchGetCctvList = createAsyncThunk(
  "cctv/main",
  async (currentSpaceId?: number) => {
    await sleep(100);
    const { result } = await getCctvList(currentSpaceId);
    return result;
  }
);

const slice = createSlice({
  name: "cctv/main",
  initialState,
  reducers: {
    resetCctvList: (state) => ({
      ...state,
      cctvList: initialState.cctvList,
      loading: initialState.loading,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetCctvList.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(fetchGetCctvList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = undefined;
      state.cctvList = payload;
    });
    builder.addCase(fetchGetCctvList.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    });
  },
});

export const cctvListSelector = (state: RootState) => state.cctv.list;
export const { resetCctvList } = slice.actions;

export default slice;
