import { apiGet, apiPost } from "../../common/api";

type PosListResponseType = {
  code: string;
  message: string;
  result: {
    id: string;
    device_name: string;
  }[];
};

type PosHistoryResponseType = {
  code: string;
  message: string;
  result: {
    id: string;
    posId: string;
    saleTime: string;
    salesType: string;
    salesAmount: string;
  }[];
};

type SummaryRateEntity = {
  totalAmount: string;
  salesCount: string;
  rateType: string;
  rate: string;
};

type commonSummary = {
  rateOfSalesDaily: SummaryRateEntity;
  rateOfSalesYesterday: SummaryRateEntity;
  rateOfSalesYesterdayJust: SummaryRateEntity;
  rateOfSalesWeekly: SummaryRateEntity;
  rateOfSalesMonthly: SummaryRateEntity;
};

type PosSummaryDaily = {
  totalAmount: string;
  rateOfSalesDaily: {
    type: string;
    amount: string;
    rate: string;
    salesCount: string;
  };
  records: {
    id: string;
    posId: string;
    saleTime: string;
    salesType: string;
    salesAmount: string;
  }[];
};

type PosSummaryWeekly = {
  Day: string;
  history:
    | {
        totalAmount: string;
        records: {
          id: string;
          posId: string;
          saleTime: string;
          salesType: string;
          salesAmount: string;
        }[];
      }
    | string;
  isBefore: boolean;
}[];

type SaleEntity = {
  amount: string;
  saleCount: string;
};

type PosSummaryMonthly = {
  rateOfSalesMonthly: {
    type: string;
    amount: string;
    salesCount: string;
    rateType: string;
    rate: string;
  }[];
  records: {
    id: string;
    salesTime: string;
    rateOfSales: {
      salesAll: SaleEntity;
      salesCard: SaleEntity;
      saleCash: SaleEntity;
    };
  }[];
};

type PosSummaryResponseType = {
  code: string;
  message: string;
  result: commonSummary;
};

type PosDailySummaryResponseType = {
  code: string;
  message: string;
  result: PosSummaryDaily;
};
type PosWeeklySummaryResponseType = {
  result: PosSummaryWeekly;
};

type PosMonthlySummaryResponseType = {
  code: string;
  message: string;
  result: PosSummaryMonthly;
};

type PosMappingInfo = {
  code: string;
  message: string;
  result: {
    id: string;
    device_id: string;
    device_name: string;
    pid: string;
    mapping_info: {
      id: number;
      pos: number;
      camera: {
        id: number;
        space_id: number;
        device_id: number;
      };
    };
  };
};

type ReceiptDetailInfo = {
  code: string;
  message: string;
  result: {
    id: string;
    posId: string;
    saleTime: string;
    salesType: string;
    salesAmount: string;
    saleList: {
      product_code: string;
      product_name: string;
      quantity: string;
      price: string;
      discount: string;
    }[];
  };
};

export const getPosList = async (spaceId: number | undefined) => {
  try {
    let query = "";
    if (spaceId) query = `?space=${spaceId}`;
    const { data } = await apiGet(`/v1.0/openapi/pos${query}`);
    return data as PosListResponseType;
  } catch (e) {
    throw e;
  }
};

export const getPosHistory = async ({
  date,
  deviceId,
}: {
  date: string;
  deviceId: string;
}) => {
  try {
    let query = "";
    if (date) query = `?date=${date}`;

    const { data } = await apiGet(
      `/v1.0/openapi/pos/sales/history/${deviceId}${query}`
    );

    return { result: data } as PosHistoryResponseType;
  } catch (e) {
    throw e;
  }
};

export const getPosSummaryMonthly = async ({
  date,
  deviceId,
}: {
  date: string;
  deviceId?: number;
}) => {
  try {
    let query = "";
    if (date) query = `?date=${date}`;
    if (deviceId) query += `&id=${deviceId}`;

    const { data } = await apiGet(
      `/v1.0/openapi/pos/sales/history/summary/monthly${query}`
    );

    return { result: data } as PosMonthlySummaryResponseType;
  } catch (e) {
    throw e;
  }
};

export const getPosSummaryCommon = async ({
  date,
  deviceId,
}: {
  date: string;
  deviceId?: number;
}) => {
  try {
    let query = "";
    if (date) query = `?date=${date}`;
    if (deviceId) query += `&id=${deviceId}`;

    const { data } = await apiGet(
      `/v1.0/openapi/pos/sales/history/summary/common${query}`
    );

    return { result: data } as PosSummaryResponseType;
  } catch (e) {
    throw e;
  }
};

export const getPosSummaryDaily = async ({
  date,
  deviceId,
}: {
  date: string;
  deviceId?: number;
}) => {
  try {
    let query = "";
    if (date) query = `?date=${date}`;
    if (deviceId) query += `&id=${deviceId}`;

    const { data } = await apiGet(
      `/v1.0/openapi/pos/sales/history/summary/daily${query}`
    );

    return { result: data } as PosDailySummaryResponseType;
  } catch (e) {
    throw e;
  }
};

export const getPosSummaryWeekly = async ({
  date,
  deviceId,
}: {
  date: string;
  deviceId?: number;
}) => {
  try {
    let query = "";
    if (date) query = `?date=${date}`;
    if (deviceId) query += `&id=${deviceId}`;

    const { data } = await apiGet(
      `/v1.0/openapi/pos/sales/history/summary/weekly${query}`
    );

    return { result: data } as PosWeeklySummaryResponseType;
  } catch (e) {
    throw e;
  }
};

export const getPosMapping = async ({ id }: { id: string }) => {
  try {
    const { data } = await apiGet(`/v1.0/openapi/pos/mapping/${id}`);
    return data as PosMappingInfo;
  } catch (e) {
    throw e;
  }
};

export const postPosMapping = async (body: {
  id: number;
  camera: number;
  pos: number;
  isNew: boolean;
}) => {
  try {
    const { data } = await apiPost(`/v1.0/openapi/pos/mapping`, body);
    return data as PosMappingInfo;
  } catch (e) {
    throw e;
  }
};

export const getReceiptDetail = async ({
  receiptId,
}: {
  receiptId: string;
}) => {
  try {
    const { data } = await apiGet(
      `/v1.0/openapi/pos/receipt/${receiptId}/detail`
    );
    return data as ReceiptDetailInfo;
  } catch (e) {
    throw e;
  }
};
