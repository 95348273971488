import React from "react";
import loadable from "@loadable/component";
import { Route, Routes } from "react-router-dom";

const Home = loadable(
  () => import(/* webpackChunkName: "Home" */ "./pages/Home")
);
const Dashboard = loadable(
  () => import(/* webpackChunkName: "Dashboard" */ "./pages/Dashboard")
);
const Space = loadable(
  () => import(/* webpackChunkName: "Space" */ "./pages/Space")
);
const SpaceDetail = loadable(
  () => import(/* webpackChunkName: "SpaceDetail" */ "./pages/SpaceDetail")
);
const Recipe = loadable(
  () => import(/* webpackChunkName: "Recipe" */ "./pages/Recipe")
);

const Cctv = loadable(
  () => import(/* webpackChunkName: "Cctv" */ "./pages/Cctv")
);

const SellManage = loadable(
  () => import(/* webpackChunkName: "SellManage" */ "./pages/SellManage")
);

const SellManageDetail = loadable(
  () =>
    import(
      /* webpackChunkName: "SellManageDetail" */ "./pages/SellManageDetail"
    )
);

const CctvDetail = loadable(
  () => import(/* webpackChunkName: "CctvDetail" */ "./pages/CctvDetail")
);

const Histories = loadable(
  () => import(/* webpackChunkName: "Histories" */ "./pages/Histories")
);

function AppRoute() {
  return (
    <Routes>
      <Route path="/dashboard/*" element={<Dashboard />}>
        <Route path=":id" element={<Dashboard />} />
      </Route>
      <Route
        path="/space/*"
        element={
          <Routes>
            <Route path="/" element={<Space />} />
            <Route path="/detail" element={<SpaceDetail />} />
          </Routes>
        }
      />

      <Route path="/recipe/*" element={<Recipe />} />

      <Route
        path="/cctv/*"
        element={
          <Routes>
            <Route path="/" element={<Cctv />} />
            <Route path="/detail" element={<CctvDetail />} />
          </Routes>
        }
      />
      <Route
        path="/sellManage/*"
        element={
          <Routes>
            <Route path="/" element={<SellManage />} />
            <Route path="/detail" element={<SellManageDetail />} />
          </Routes>
        }
      />
      <Route
        path="/history/*"
        element={
          <Routes>
            <Route path="/" element={<Histories />} />
          </Routes>
        }
      />
    </Routes>
  );
}

export default AppRoute;
