import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  resetAirposModalVisible,
  settingAirposSelector,
  updateAirposModalVisible,
} from "../../../../store/setting/airpos";
import { AppDispatch } from "../../../../store";
import AirposLogin from "./step1/AirposLogin";
import AirposDeviceList from "./step2/AirposDeviceList";
import AirposCameraSetting from "./step3/AirposCameraSetting";
import { resetAirposList } from "../../../../store/setting/airpos/list";
import AirposComplete from "./step4/AirposComplete";
import { fetchGetSyncPlatformList } from "../../../../store/setting/account/syncPlatforms";

function AirposModal() {
  const { visible, step } = useSelector(settingAirposSelector);
  const [id, setId] = useState("고퀄다점포");
  const [password, setPassword] = useState("air.123");
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (!visible) {
      dispatch(fetchGetSyncPlatformList());
      dispatch(resetAirposModalVisible());
      dispatch(resetAirposList());
    }
  }, [visible]);
  const onCancel = () => {
    dispatch(fetchGetSyncPlatformList());
    dispatch(updateAirposModalVisible(false));
  };
  return (
    <Modal
      className="airpos-modal"
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <img
        src={`${process.env.REACT_APP_S3_URL}/1.0/logos/airpos_logo.png`}
        alt="airpos_logo"
      />
      {step === 1 && (
        <AirposLogin
          id={id}
          password={password}
          setId={setId}
          setPassword={setPassword}
        />
      )}
      {step === 2 && <AirposDeviceList id={id} password={password} />}
      {step === 3 && <AirposCameraSetting />}
      {step === 4 && <AirposComplete />}
    </Modal>
  );
}

export default AirposModal;
