import React, { useEffect, useMemo, useState } from "react";
import "moment/locale/ko";
import Battery from "../../assets/img/dashboard/battery";
import { useDispatch, useSelector } from "react-redux";
import { panelDetailSelector } from "../../store/panel/detail";
import { DeviceDetailType } from "../../api/devices/device";
import { Switch, Button } from "antd";
import { AppDispatch } from "../../store";
import { fetchDeviceControl } from "../../store/panel/control";
import moment from "moment";
import { datetimeFormat } from "../../common/utils";

const DoorLockPanel = ({ action, token }: { action: any; token?: string }) => {
  const [noDetected, setNoDetected] = useState(false);
  const [remote, setRemote] = useState(false);
  const { detail } = useSelector(panelDetailSelector);
  const { id, status, deviceName, spaceName, time } =
    detail as DeviceDetailType;

  const eventTime = useMemo(() => {
    return moment(time).format(datetimeFormat);
  }, [time]);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    status && status.alarmSwitch && !noDetected && setNoDetected(true);
  }, [status?.alarmSwitch]);

  const onClickDeviceControl = (key: string) => () => {
    //dispatch(fetchDeviceControl());
  };

  const onChangeRemote = () => {
    setRemote((prevState) => {
      return !prevState;
    });
  };

  return (
    <section className="device-setting-body doorLock">
      <div className="device-setting-top">
        <h1>{deviceName}</h1>
        <div className="device-setting-header">
          <div className="remain-battery">
            <Battery batteryLevel={status?.battery} />
            <p>{spaceName ? spaceName : "미지정"}</p>
          </div>
        </div>
      </div>
      <div
        className={`device-display doorLock status ${
          status?.open ? "detected" : ""
        }`}
      >
        <h2>문 상태</h2>
        <div className="display">
          <h1>{status?.open ? "열림" : "닫힘"}</h1>
          <p className="detected-time-stamp">{time ? eventTime : "없음"}</p>
        </div>
      </div>
      <div className="temporary-password-setting">
        <h3>임시 비밀번호 추가</h3>
        <div className="password-button">
          <Button onClick={onClickDeviceControl("")}>
            <img
              src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/recipe_time_icon.png`}
              alt="time"
            />
            <p>기간제</p>
          </Button>
          <Button onClick={onClickDeviceControl("")}>
            <img
              src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/recipe_register_oneclick_icon.png`}
              alt="once"
            />
            <p>일회용</p>
          </Button>
        </div>
      </div>
      <div className={`doorLock-control-container ${remote && "remote"}`}>
        <div className="control-header">
          <h3>원격 제어</h3>
          <Switch size="small" checked={remote} onChange={onChangeRemote} />
        </div>
        {remote && (
          <div className="door-button">
            <Button onClick={onClickDeviceControl("")}>
              <img
                src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/door_opened.png`}
                alt="once"
              />
              <p>문 열기</p>
            </Button>
            <Button onClick={onClickDeviceControl("")}>
              <img
                src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/door_closed.png`}
                alt="once"
              />
              <p>문 닫기</p>
            </Button>
          </div>
        )}
      </div>
    </section>
  );
};

export default DoorLockPanel;
