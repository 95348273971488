import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetPasswordSelector } from "../../../store/reset-password";
import { checkCodeSelector, fetchCheckCode } from "../../../store/check-code";
import useQuery from "../../../utils/useQuery";
import CertificationForm from "../../common/certification-form/CertificationForm";
import { AppDispatch } from "../../../store";
import { Button } from "antd";

function ResetPasswordStep2() {
  const { form } = useSelector(resetPasswordSelector);
  const { check, loading } = useSelector(checkCodeSelector);

  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();
  const query = useQuery();
  const step = Number(query.get("step"));

  useEffect(() => {
    !form.email && navigate("/login/reset-password?step=1");
  }, []);

  useEffect(() => {
    check && navigate(`/login/reset-password?step=${step + 1}`);
  }, [check]);

  const onNextClick = useCallback(async () => {
    if (form.certificationNumber)
      await dispatch(
        fetchCheckCode({
          email: form.email as string,
          code: form.certificationNumber,
          auth_type: 2,
        })
      );
  }, [form, dispatch]);

  return (
    <div>
      <CertificationForm />
      <div className="reset-password-footer">
        <Button
          type="primary"
          disabled={!form.certificationNumber || loading}
          onClick={onNextClick}
        >
          {loading ? "loading..." : "다음"}
        </Button>
      </div>
    </div>
  );
}

export default ResetPasswordStep2;
