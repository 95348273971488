import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../index";

type AirposState = {
  visible: boolean;
  step: number;
  pos?: {
    name: string;
    space: { id: number; name: string };
    camera: { id: number; name: string };
  };
  peristalsis: boolean;
};

const initialState: AirposState = {
  visible: false,
  step: 1,
  pos: undefined,
  peristalsis: false,
};

const slice = createSlice({
  name: "setting/airpos/main",
  initialState,
  reducers: {
    resetAirposModalVisible: (state: AirposState) => ({
      ...state,
      visible: initialState.visible,
      step: initialState.step,
      pos: initialState.pos,
      peristalsis: initialState.peristalsis,
    }),
    updateAirposModalVisible: (state: AirposState, { payload }) => ({
      ...state,
      visible: payload,
    }),
    updateAirposStep: (state: AirposState) => ({
      ...state,
      step: state.step === 4 ? 2 : state.step + 1,
    }),
    updateAisposData: (
      state: AirposState,
      { payload: { name, space, camera } }
    ) => ({
      ...state,
      pos: {
        ...state.pos,
        name,
        space: {
          ...state.pos?.space,
          ...space,
        },
        camera: {
          ...state.pos?.camera,
          ...camera,
        },
      },
    }),
    updatePeristalsis: (state: AirposState, { payload }) => ({
      ...state,
      peristalsis: payload,
    }),
  },
});

export const settingAirposSelector = (state: RootState) =>
  state.setting.airpos.main;

export const {
  resetAirposModalVisible,
  updateAirposModalVisible,
  updateAirposStep,
  updateAisposData,
  updatePeristalsis,
} = slice.actions;

export default slice;
