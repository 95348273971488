import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../index";
import { sleep } from "../../../common/utils";
import {
  getDeviceHistoryDetail,
  HistoryDetailType,
} from "../../../api/devices/device";

type HistoryDetailState = {
  loading: boolean;
  error?: string | Error;
  historyList: HistoryDetailType[];
  deviceId: string | undefined;
};

const initialState: HistoryDetailState = {
  loading: false,
  historyList: [],
  deviceId: undefined,
};

export const fetchSearchHistoryDetail = createAsyncThunk(
  "history/detail",
  async ({
    deviceId,
    startTimeStamp,
    endTimeStamp,
    eventCode,
  }: {
    deviceId: string;
    startTimeStamp: number;
    endTimeStamp: number;
    eventCode?: string;
  }) => {
    await sleep(100);
    const { result } = await getDeviceHistoryDetail(
      deviceId,
      startTimeStamp,
      endTimeStamp,
      eventCode
    );
    return result;
  }
);

const slice = createSlice({
  name: "history/detail",
  initialState,
  reducers: {
    resetHistoryDetail: (state: HistoryDetailState) => ({
      ...state,
      historyList: initialState.historyList,
      deviceId: initialState.deviceId,
    }),
    updateDeviceId: (state: HistoryDetailState, { payload }) => ({
      ...state,
      deviceId: payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSearchHistoryDetail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchSearchHistoryDetail.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = undefined;
        state.historyList = payload;
      }
    );
    builder.addCase(fetchSearchHistoryDetail.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message as string;
      state.historyList = [];
    });
  },
});

export const HistoryDetailSelector = (state: RootState) => state.history.detail;
export const { resetHistoryDetail, updateDeviceId } = slice.actions;

export default slice;
