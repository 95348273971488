import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { panelDetailSelector } from "../../store/panel/detail";
import { DeviceDetailType } from "../../api/devices/device";
import { Switch } from "antd";
import { AppDispatch } from "../../store";
import { fetchDeviceControl } from "../../store/panel/control";

const PlugPanel = ({ action, token }: { action: any; token?: string }) => {
  const { detail } = useSelector(panelDetailSelector);
  const { id, status, deviceName, spaceName } = detail as DeviceDetailType;
  const dispatch = useDispatch<AppDispatch>();

  const handlePlugPower = useCallback(() => {
    if (id && status)
      dispatch(
        fetchDeviceControl({
          id,
          token,
          body: { power1: !status.power1 },
        })
      );
  }, [dispatch, status, id]);

  return (
    <section className="device-setting-body">
      <div className="device-setting-top">
        <h1>{deviceName}</h1>
        <div className="device-setting-header">
          <div className="remain-battery">
            <p>{spaceName ? spaceName : "미지정"}</p>
          </div>
          <Switch onChange={handlePlugPower} checked={status?.power1} />
        </div>
      </div>
      <div className="device-display plug">
        <h2>실시간 전력 사용 정보</h2>
        <div className={status?.power1 ? "display active" : "display"}>
          <div className="top">
            <h1>{status?.power1 ? `${status?.curPower}W` : "-"}</h1>
            <p>소비 전력</p>
          </div>

          <div className="bottom">
            <div>
              <span
                className={`plug-current ${status?.power ? "" : "deactive"}`}
              >
                {status?.power1 ? `${status?.curCurrent}mA` : "-"}
              </span>
              <p>전류</p>
            </div>
            <div>
              <span
                className={`plug-voltage ${status?.power ? "" : "deactive"}`}
              >
                {status && status.power1 ? `${status.curVoltage}V` : "-"}
              </span>
              <p>전압</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PlugPanel;
