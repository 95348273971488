import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sleep } from "../../../../../common/utils";
import { RootState } from "../../../../index";
import { getPosSummaryMonthly } from "../../../../../api/pos/pos";

export type conclusion = {
  rateOfSalesMonthly: {
    type: string;
    amount: string;
    salesCount: string;
    rateType: string;
    rate: string;
  }[];
  records: {
    id: string;
    salesTime: string;
    rateOfSales: {
      salesAll: {
        amount: string;
        saleCount: string;
      };
      salesCard: {
        amount: string;
        saleCount: string;
      };
      saleCash: {
        amount: string;
        saleCount: string;
      };
    };
  }[];
};

type MonthConclusion = {
  loading: boolean;
  error?: string | Error;
  monthConclusion: conclusion;
};

const initialState: MonthConclusion = {
  loading: false,
  error: undefined,
  monthConclusion: {
    rateOfSalesMonthly: [],
    records: [],
  },
};

export const fetchGetMonthConclusion = createAsyncThunk(
  "airPos/month/conclusion",
  async ({ date }: { date: string }) => {
    await sleep(100);
    const { result } = await getPosSummaryMonthly({ date: date });
    return result;
  }
);

const slice = createSlice({
  name: "airPos/month/conclusion",
  initialState,
  reducers: {
    resetPosMonthConclusion: (state) => ({
      ...state,
      monthlyConclusion: initialState.monthConclusion,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetMonthConclusion.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(fetchGetMonthConclusion.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = undefined;
      state.monthConclusion = payload;
    });
    builder.addCase(fetchGetMonthConclusion.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    });
  },
});

export const posMonthConclusionSelector = (state: RootState) =>
  state.pos.monthConclusion;
export const { resetPosMonthConclusion } = slice.actions;

export default slice;
