import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sleep } from "../../../common/utils";
import { RootState } from "../../index";
import { DeviceType, getNoSpaceDeviceList } from "../../../api/space/space";

type NoSpaceList = {
  loading: boolean;
  error?: string | Error;
  noSpaceDeviceList: DeviceType[];
};

const initialState: NoSpaceList = {
  loading: false,
  error: undefined,
  noSpaceDeviceList: [],
};

export const fetchGetNoSpaceList = createAsyncThunk(
  "space/noSpaceList",
  async () => {
    await sleep(100);
    const { result } = await getNoSpaceDeviceList();
    return result;
  }
);

const slice = createSlice({
  name: "space/noSpaceList",
  initialState,
  reducers: {
    resetNoSpaceDeviceList: (state) => ({
      ...state,
      noSpaceDeviceList: initialState.noSpaceDeviceList,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetNoSpaceList.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(fetchGetNoSpaceList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = undefined;
      state.noSpaceDeviceList = payload;
    });
    builder.addCase(fetchGetNoSpaceList.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    });
  },
});

export const noSpaceDeviceListSelector = (state: RootState) =>
  state.space.noList;
export const { resetNoSpaceDeviceList } = slice.actions;

export default slice;
