import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../index";

type SpaceDeviceSelectedListState = {
  selectedDeviceList: number[];
  visible: boolean;
  modal: {
    menu: string;
    visible: boolean;
  };
};

const initialState: SpaceDeviceSelectedListState = {
  selectedDeviceList: [],
  visible: false,
  modal: {
    menu: "",
    visible: false,
  },
};
const slice = createSlice({
  name: "space/deviceSetting/list",
  initialState,
  reducers: {
    resetSpaceDeviceSelectedList: (state: SpaceDeviceSelectedListState) => ({
      ...state,
      selectedDeviceList: initialState.selectedDeviceList,
      visible: initialState.visible,
      modal: initialState.modal,
    }),
    addSpaceDeviceSelectedList: (
      state: SpaceDeviceSelectedListState,
      { payload }
    ) => ({
      ...state,
      selectedDeviceList: [...state.selectedDeviceList, payload],
    }),
    removeSpaceDeviceSelectedList: (
      state: SpaceDeviceSelectedListState,
      { payload }
    ) => ({
      ...state,
      selectedDeviceList: state.selectedDeviceList.filter(
        (device) => device !== payload
      ),
    }),
    updateSpaceDeviceSettingVisible: (
      state: SpaceDeviceSelectedListState,
      { payload }
    ) => ({
      ...state,
      visible: payload,
    }),
    updateSpaceDeviceModal: (
      state: SpaceDeviceSelectedListState,
      { payload }
    ) => ({
      ...state,
      modal: {
        ...state.modal,
        menu: payload,
        visible: true,
      },
    }),
  },
});

export const spaceDeviceSelectedListSelector = (state: RootState) =>
  state.space.deviceSetting.list;
export const {
  resetSpaceDeviceSelectedList,
  addSpaceDeviceSelectedList,
  removeSpaceDeviceSelectedList,
  updateSpaceDeviceSettingVisible,
  updateSpaceDeviceModal,
} = slice.actions;

export default slice;
