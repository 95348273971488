import React, { useEffect, useMemo, useState } from "react";
import LayoutDashboardSettingPopover from "./LayoutDashboardSettingPopover";
import { Popover } from "antd";
import { useSelector } from "react-redux";
import { dashboardRegisterSelector } from "../../store/dashboard/register";
import { dashboardRenameSelector } from "../../store/dashboard/rename";
import { dashboardDeleteSelector } from "../../store/dashboard/delete";
import LayoutSpaceSettingPopover from "./LayoutSpaceSettingPopover";
import { updateSpaceSelector } from "../../store/space/update";
import { createSpaceSelector } from "../../store/space/register";
import { removeSpaceSelector } from "../../store/space/remove";

function HoverSetting({ menu, name }: { menu: string; name: string }) {
  const [visible, setVisible] = useState(false);
  const { visible: dashboardRegisterModalVisible } = useSelector(
    dashboardRegisterSelector
  );
  const { visible: dashboardRenameModalVisible } = useSelector(
    dashboardRenameSelector
  );
  const { visible: dashboardRemoveModalVisible } = useSelector(
    dashboardDeleteSelector
  );
  const {
    modal: { open: spaceRenameModalVisible },
  } = useSelector(updateSpaceSelector);
  const { modalOpen: spaceRegisterModalVisible } =
    useSelector(createSpaceSelector);
  const { visible: removeModalOpen } = useSelector(removeSpaceSelector);

  useEffect(() => {
    if (
      dashboardRegisterModalVisible ||
      dashboardRenameModalVisible ||
      dashboardRemoveModalVisible ||
      spaceRenameModalVisible ||
      spaceRegisterModalVisible ||
      removeModalOpen
    )
      setVisible(false);
  }, [
    dashboardRegisterModalVisible,
    dashboardRenameModalVisible,
    dashboardRemoveModalVisible,
    spaceRegisterModalVisible,
    spaceRenameModalVisible,
    removeModalOpen,
  ]);

  const id = useMemo(() => {
    return Number(menu.split("-")[1]);
  }, [menu]);

  const handleVisibleChange = (newVisible: boolean) => {
    setVisible(newVisible);
  };

  const setContent = useMemo(() => {
    if (menu.indexOf("dashboard") !== -1) {
      return <LayoutDashboardSettingPopover id={id} name={name} />;
    } else if (menu.indexOf("space") !== -1) {
      return <LayoutSpaceSettingPopover />;
    }
  }, [menu, visible, id, name]);
  return (
    <Popover
      placement="rightBottom"
      trigger="click"
      content={setContent}
      visible={visible}
      onVisibleChange={handleVisibleChange}
      key={id}
    >
      <svg
        width={16}
        height={16}
        fill="#000"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity={0.5} fill="#000">
          <path d="M10.666 8a1.333 1.333 0 1 0 2.667 0 1.333 1.333 0 0 0-2.667 0ZM6.666 8a1.333 1.333 0 1 0 2.667 0 1.333 1.333 0 0 0-2.667 0ZM4 9.333a1.333 1.333 0 1 1 0-2.666 1.333 1.333 0 0 1 0 2.666Z" />
        </g>
      </svg>
    </Popover>
  );
}
export default HoverSetting;
