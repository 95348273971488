import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ActionType, RootState } from "../index";
import { sleep } from "../../common/utils";
import { resetPassword } from "../../api/join/join";

type ResetPasswordState = {
  loading: boolean;
  error?: string | Error;
  form: ResetPasswordForm;
  success: boolean;
};

type ResetPasswordForm = {
  email: string | undefined;
  username: string | undefined;
  certificationNumber: number | undefined;
  code: string | undefined;
  password1: string | undefined;
  password2: string | undefined;
  number1: number | undefined;
  number2: number | undefined;
  number3: number | undefined;
  number4: number | undefined;
  number5: number | undefined;
  number6: number | undefined;
};

const initialState: ResetPasswordState = {
  loading: false,
  form: {
    email: undefined,
    username: undefined,
    certificationNumber: undefined,
    code: undefined,
    password1: undefined,
    password2: undefined,
    number1: undefined,
    number2: undefined,
    number3: undefined,
    number4: undefined,
    number5: undefined,
    number6: undefined,
  },
  success: false,
};

export const fetchResetPassword = createAsyncThunk(
  "resetPassword",
  async (body: { email: string; password: string }) => {
    await sleep(100);
    const { result } = await resetPassword(body);
    return result;
  }
);

const slice = createSlice({
  name: "resetPassword",
  initialState,
  reducers: {
    updateResetPasswordForm: (
      state: ResetPasswordState,
      action: ActionType<ResetPasswordState["form"]>
    ) => ({
      ...state,
      form: { ...state.form, ...action.payload },
    }),
    resetForm: (state: ResetPasswordState) => ({
      ...state,
      form: initialState.form,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchResetPassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchResetPassword.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = undefined;
      state.success = payload;
    });
    builder.addCase(fetchResetPassword.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message as string;
      state.success = false;
    });
  },
});

export const resetPasswordSelector = (state: RootState) => state.resetPassword;
export const { updateResetPasswordForm, resetForm } = slice.actions;

export default slice;
