import { Button, Radio, RadioChangeEvent } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../store";
import {
  updateAirposModalVisible,
  updateAirposStep,
} from "../../../../../store/setting/airpos";
import {
  Airpos,
  fetchGetAirPosList,
  settingAirposListSelector,
  updateSelectedPos,
} from "../../../../../store/setting/airpos/list";
const md5 = require("md5");

function AirposDeviceList({ id, password }: { id: string; password: string }) {
  const { posList, selectedPos, isAgain } = useSelector(
    settingAirposListSelector
  );
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(
      fetchGetAirPosList({
        checkAgain: isAgain,
        bizUserId: id,
        bizUserPassword: md5(password),
      })
    );
  }, []);

  const onChangePos = (e: RadioChangeEvent) => {
    dispatch(
      updateSelectedPos({ id: e.target.id, device_name: e.target.value })
    );
  };
  return (
    <>
      <h1>POS/키오스크 선택</h1>
      <p>연동할 POS 또는 키오스크를 선택해주세요.</p>
      <p>설정되지 않은 기기는 '장소 미지정'에 위치됩니다.</p>
      <ul className="pos-list">
        <Radio.Group onChange={onChangePos}>
          {posList &&
            posList.map((pos: Airpos) => {
              return (
                <li
                  key={pos.id}
                  className={`pos-item ${
                    selectedPos && selectedPos.id === pos.id ? "selected" : ""
                  }`}
                >
                  <Radio value={pos.device_name} id={pos.id}>
                    <div className="pos-info">
                      <img
                        src={`${process.env.REACT_APP_S3_URL}/1.0/button-icons/pos_icon.png`}
                        alt="pos_icon"
                      />
                      <p>{pos.device_name}</p>
                    </div>
                  </Radio>
                </li>
              );
            })}
        </Radio.Group>
      </ul>
      <div className="pos-footer">
        <Button
          disabled={!selectedPos}
          onClick={() => dispatch(updateAirposStep())}
          type="primary"
        >
          다음
        </Button>
        {selectedPos && (
          <Button onClick={() => dispatch(updateAirposModalVisible(false))}>
            닫기
          </Button>
        )}
      </div>
    </>
  );
}

export default AirposDeviceList;
