import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../index";
import { ActionType, ConditionType } from "../register";
import { sleep } from "../../../common/utils";
import { getRecipeById, getRecipeList } from "../../../api/recipe/recipe";

type RecipeDetail = {
  id: string;
  name: string;
  oneClick: boolean;
  recipeData: string;
  timeInfo?: {
    dayList: string[];
    during: boolean;
    repeat: boolean;
    startTime: string;
    endTime: string;
  };
  conditionList: ConditionType[];
  actionList: ActionType[];
};

type RecipeDetailState = {
  loading: boolean;
  error?: string | Error;
  recipe?: RecipeDetail;
};

const initialState: RecipeDetailState = {
  loading: false,
  error: undefined,
  recipe: undefined,
};

export const fetchGetRecipeDetail = createAsyncThunk(
  "recipe/detail",
  async (recipeId: string) => {
    await sleep(100);
    const { result } = await getRecipeById(recipeId);
    const { id, name, oneClick, recipeData } = result;
    const { timeInfo, registerConditionList, registerActionList } =
      JSON.parse(recipeData);

    return {
      id: id,
      name: name,
      oneClick: oneClick,
      recipeData: recipeData,
      timeInfo: timeInfo,
      conditionList: registerConditionList,
      actionList: registerActionList,
    };
  }
);

const slice = createSlice({
  name: "recipe/detail",
  initialState,
  reducers: {
    updateOpenRecipeUpdateModal: (state: RecipeDetailState, { payload }) => ({
      ...state,
      modal: payload,
    }),
    resetRecipeDetail: (state: RecipeDetailState) => ({
      ...state,
      loading: initialState.loading,
      recipe: initialState.recipe,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchGetRecipeDetail.pending,
      (state: RecipeDetailState) => {
        state.loading = true;
        state.error = undefined;
      }
    );
    builder.addCase(
      fetchGetRecipeDetail.fulfilled,
      (state: RecipeDetailState, { payload }) => {
        state.loading = false;
        state.recipe = payload;
        state.error = undefined;
      }
    );
    builder.addCase(
      fetchGetRecipeDetail.rejected,
      (state: RecipeDetailState, { error }) => {
        state.loading = false;
        state.error = error.message;
      }
    );
  },
});

export const recipeDetailSelector = (state: RootState) => state.recipe.detail;
export const { resetRecipeDetail } = slice.actions;

export default slice;
