import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sleep } from "../../../common/utils";
import { registerDashboard } from "../../../api/dashboard/dashboard";
import { RootState } from "../../index";

type DashboardRegisterState = {
  loading: boolean;
  error?: string | Error;
  id?: number;
  dashboardName?: string;
  success: boolean;
  visible: boolean;
};

const initialState: DashboardRegisterState = {
  loading: false,
  success: false,
  visible: false,
};

export const fetchRegisterDashboard = createAsyncThunk(
  "dashboard/register",
  async (name: string) => {
    await sleep(100);
    const { result } = await registerDashboard(name);
    return result;
  }
);

const slice = createSlice({
  name: "dashboard/register",
  initialState,
  reducers: {
    resetDashboardRegister: (state: DashboardRegisterState) => ({
      ...state,
      success: initialState.success,
      id: initialState.id,
      dashboardName: initialState.dashboardName,
      visible: initialState.visible,
    }),
    updateDashboardModalVisible: (
      state: DashboardRegisterState,
      { payload }
    ) => ({
      ...state,
      visible: payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchRegisterDashboard.pending,
      (state: DashboardRegisterState) => {
        state.loading = true;
      }
    );
    builder.addCase(
      fetchRegisterDashboard.fulfilled,
      (state: DashboardRegisterState, { payload }) => {
        state.loading = false;
        state.error = undefined;
        state.id = payload.id;
        state.dashboardName = payload.dashboardName;
        state.success = payload.success;
      }
    );
    builder.addCase(
      fetchRegisterDashboard.rejected,
      (state: DashboardRegisterState, { error }) => {
        state.loading = false;
        state.error = error.message as string;
        state.success = false;
      }
    );
  },
});

export const dashboardRegisterSelector = (state: RootState) =>
  state.dashboard.register;
export const { resetDashboardRegister, updateDashboardModalVisible } =
  slice.actions;

export default slice;
