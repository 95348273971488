import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sleep } from "../../../common/utils";
import { RootState } from "../../index";
import { getDashboardList } from "../../../api/dashboard-manage/dashboard-manage";

type DashboardListState = {
  loading: boolean;
  error?: string | Error;
  dashboardList: {
    id: number;
    dashboardName: string;
  }[];
};

const initialState: DashboardListState = {
  loading: false,
  error: undefined,
  dashboardList: [],
};

export const fetchGetDashboardList = createAsyncThunk(
  "dashboardManage/list",
  async () => {
    await sleep(100);
    const { result } = await getDashboardList();
    return result;
  }
);

const slice = createSlice({
  name: "dashboardManage/list",
  initialState,
  reducers: {
    resetDashboardList: (state) => ({
      ...state,
      spaceList: initialState.dashboardList,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetDashboardList.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(fetchGetDashboardList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = undefined;
      state.dashboardList = payload;
    });
    builder.addCase(fetchGetDashboardList.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    });
  },
});

export const dashboardListSelector = (state: RootState) =>
  state.dashboardManage.list;
export const { resetDashboardList } = slice.actions;

export default slice;
