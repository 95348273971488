import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../index";
import { sleep } from "../../../common/utils";
import { postExecuteRecipe } from "../../../api/recipe/recipe";

type RecipeExecuteState = {
  loading: boolean;
  error?: string | Error;
  success: boolean;
};

const initialState: RecipeExecuteState = {
  loading: false,
  error: "",
  success: false,
};

export const fetchExecuteRecipe = createAsyncThunk(
  "recipe/execute",
  async ({ id, body }: { id: string; body: string }) => {
    await sleep(100);
    const { result } = await postExecuteRecipe(id, body);
    return result;
  }
);
const slice = createSlice({
  name: "recipe/execute",
  initialState,
  reducers: {
    resetRecipeExecute: (state: RecipeExecuteState) => ({
      ...state,
      loading: initialState.loading,
      error: initialState.error,
      success: initialState.success,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchExecuteRecipe.pending, (state: RecipeExecuteState) => {
      state.loading = true;
    });
    builder.addCase(
      fetchExecuteRecipe.fulfilled,
      (state: RecipeExecuteState, { payload }) => {
        state.loading = false;
        state.error = undefined;
        state.success = payload;
      }
    );
    builder.addCase(
      fetchExecuteRecipe.rejected,
      (state: RecipeExecuteState, { error }) => {
        state.loading = false;
        state.error = error.message as string;
        state.success = false;
      }
    );
  },
});

export const recipeExecuteSelector = (state: RootState) => state.recipe.execute;
export const { resetRecipeExecute } = slice.actions;
export default slice;
