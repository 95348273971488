import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../index";
import { sleep } from "../../../common/utils";
import { removeNoSpaceDeviceList } from "../../../api/space/space";

type NoSpaceDeviceListRemoveState = {
  loading: boolean;
  error?: string | Error;
  success: boolean | undefined;
};

const initialState: NoSpaceDeviceListRemoveState = {
  loading: false,
  error: undefined,
  success: undefined,
};

export const fetchRemoveNoSpaceDeviceList = createAsyncThunk(
  "space/removeNoSpace",
  async (deviceList: number[]) => {
    await sleep(100);
    const { result } = await removeNoSpaceDeviceList({ deviceList });
    return result;
  }
);

const slice = createSlice({
  name: "space/removeNoSpace",
  initialState,
  reducers: {
    resetNoSpaceDeviceListRemove: (state: NoSpaceDeviceListRemoveState) => ({
      ...state,
      loading: initialState.loading,
      success: initialState.success,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRemoveNoSpaceDeviceList.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(
      fetchRemoveNoSpaceDeviceList.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = undefined;
        state.success = payload;
      }
    );
    builder.addCase(
      fetchRemoveNoSpaceDeviceList.rejected,
      (state, { error }) => {
        state.loading = false;
        state.success = false;
        state.error = error.message;
      }
    );
  },
});

export const noSpaceDeviceListRemoveSelector = (state: RootState) =>
  state.space.removeNoSpaceDeviceList;
export const { resetNoSpaceDeviceListRemove } = slice.actions;

export default slice;
