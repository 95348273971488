import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { joinSelector } from "../../../store/join";
import { checkCodeSelector, fetchCheckCode } from "../../../store/check-code";
import useQuery from "../../../utils/useQuery";
import CertificationForm from "../../common/certification-form/CertificationForm";
import { AppDispatch } from "../../../store";
import { Button } from "antd";

function JoinStep3() {
  const { form } = useSelector(joinSelector);
  const { check, loading } = useSelector(checkCodeSelector);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const query = useQuery();
  const step = Number(query.get("step"));

  useEffect(() => {
    if (!form.terms1 || !form.terms2 || !form.terms3 || !form.email)
      navigate("/login/join?step=1");
  }, []);

  useEffect(() => {
    check && navigate(`/login/join?step=${step + 1}`);
  }, [check]);

  const onNextClick = useCallback(async () => {
    if (form.certificationNumber)
      await dispatch(
        fetchCheckCode({
          email: form.email as string,
          code: form.certificationNumber,
          auth_type: 1,
        })
      );
  }, [form, dispatch]);

  return (
    <div>
      <CertificationForm />
      <div className="join-footer">
        <Button
          type="primary"
          disabled={!form.certificationNumber || loading}
          onClick={onNextClick}
        >
          {loading ? "loading..." : "다음"}
        </Button>
      </div>
    </div>
  );
}

export default JoinStep3;
