import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../index";
import { sleep } from "../../../../common/utils";
import { getUserPlatforms } from "../../../../api/user/user";

export type syncPlatform = {
  id: string;
  user_info_id: number;
  sync_platform_id: string;
  uid: string;
  user_id: string;
  created_at: string;
  updated_at: string;
  syncPlatformInfo: {
    id: string;
    platform_type_code: string;
    app_schema: string;
    platform_name: string;
    package_name: string;
  };
};

type PlatformListState = {
  loading: boolean;
  error?: string | Error;
  syncPlatformList: syncPlatform[];
};

const initialState: PlatformListState = {
  loading: false,
  error: undefined,
  syncPlatformList: [],
};

export const fetchGetSyncPlatformList = createAsyncThunk(
  "setting/syncplatform/list",
  async () => {
    await sleep(100);
    const { result } = await getUserPlatforms();
    return result;
  }
);

const slice = createSlice({
  name: "setting/syncplatform/list",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGetSyncPlatformList.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(
      fetchGetSyncPlatformList.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = undefined;
        state.syncPlatformList = payload;
      }
    );
    builder.addCase(fetchGetSyncPlatformList.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    });
  },
});

export const settingSyncPlatformListSelector = (state: RootState) =>
  state.setting.account.syncPlatformList;

export default slice;
