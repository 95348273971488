import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { joinSelector } from "../../../store/join";
import useQuery from "../../../utils/useQuery";
import PasswordForm from "../../common/password-form/PasswordForm";
import { Button } from "antd";

function JoinStep4() {
  const { form } = useSelector(joinSelector);
  const navigate = useNavigate();
  const query = useQuery();
  const step = Number(query.get("step"));

  useEffect(() => {
    if (
      !form.terms1 ||
      !form.terms2 ||
      !form.terms3 ||
      !form.email ||
      !form.certificationNumber
    )
      navigate("/login/join?step=1");
  }, []);

  const onNextClick = useCallback(async () => {
    navigate(`/login/join?step=${step + 1}`);
  }, []);

  return (
    <div>
      <PasswordForm />
      <div className="join-footer">
        <Button
          type="primary"
          disabled={
            !(form.password1 && form.password2) ||
            form.password1 !== form.password2
          }
          onClick={onNextClick}
        >
          다음
        </Button>
      </div>
    </div>
  );
}

export default JoinStep4;
