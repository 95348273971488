import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../index";
import { sleep } from "../../../common/utils";
import {
  ConditionSelectType,
  getActionListByDeviceType,
} from "../../../api/recipe/recipe";

type ActionSelectListState = {
  loading: boolean;
  error?: string | Error;
  actionList: ConditionSelectType[];
};

const initialState: ActionSelectListState = {
  loading: false,
  error: "",
  actionList: [],
};

export const fetchGetActionList = createAsyncThunk(
  "recipe/actionList",
  async (deviceType: string) => {
    await sleep(100);
    const { result } = await getActionListByDeviceType(deviceType);
    return result;
  }
);
const slice = createSlice({
  name: "recipe/actionList",
  initialState,
  reducers: {
    resetRecipeActionList: (state: ActionSelectListState) => ({
      ...state,
      loading: initialState.loading,
      error: initialState.error,
      actionList: initialState.actionList,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchGetActionList.pending,
      (state: ActionSelectListState) => {
        state.loading = true;
      }
    );
    builder.addCase(
      fetchGetActionList.fulfilled,
      (state: ActionSelectListState, { payload }) => {
        state.loading = false;
        state.error = undefined;
        state.actionList = payload;
      }
    );
    builder.addCase(
      fetchGetActionList.rejected,
      (state: ActionSelectListState, { error }) => {
        state.loading = false;
        state.error = error.message as string;
        state.actionList = [];
      }
    );
  },
});

export const recipeActionListSelector = (state: RootState) =>
  state.recipe.actionList;
export const { resetRecipeActionList } = slice.actions;
export default slice;
